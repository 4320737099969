import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { CopyButton, GoldenButton, IconImage, Loader, Pagination } from 'components';
import { device } from 'resources/theme';
import { formatAddress } from 'utils';
import { useAdminAuthRequire } from 'hooks/useAdminAuthRequire';
import { authStore } from 'store';
import { observer } from 'mobx-react-lite';
import { useFetch } from 'hooks';
import { getNFTs } from 'api';
import { PAGE_LIMIT } from 'constants/index';

const PageSize = 9;

export const WinnersPageView = observer(() => {
  useAdminAuthRequire();
  const { t } = useTranslation('views/admin');
  const [currentPage, setCurrentPage] = useState(1);
  const [activeBtn, setActiveBtn] = useState('Diamond');
  const [isListOpen, setIsListOpen] = useState(false);

  const { response, loading } = useFetch(
    () => getNFTs({ status: activeBtn, page: currentPage, ipp: PAGE_LIMIT, ofAllUsers: true }),
    [activeBtn, currentPage],
  );
  const winnersData = response ? response.result : [];
  const buttonsArray = [
    {
      text: t('buttons.diamond'),
      name: 'Diamond',
    },
    {
      text: t('buttons.gold'),
      name: 'Gold',
    },
    {
      text: t('buttons.silver'),
      name: 'Silver',
    },
  ];

  if (!authStore.isAdmin) {
    return <Loader isLoading={true} />;
  }

  return (
    <StyledContainer>
      <Loader isLoading={loading} />
      <StyledButtonsContainer isOpen={isListOpen}>
        {buttonsArray.map((item) => (
          <StyledGoldenBtn
            key={item.name}
            activeBtn={activeBtn}
            name={item.name}
            isMobile={window.matchMedia(device.laptopM).matches}
          >
            <GoldenButton
              onClick={() => {
                setCurrentPage(1);
                setActiveBtn(item.name);
                setIsListOpen(true);
              }}
            >
              {' '}
              {item.text}
            </GoldenButton>
          </StyledGoldenBtn>
        ))}
      </StyledButtonsContainer>
      <StyledListContsiner isOpen={isListOpen}>
        <StyledGoBackBtn isOpen={isListOpen} onClick={() => setIsListOpen(false)}>
          <StyledIconImage icon='arrow' />
          {t('buttons.winners')}
        </StyledGoBackBtn>
        <StyledList>
          <StyledListHeader>
            <div>
              <span>№</span>
            </div>
            <div>
              <span>{t('idWallet')}</span>
            </div>
            <div>
              <span>{t('email')}</span>
            </div>
            <div>
              <span>{t('tokenID')}</span>
            </div>
          </StyledListHeader>
          {winnersData.map((item, index) => {
            return (
              <StyledListItem key={item.tokenId}>
                {' '}
                <StyledWhiteField>{(currentPage - 1) * 9 + index + 1}</StyledWhiteField>
                <StyledWhiteField>
                  <span>{formatAddress(item.user.accountAddress)}</span>{' '}
                  <CopyButton textToCopy={item.user?.accountAddress} />
                </StyledWhiteField>
                <StyledWhiteField>
                  {item.user?.email && (
                    <>
                      <span>{item.user?.email}</span> <CopyButton textToCopy={item.user?.email} />
                    </>
                  )}
                </StyledWhiteField>
                <StyledWhiteField>{item.tokenId.toLocaleString().replace(',', ' ')}</StyledWhiteField>
              </StyledListItem>
            );
          })}
        </StyledList>
        {response?.totalItems ? (
          <Pagination
            className='pagination-bar'
            currentPage={currentPage}
            totalCount={response?.totalItems}
            pageSize={PageSize}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        ) : null}
      </StyledListContsiner>
    </StyledContainer>
  );
});

const StyledIconImage = styled(IconImage)`
  transform: rotate(180deg);
  position: absolute;
  left: 24px;
  filter: brightness(0) invert(100%);
  width: 9px;
`;

const StyledGoBackBtn = styled.button<{ isOpen: boolean }>`
  display: none;
  @media ${device.laptopM} {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'nonne')};
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    height: 63px;
    background: #828282;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0px 20px 50px;
    gap: 10px;
    font-family: 'Ginora Sans';
    font-style: normal;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizeL};
    line-height: 23px;
    color: #ffffff;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledGoldenBtn = styled.div<{ name: string; activeBtn: string; isMobile: boolean }>`
  margin-bottom: 41px;
  button {
    width: 275px;
    height: 65px;
    text-transform: capitalize;
    color: ${({ theme, activeBtn, name, isMobile }) => (activeBtn === name && !isMobile ? theme.bgColor : 'initial')};
    background-clip: ${({ activeBtn, name, isMobile }) => (activeBtn === name && !isMobile ? 'unset' : 'text')};
    -webkit-background-clip: ${({ activeBtn, name, isMobile }) =>
      activeBtn === name && !isMobile ? 'unset' : 'transparent'} !important;
    -moz-background-clip: ${({ activeBtn, name, isMobile }) => (activeBtn === name && !isMobile ? 'unset' : 'text')};
    text-fill-color: ${({ theme, activeBtn, name, isMobile }) =>
      activeBtn === name && !isMobile ? theme.bgColor : 'transparent'} !important;
    -webkit-text-fill-color: ${({ theme, activeBtn, name, isMobile }) =>
      activeBtn === name && !isMobile ? theme.bgColor : 'text'} !important;
    -moz-text-fill-color: ${({ theme, activeBtn, name, isMobile }) =>
      activeBtn === name && !isMobile ? theme.bgColor : 'transparent'} !important;
    span {
      text-fill-color: ${({ theme, activeBtn, name, isMobile }) =>
        activeBtn === name && !isMobile ? theme.bgColor : 'transparent'} !important;
      -webkit-text-fill-color: ${({ theme, activeBtn, name, isMobile }) =>
        activeBtn === name && !isMobile ? theme.bgColor : 'text'} !important;
      -moz-text-fill-color: ${({ theme, activeBtn, name, isMobile }) =>
        activeBtn === name && !isMobile ? theme.bgColor : 'transparent'} !important;
    }
  }
  @media ${device.laptopM} {
    margin-bottom: 15px;
    button {
      height: 58px;
      font-size: ${({ theme }) => theme.fontSizeS};
    }
  }
`;

const StyledButtonsContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0;
  ${StyledGoldenBtn}:nth-last-child(1) {
    margin-bottom: 0;
  }
  @media ${device.laptopM} {
    margin: 50px auto;
    display: ${({ isOpen }) => (isOpen ? 'none' : 'flex')};
    flex-direction: column;
    justify-content: center;
  }
`;

const StyledListContsiner = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${device.laptopM} {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    margin: auto;
  }
`;

const StyledWhiteField = styled.div`
  width: 100%;
  height: 46px;
  box-sizing: border-box;
  margin: 15px 0;
  background: #fff;
  border-radius: 10px;
  padding: 16px 0px 16px 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
  margin-bottom: 13px;
  font-size: ${({ theme }) => theme.fontSizeM};
  color: #000;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 22px !important;
    margin-right: 26px !important;
  }
  @media ${device.laptopM} {
    margin-bottom: 15px;
    height: 31px;
    padding-left: 10px;
    padding-right: 10px;
    div {
      transform: scale(0.61, 0.61);
      width: 14px !important;
      margin-right: 12px !important;
      margin-left: 4px;
    }
  }
`;

const StyledList = styled.ul`
  padding: 0;
  list-style-type: none;
  height: ${(PageSize + 1) * (46 + 13)}px;
  li {
    div:nth-child(1) {
      width: 46px;
      margin-right: 16px;
    }
    div:nth-child(2) {
      width: 220px;
      margin-right: 100px;
    }
    div:nth-child(3) {
      width: 322px;
      margin-right: 100px;
    }
    div:nth-child(4) {
      width: 96px;
    }
  }
  @media ${device.laptopM} {
    margin-top: 220px;
    height: ${(PageSize + 1) * (32 + 12)}px;
    li {
      div:nth-child(1) {
        max-width: 46px;
        width: 12vw;
        margin-right: 13px;
      }
      div:nth-child(2) {
        max-width: 86px;
        width: 24vw;
        margin-right: 6px;
      }
      div:nth-child(3) {
        max-width: 136px;
        width: 38vw;
        margin-right: 6px;
      }
      div:nth-child(4) {
        max-width: 50px;
        width: 13vw;
      }
    }
  }
`;
const StyledListHeader = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  div {
    text-align: center;
  }
  span {
    font-family: 'Ginora Sans';
    font-style: normal;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSizeXL};
    line-height: 28px;
    text-transform: uppercase;
    background: ${({ theme }) => theme.linearGradientGold};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  @media ${device.laptopM} {
    div {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 3px 8px;
      gap: 10px;
      width: 81px;
      height: 21px;
      background: #d4af37;
      border-radius: 5px;
    }
    span {
      font-size: ${({ theme }) => theme.fontSizeXs};
      line-height: 15px;
      background: #fff;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
`;
const StyledListItem = styled.li`
  display: flex;
  justify-content: space-between;
  ${StyledWhiteField}:nth-child(3n+1) {
    justify-content: center;
    padding: 16px 0;
  }
  ${StyledWhiteField}:nth-child(4) {
    font-family: 'Ginora Sans';
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSizeXL};
    line-height: 28px;
    @media ${device.laptopM} {
      font-size: 11px;
      font-family: 'Poppins';
      font-weight: 400;
    }
  }
  @media ${device.laptopM} {
    ${StyledWhiteField} {
      font-weight: 400;
      font-size: 11px;
      span {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    ${StyledWhiteField}:nth-child(2) {
      font-size: 9px;
    }
  }
`;
