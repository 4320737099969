import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FreeMintModal, GoldenButton, IconImage, Loader } from 'components';
import nftExample from 'resources/images/freeMint/nft-example-3.jpg';
import { freeMintStore, walletStore } from '../../../store';
import { FreeMintContract } from '../../../blockchain';
import { useTranslation } from 'react-i18next';
import buttonBgSvg from 'resources/images/freeMint/about/arrowBg.svg';
import buttonBgHoverSvg from 'resources/images/freeMint/about/arrowBgHover.svg';
import { device } from 'resources/theme';
import { FreeMintModalKeys } from '../../../constants';

interface IProps {
  onClose?: () => void;
}

export const FreeMintNftOrderModal = ({ onClose }: IProps) => {
  const { t } = useTranslation('views/freeMint');
  const [nonClaimedTokens, setNonClaimedTokens] = useState([]);
  const [maxCount, setMaxCount] = useState<number>(0);
  const [count, setCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    initialCheck();
  }, []);

  const initialCheck = async () => {
    try {
      if (walletStore.web3Provider) {
        const contract = new FreeMintContract(walletStore.web3Provider);
        const isClaimEnabled = await FreeMintContract.isClaimEnabled();

        if (!isClaimEnabled) {
          setIsLoading(false);
          return;
        }

        const _nonClaimedTokens = await contract.getNotClaimedTokens(walletStore.connectedAccount);
        const maxTokens = await FreeMintContract.getMaxTokens();
        const usedTokens = await FreeMintContract.getTotalSupply();

        if (usedTokens === maxTokens) {
          setIsLoading(false);
          return;
        }

        const _maxCount = Math.min(_nonClaimedTokens.length, maxTokens - usedTokens);
        setMaxCount(_maxCount);
        setNonClaimedTokens(_nonClaimedTokens.slice(0, _maxCount));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      setErrorMessage(t('defaultErrorMessage'));
    }
  };

  const handleMint = async () => {
    try {
      if (!walletStore.web3Provider) {
        return;
      }
      setIsLoading(true);
      await new FreeMintContract(walletStore.web3Provider).claimTokens(nonClaimedTokens.slice(0, count));
      freeMintStore.setModal(FreeMintModalKeys.SuccessfulMint);
    } catch (e) {
      freeMintStore.setModal(FreeMintModalKeys.UnsuccessfulMint);
    }
  };

  const handleDecrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const handleIncrement = () => {
    if (count < maxCount) {
      setCount(count + 1);
    }
  };

  if (isLoading) {
    return <Loader isLoading={true} />;
  }

  return (
    <FreeMintModal onClose={onClose}>
      <StyledWrapper>
        <StyledLeftContainer>
          <StyledGalleryImageContainer>
            <StyledImage src={nftExample} alt='' />
          </StyledGalleryImageContainer>
          {maxCount > 0 && (
            <>
              <StyledOrderLabel>{t('modal.nftOrder.addNftsToCard')}</StyledOrderLabel>
              <StyledNftCountButtonsContainer>
                <StyledNftCountButton onClick={handleDecrement} disabled={count === 1}>
                  {'-'}
                </StyledNftCountButton>
                <StyledNftCountLabel>{count}</StyledNftCountLabel>
                <StyledNftCountButton onClick={handleIncrement} disabled={count === maxCount}>
                  {'+'}
                </StyledNftCountButton>
              </StyledNftCountButtonsContainer>
            </>
          )}
          {maxCount > 0 ? (
            <StyledOrderInfoMobile>
              <StyledOrderTotalWrapper>
                <StyledOrderTotal>{t('modal.nftOrder.yourOrderIs')}</StyledOrderTotal>
                <StyledBr />
                <StyledTotalCountValue>
                  {count}
                  {t('modal.nftOrder.countNft')}
                </StyledTotalCountValue>
              </StyledOrderTotalWrapper>
              <StyledButtonsContainer>
                <StyledButton isFreeMint={true} onClick={handleMint} primary={true} disabled={isLoading}>
                  {t('mintNow')}
                </StyledButton>
              </StyledButtonsContainer>
            </StyledOrderInfoMobile>
          ) : (
            <StyledNftNotAvailableMobile>{t('modal.nftOrder.nftNotAvailable')}</StyledNftNotAvailableMobile>
          )}
        </StyledLeftContainer>
        <StyledRightContainer>
          <StyledTitle>{t('modal.nftOrder.title')}</StyledTitle>
          <StyledDescription>{t('modal.nftOrder.description')}</StyledDescription>
          {maxCount > 0 ? (
            <StyledOrderInfo>
              <StyledOrderTotalWrapper>
                <StyledOrderTotal>{t('modal.nftOrder.yourOrderIs')}</StyledOrderTotal>
                <StyledBr />
                <StyledTotalCountValue>
                  {count}
                  {t('modal.nftOrder.countNft')}
                </StyledTotalCountValue>
              </StyledOrderTotalWrapper>
              <StyledButtonsContainer>
                <StyledButton onClick={handleMint} primary={true} disabled={isLoading}>
                  {t('mintNow')}
                </StyledButton>
              </StyledButtonsContainer>
            </StyledOrderInfo>
          ) : (
            <StyledNftNotAvailable>{t('modal.nftOrder.nftNotAvailable')}</StyledNftNotAvailable>
          )}
        </StyledRightContainer>
      </StyledWrapper>
      {errorMessage ? (
        <StyledContainer>
          <StyledErrorMessage>
            <StyledIcon icon='errorCross' />
            <StyledErrorText>{errorMessage}</StyledErrorText>
          </StyledErrorMessage>
        </StyledContainer>
      ) : null}
    </FreeMintModal>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media ${device.mobileL} {
    flex-direction: column-reverse;
  }
`;

const StyledLeftContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 2;
  margin-right: 10px;
  @media ${device.mobileL} {
    margin-top: 0;
    margin-left: 0;
    justify-content: center;
    align-items: center;
  }
`;

const StyledRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 3;
  margin-top: 80px;
  margin-left: 100px;
  @media ${device.mobileL} {
    margin-top: 0;
    margin-left: 0;
    justify-content: center;
    align-items: center;
  }
`;

const StyledGalleryImageContainer = styled.div`
  // box-sizing: border-box;
  background: ${({ theme }) => theme.freeMintGalleryImageBg};
  border-radius: 20px;
  // border: 1px solid;
  border-image-source: linear-gradient(
    122.47deg,
    #ffc149 2.47%,
    #fffea1 11.82%,
    rgba(255, 254, 161, 0) 27.72%,
    rgba(255, 254, 161, 0) 74.66%,
    #b48900 104.8%
  );
  max-width: 336px;
  min-width: 236px;
  margin: auto;
  margin-bottom: 20px;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
  }

  @media ${device.mobileL} {
    padding: 25px;
  }
`;

const StyledImage = styled.img`
  border-radius: 20px;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 30px;
  padding-left: 20px;
  @media ${device.mobileL} {
    justify-content: center;
    align-items: center;
    padding-left: 0;
  }
`;

const StyledTitle = styled.div`
  font-family: 'Syne Extra';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
  background: linear-gradient(120.43deg, #ffc149 25.88%, #fffea1 41.78%, #b48900 68.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media ${device.mobileL} {
    font-size: 24px;
    line-height: 29px;
  }
`;

const StyledDescription = styled.p`
  width: auto;
  font-size: ${({ theme }) => theme.fontSizeM};
  font-weight: 300;
  color: ${({ theme }) => theme.lightTextColor};
  text-align: left;
  margin-bottom: 24px;
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSizeXs};
  }
  @media ${device.mobileL} {
    margin-bottom: 17px;
  }
`;

const StyledErrorMessage = styled.div`
  color: ${({ theme }) => theme.errorColor};
  font-size: ${({ theme }) => theme.errorFontSize};
`;

const StyledErrorText = styled.span`
  margin-left: 12px;
`;

const StyledIcon = styled(IconImage)`
  width: 10px;
  height: 10px;
`;

const StyledButtonsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  @media ${device.mobileL} {
    justify-content: center;
    align-items: center;
  }
`;

const StyledButton = styled(GoldenButton)<{ primary?: boolean }>`
  border-radius: 5px;
  flex: 1;
`;

const StyledLabel = styled.span`
  font-family: 'Syne Extra';
  font-style: normal;
  font-weight: 800;
  font-size: 19.1253px;
  line-height: 23px;
  text-transform: uppercase;
  background: linear-gradient(120.43deg, #ffc149 25.88%, #fffea1 41.78%, #b48900 68.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-right: 20px;
  @media ${device.mobileL} {
    margin-right: 0;
  }
`;

const StyledOrderLabel = styled(StyledLabel)`
  @media ${device.mobileL} {
    max-width: 200px;
  }
`;

const StyledOrderTotal = styled(StyledLabel)``;

const StyledOrderTotalWrapper = styled.div`
  @media ${device.mobileL} {
    max-width: 200px;
    padding-top: 10px;
    text-align: center;
  }
`;

const StyledTotalCountValue = styled.div`
  display: inline-block;
  font-family: 'Syne Extra';
  font-style: normal;
  font-weight: 800;
  font-size: 19.1253px;
  line-height: 23px;
  text-transform: uppercase;
  background: linear-gradient(147.12deg, #33e7c6 5.93%, rgba(139, 248, 255, 0.7) 83.45%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media ${device.mobileL} {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

const StyledNftCountButtonsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  // min-width: 200px;
  text-align: center;
  align-items: center;
`;

const StyledNftCountButton = styled.div<{ disabled: boolean }>`
  box-sizing: border-box;
  width: 44px;
  height: 44px;
  margin: 0 15px;
  background-image: url(${buttonBgSvg});
  background-size: contain;
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  color: ${({ disabled }) => (disabled ? '#000000' : '#FFFFFF')};
  padding: 12px;
  text-align: center;
  font-weight: bold;
  user-select: none;
  mix-blend-mode: ${({ disabled }) => (disabled ? 'soft-light' : 'none')};
  &:hover,
  &:active {
    background-image: url(${({ disabled }) => (disabled ? buttonBgSvg : buttonBgHoverSvg)});
  }
`;

const StyledNftCountLabel = styled.span`
  font-family: 'Syne Bold';
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;
  background: linear-gradient(120.43deg, #ffc149 25.88%, #fffea1 41.78%, #b48900 68.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  min-width: 20px;
  text-align: center;
`;

const StyledOrderInfo = styled.div`
  display: block;
  @media ${device.mobileL} {
    display: none;
  }
`;

const StyledOrderInfoMobile = styled.div`
  display: none;
  margin-top: 10px;
  margin-bottom: 10px;
  @media ${device.mobileL} {
    display: block;
  }
`;

const StyledNftNotAvailable = styled(StyledLabel)`
  display: block;
  @media ${device.mobileL} {
    display: none;
  }
`;

const StyledNftNotAvailableMobile = styled(StyledLabel)`
  display: none;
  @media ${device.mobileL} {
    display: block;
  }
`;

const StyledBr = styled.br`
  display: none;
  @media ${device.mobileL} {
    display: block;
  }
`;
