import styled from 'styled-components';
import { HeaderView, FooterView, FreeMintHeaderView, FreeMintFooterView } from 'views';
import { device, FreeMintGlobalStyle } from 'resources/theme';
import { Outlet } from 'react-router-dom';
import gradient3Png from 'resources/images/freeMint/background/gradients-3.png';
import group187Png from 'resources/images/freeMint/background/group-187.png';
import group189Png from 'resources/images/freeMint/background/group-189.png';
// import gradient2Png from 'resources/images/freeMint/background/gradients-2.png';
// import gradient4Png from 'resources/images/freeMint/background/gradients-4.png';
// import gradient5Png from 'resources/images/freeMint/background/gradients-5.png';

interface IProps {
  isFreeMint?: boolean;
}

export const MainLayoutView = ({ isFreeMint = false }: IProps) => {
  return (
    <StyledContainer isFreeMint={isFreeMint}>
      {isFreeMint ? <FreeMintHeaderView /> : <HeaderView />}
      <Outlet />
      {isFreeMint ? <FreeMintFooterView /> : <FooterView />}
      {isFreeMint && <FreeMintGlobalStyle />}
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ isFreeMint: boolean }>`
  background-image: ${({ isFreeMint }) =>
    isFreeMint ? `url(${group189Png}), url(${group187Png}), url(${gradient3Png})` : 'inherit'};
  background-position: ${({ isFreeMint }) => (isFreeMint ? 'left -50%, right -50%, left bottom' : 'inherit')};
  background-repeat: ${({ isFreeMint }) => (isFreeMint ? 'no-repeat' : 'inherit')};
  background-attachment: ${({ isFreeMint }) => (isFreeMint ? 'local' : 'inherit')};
  background-size: ${({ isFreeMint }) => (isFreeMint ? 'cover' : 'inherit')};
  margin: auto;
  max-width: 1940px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: ${({ isFreeMint }) => (isFreeMint ? '91px 0 27px 0' : '100px 0 27px')};
  width: 100%;
  height: ${({ isFreeMint }) => (isFreeMint ? 'inherit' : '100vh')};
  @media ${device.tablet} {
    padding-right: 24px;
    padding-left: 24px;
  }
  @media ${device.mobileL} {
    padding-right: ${({ isFreeMint }) => (isFreeMint ? '0' : '24px')};
    padding-left: ${({ isFreeMint }) => (isFreeMint ? '0' : '24px')};
  }
`;
