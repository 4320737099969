import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import { Loader, Steps } from 'components';
import { IStep } from 'types';
import { useAdminAuthRequire, useFetch } from 'hooks';
import { marketplaceStore } from 'store';
import { AdminRoutesEnum, ALL_NFTS_COUNT, DrawSteps } from 'constants/index';
import { GenerateRandomNumbersView } from './GenerateRandomNumbersView';
import { TopDrawView } from './TopDrawView';
import { BronzeDrawView } from './BronzeDrawView';

export const AdminDrawStepsView = observer(() => {
  useAdminAuthRequire();
  const { t } = useTranslation('views/admin');
  const navigation = useNavigate();
  const [step, setStep] = useState<DrawSteps>(DrawSteps.GenerateRandomNumbers);
  const { loading } = useFetch(() => marketplaceStore.loadDrawData(), []);

  const [waitedTx, setWaitedTx] = useState<any>(null);
  const steps = useMemo<IStep[]>(() => {
    const drawSteps = [DrawSteps.GenerateRandomNumbers, DrawSteps.TopDraw, DrawSteps.BronzeDraw];
    const activeIndex = drawSteps.indexOf(step);
    return drawSteps.map((stepName, index) => ({
      title: t(`drawSteps.${stepName}.stepTitle`),
      description: t(`drawSteps.${stepName}.stepDescription`),
      status: index > activeIndex ? 'pending' : index < activeIndex ? 'done' : 'active',
    }));
  }, [step, t]);

  useEffect(() => {
    if (marketplaceStore.drawData) {
      const { winnersLength, bronzeCounter, isDraw } = marketplaceStore.drawData;
      console.log(isDraw);
      if (bronzeCounter >= ALL_NFTS_COUNT) {
        navigation(AdminRoutesEnum.adminWinners);
        return;
      }

      if (winnersLength < 110) {
        setStep(DrawSteps.GenerateRandomNumbers);
      } else if (winnersLength === 110 && !isDraw) {
        setStep(DrawSteps.TopDraw);
      } else if (isDraw) {
        setStep(DrawSteps.BronzeDraw);
      }
    }
  }, [marketplaceStore.drawData]);

  const renderStepContent = () => {
    switch (step) {
      case DrawSteps.GenerateRandomNumbers:
        return <GenerateRandomNumbersView />;
      case DrawSteps.TopDraw:
        return <TopDrawView setWaitedTx={setWaitedTx} />;
      case DrawSteps.BronzeDraw:
        return <BronzeDrawView />;
    }
  };

  return (
    <StyledContainer>
      <Steps steps={steps} />
      <StyledStepContent>{renderStepContent()}</StyledStepContent>
      <Loader isLoading={loading} />
    </StyledContainer>
  );
});

const StyledContainer = styled.div`
  margin: 103px 0 211px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  height: 100%;
  text-align: center;
`;

const StyledStepContent = styled.div`
  margin-top: 40px;
  width: 100%;
  max-width: 820px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
