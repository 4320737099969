import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { externalLinks } from 'constants/index';
import { GoldenButton } from 'components';
import { device } from 'resources/theme';
import { openExternalLink } from 'utils';

export const CHNoNftLeftView = () => {
  const { t } = useTranslation('views/purchase');
  return (
    <StyledContainer>
      <StyledDescription>{t('noNft.descriptionNoNftLeft')}</StyledDescription>
      <StyledButtonWrapper>
        <GoldenButton onClick={() => openExternalLink(externalLinks.discord)} icon='discord'>
          {t('noNft.join')}
        </GoldenButton>
      </StyledButtonWrapper>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin: 80px auto 200px auto;

  @media ${device.laptopL} {
    margin-top: -30px;
  }

  @media ${device.mobileL} {
    margin: 20px auto 60px auto;
  }
`;

const StyledDescription = styled.p`
  color: ${({ theme }) => theme.lightTextColor};
  font-size: ${({ theme }) => theme.fontSizeM};

  line-height: 24px;
  font-weight: 300;
  text-align: center;
  margin: 16px auto;
  max-width: 480px;

  @media ${device.laptopL} {
    font-size: ${({ theme }) => theme.fontSizeS};
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;
`;
