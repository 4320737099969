import styled from 'styled-components';
import bgDescription from 'resources/images/freeMint/about/aboutBgDescription.png';
import bgDescriptionMobile from 'resources/images/freeMint/about/aboutBgDescriptionMobile.png';
import nftExample1 from 'resources/images/freeMint/nft-example-1.jpg';
import nftExample2 from 'resources/images/freeMint/nft-example-2.jpg';
import nftExample3 from 'resources/images/freeMint/nft-example-3.jpg';
import { GoldenButton } from '../../../components';
import { Gallery } from './gallery';
import { useTranslation } from 'react-i18next';
import { authStore, freeMintStore } from '../../../store';
import { FreeMintModalKeys } from '../../../constants';
import { device } from '../../../resources/theme';

const nftExamples = [nftExample1, nftExample2, nftExample3];

export const FreeMintAboutSectionView = () => {
  const { t } = useTranslation('views/freeMint');
  return (
    <StyledSectionWrapper>
      <StyledAboutContainer>
        <StyledAboutWrapper>
          <StyledAboutTitle1>{t('about.title1')}</StyledAboutTitle1>
          <StyledAboutTitle2>{t('about.title2')}</StyledAboutTitle2>
          <StyledAboutTextWrapper>{t('about.text')}</StyledAboutTextWrapper>
          <StyledMintButton
            isFreeMint={true}
            onClick={() => {
              if (authStore.isAuthorized) {
                freeMintStore.setModal(FreeMintModalKeys.NftOrder);
              } else {
                freeMintStore.setModal(FreeMintModalKeys.NotLoggedIn);
              }
            }}
          >
            {t('mintNow')}
          </StyledMintButton>
        </StyledAboutWrapper>
        <Gallery items={nftExamples} />
        <StyledAboutWrapperMobile>
          <StyledAboutTitle1Mobile>{t('about.title1')}</StyledAboutTitle1Mobile>
          <StyledAboutTitle2Mobile>{t('about.title2')}</StyledAboutTitle2Mobile>
        </StyledAboutWrapperMobile>
      </StyledAboutContainer>
      <StyledDescriptionContainer>
        <StyledDescriptionTextContainer>
          <StyledDescriptionTitle>{t('about.description.title')}</StyledDescriptionTitle>
          <StyledDescriptionTextWrapper>
            <p>{t('about.description.text1')}</p>
            <p>{t('about.description.text2')}</p>
            <p>{t('about.description.text3')}</p>
          </StyledDescriptionTextWrapper>
        </StyledDescriptionTextContainer>
        <StyledDescriptionImageContainer>
          <StyledImage src={bgDescription} alt='' />
          <StyledImageMobile src={bgDescriptionMobile} alt='' />
        </StyledDescriptionImageContainer>
      </StyledDescriptionContainer>
    </StyledSectionWrapper>
  );
};

const StyledSectionWrapper = styled.section`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 120px 50px 147px 50px;
  @media ${device.tablet} {
    padding: 0 24px;
  }
`;

const StyledAboutWrapper = styled.div`
  padding-top: 55px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media ${device.mobileL} {
    padding-top: 5px;
    min-width: 0;
    margin: 0 0 120px 0;
  }
`;

const StyledAboutContainer = styled.div`
  margin-right: 30px;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media ${device.mobileL} {
    flex-direction: column-reverse;
    justify-content: flex-end;
    margin-right: 0;
  }
`;

const StyledAboutWrapperMobile = styled(StyledAboutWrapper)`
  display: none;
  min-width: 300px;
  @media ${device.mobileL} {
    display: block;
    margin: 64px 0 0 0;
  }
`;

const StyledTitleLine1 = styled.h2`
  margin-top: 10px;
  font-family: 'Syne Extra';
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 58px;
  text-transform: uppercase;
  background: linear-gradient(120.43deg, #ffc149 25.88%, #fffea1 41.78%, #b48900 68.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const StyledTitleLine2 = styled.h2`
  font-family: 'Syne Extra';
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 58px;
  text-transform: uppercase;
  background: linear-gradient(120.43deg, #ffc149 25.88%, #fffea1 41.78%, #b48900 68.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const StyledAboutTitle1 = styled(StyledTitleLine1)`
  @media ${device.mobileL} {
    display: none;
  }
`;

const StyledAboutTitle2 = styled(StyledTitleLine2)`
  @media ${device.mobileL} {
    display: none;
  }
`;

const StyledAboutTitle1Mobile = styled(StyledTitleLine1)`
  display: none;
  font-size: 26px;
  line-height: 31px;
  @media ${device.mobileL} {
    display: block;
  }
`;

const StyledAboutTitle2Mobile = styled(StyledTitleLine2)`
  display: none;
  font-size: 26px;
  line-height: 31px;
  @media ${device.mobileL} {
    display: block;
  }
`;

const StyledAboutTextWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  max-width: 525px;
  font-family: 'Montserrat Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  z-index: 2;
  @media ${device.mobileL} {
    margin-right: 6px;
  }
`;

const StyledMintButton = styled(GoldenButton)``;

const StyledDescriptionContainer = styled.div`
  margin-top: 47px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  background: linear-gradient(270deg, rgba(74, 144, 249, 0.43) 0%, rgba(21, 54, 102, 0) 83.33%);
  border-radius: 30px;
  border: 1px solid #4a7bf8;
  padding: 0 57px;
  @media ${device.tablet} {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0 0 0;
    margin-left: 0;
    margin-bottom: 100px;
  }
`;

const StyledDescriptionTextContainer = styled.div`
  padding-top: 40px;
  flex: 1;
  text-align: left;
  @media ${device.tablet} {
    padding: 0 21px 0 22px;
  }
`;

const StyledDescriptionImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 12px 0 0 0;
  text-align: center;
  img {
    max-width: 488px;
  }
  @media ${device.tablet} {
    max-height: 200px;
    flex-direction: row;
    justify-content: center;
    img {
      width: 100%;
    }
  }
`;

const StyledDescriptionTitle = styled.h3`
  font-family: 'Syne Extra';
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  background: linear-gradient(120.43deg, #ffc149 25.88%, #fffea1 41.78%, #b48900 68.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 32px;
  line-height: 38px;
  @media ${device.mobileL} {
    font-size: 24px;
    line-height: 29px;
  }
`;

const StyledDescriptionTextWrapper = styled.div`
  margin-top: 14px;
  text-align: left;
  max-width: 595px;
  font-family: 'Montserrat Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  z-index: 2;
`;

const StyledImage = styled.img`
  display: block;
  @media ${device.tablet} {
    display: none;
  }
`;

const StyledImageMobile = styled.img`
  display: none;
  @media ${device.tablet} {
    display: block;
    border-radius: 30px;
  }
`;
