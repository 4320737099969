import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { device } from 'resources/theme';
import { IMenuItem } from 'types';
import { IconImage } from 'components/icons';
import { observer } from 'mobx-react-lite';
import { authStore } from 'store';

interface IProps {
  items: IMenuItem[];
  rotation?: 'column' | 'row';
  rightSide?: boolean;
  setOpenMenu?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenProfileMenu?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NavMenu = observer(
  ({ items, rotation = 'row', rightSide = false, setOpenMenu, setOpenProfileMenu, ...props }: IProps) => {
    const { t } = useTranslation();

    return (
      <>
        <StyledList rotation={rotation} {...props}>
          {items.map((item) => {
            return (
              <StyledListItem key={`${item.link}${item.name}`} rightSide={rightSide}>
                {item.isExternal ? (
                  <StyledExternalLink href={item.link}>
                    {' '}
                    {item.icon ? <StyledIconImage icon={item.icon} /> : <></>} {t(item.name)}
                  </StyledExternalLink>
                ) : (
                  <StyledLink
                    to={item.link}
                    onClick={() => {
                      setOpenMenu ? setOpenMenu(false) : {};
                      setOpenProfileMenu ? setOpenProfileMenu(false) : {};
                      item.icon === 'logout' || item.icon === 'logoutGold' ? authStore.signOut() : {};
                    }}
                  >
                    {' '}
                    {item.icon ? <StyledIconImage icon={item.icon} /> : <></>} {t(item.name)}
                  </StyledLink>
                )}
              </StyledListItem>
            );
          })}
        </StyledList>
      </>
    );
  },
);

const StyledList = styled.ul<{ rotation?: string }>`
  list-style-type: none;
  display: flex;
  padding-left: 0;
  flex-direction: ${({ rotation }) => rotation};
`;

const StyledListItem = styled.li<{ rightSide?: boolean }>`
  display: block;
  padding: ${({ rightSide }) => (rightSide ? '7px 0' : '10px 15px')};
  font-size: ${({ theme, rightSide }) => (rightSide ? theme.fontSizeL : theme.fontSizeM)};
  text-align: ${({ rightSide }) => (rightSide ? 'right' : 'left')};
  text-transform: ${({ rightSide }) => (rightSide ? 'uppercase' : '')};
  color: ${({ theme }) => theme.lightTextColor};
  @media ${device.laptop} {
    padding: ${({ rightSide }) => (rightSide ? '7px 0' : '10px 10px')};
    font-size: ${({ theme }) => theme.fontSizeM};
    white-space: nowrap;
  }
`;

const StyledIconImage = styled(IconImage)`
  position: relative;
  top: 5px;
  margin-right: 15px;
`;

const StyledExternalLink = styled.a`
  font-family: 'Ginora Sans', serif !important;
  font-weight: 400;
  text-transform: inherit;
  text-decoration: none;
  white-space: nowrap;
  color: inherit;
  &:hover {
    background: ${({ theme }) => theme.linearGradientGold};
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    img {
      filter: unset;
    }
  }
`;

const StyledLink = styled(NavLink)`
  font-family: 'Ginora Sans', serif !important;
  font-weight: 400;
  text-transform: inherit;
  text-decoration: none;
  white-space: nowrap;
  color: inherit;
  &:hover {
    background: ${({ theme }) => theme.linearGradientGold};
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    img {
      filter: unset;
    }
  }
`;
