import { ReactNode } from 'react';
import styled from 'styled-components';
import { SquareIconButton } from 'components/buttons/SquareIconButton';
import { GoldenButton } from 'components/buttons';
import { device } from 'resources/theme';

interface IProps {
  isOpen: boolean;
  loading?: boolean;
  className?: string;
  close?: () => void;
  children?: ReactNode;
  disable?: boolean;
  onSubmit?: () => void;
  submitButtonText?: string;
  maxWidthForLaptop?: string;
}
export const Modal = ({
  isOpen,
  loading,
  close,
  children,
  onSubmit,
  submitButtonText = 'continue',
  maxWidthForLaptop,
}: IProps) => {
  return (
    <StyledBackground isOpen={isOpen}>
      <StyledContainer maxWidthForLaptop={maxWidthForLaptop}>
        <StyledCloseButton onClick={close}>✕</StyledCloseButton>
        <StyledModalWindow>
          {children}
          {onSubmit && (
            <StyledButtonsContainer>
              <StyledButton onClick={onSubmit} primary={true} disabled={loading}>
                {submitButtonText}
              </StyledButton>
            </StyledButtonsContainer>
          )}
        </StyledModalWindow>
      </StyledContainer>
    </StyledBackground>
  );
};

const StyledBackground = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
`;

const StyledContainer = styled.div<{ maxWidthForLaptop?: string }>`
  position: relative;
  background: ${({ theme }) => theme.linearGradientGold};
  width: 45%;
  max-width: 860px;
  height: auto;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 2px;
  color: rgba(0, 0, 139, 0.9);
  @media ${device.laptop} {
    width: 70%;
    max-width: ${({ maxWidthForLaptop }) => (maxWidthForLaptop ? maxWidthForLaptop : '')};
  }
  @media ${device.mobileL} {
    width: 90%;
  }
`;

const StyledModalWindow = styled.div`
  background: ${({ theme }) => theme.bgColor};
  width: auto;
  height: 100%;
  border-radius: 10px;
  padding: 5% 10%;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(GoldenButton)<{ primary?: boolean }>`
  border-radius: 5px;
`;

const StyledCloseButton = styled(SquareIconButton)`
  position: absolute;
  top: -25px;
  right: 0;
  transform: translate(0, -100%);
  &:after {
    position: absolute;
    top: -25px;
    right: 0;
    transform: translate(0, -100%);
  }
  @media ${device.laptop} {
    width: 26px;
    height: 26px;
    font-size: 20px;
    font-weight: 700;
  }
`;
