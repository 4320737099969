import { makeAutoObservable } from 'mobx';
import { MarketplaceContract } from '../blockchain';
import { appConfig } from 'config';
import { getFromLS, setToLS } from 'utils';
import { LocalStorageKeys } from 'constants/index';

type DrawData = null | { winnersLength: number; bronzeCounter: number; isDraw: boolean };

export class MarketplaceStore {
  saleTs: number = getFromLS(LocalStorageKeys.SaleTs, 0);
  isSale: boolean = getFromLS(LocalStorageKeys.IsSale, false);
  isPresale: boolean = getFromLS(LocalStorageKeys.IsPresale, false);
  drawData: DrawData = null;

  constructor() {
    makeAutoObservable(this);
    // this.init();
  }

  async init() {
    this.saleTs = await MarketplaceContract.getSaleTs();
    this.isSale = this.saleTs < Date.now();
    this.isPresale = !this.isSale && appConfig.presaleTs < Date.now();
    setToLS(LocalStorageKeys.IsSale, this.isSale);
    setToLS(LocalStorageKeys.IsPresale, this.isPresale);
    setToLS(LocalStorageKeys.SaleTs, this.saleTs);
  }

  get targetDate() {
    return this.isPresale ? new Date(marketplaceStore.saleTs) : new Date(appConfig.presaleTs);
  }

  async loadDrawData() {
    const winnersLength = await MarketplaceContract.getWinnersLength();
    const isDraw = await MarketplaceContract.isDraw();
    const bronzeCounter = await MarketplaceContract.getBronzeCounter();
    this.drawData = { winnersLength, bronzeCounter, isDraw };
  }
}

export const marketplaceStore = new MarketplaceStore();
