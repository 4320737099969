import { ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  children: ReactNode;
  onClick?: () => void;
}

export const SecondaryButton = ({ children, onClick, ...props }: IProps) => {
  return (
    <StyledButton onClick={onClick} {...props}>
      <span>{children}</span>
    </StyledButton>
  );
};

const StyledButton = styled.button`
  color: ${({ theme }) => theme.bgColor};
  font-size: ${({ theme }) => theme.buttonsFontSize};
  border: none;
  outline: none;
  border-radius: 12px;
  padding: 18px 34px;
  cursor: pointer;
  position: relative;
  width: fit-content;
  background: transparent;
  :before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 8px;
    padding: 1px;
    background: ${({ theme }) => theme.linearGradientGold};
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  span {
    display: inline;
    background: ${({ theme }) => theme.linearGradientGold};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-family: 'Ginora Sans', serif !important;
  }

  &:hover {
    background-color: ${({ theme }) => theme.mainLabelColor};
    span {
      -webkit-background-clip: unset;
      -webkit-text-fill-color: ${({ theme }) => theme.bgColor};
      background-clip: unset;
      background: transparent;
      color: ${({ theme }) => theme.bgColor};
    }
  }
  &:disabled {
    background-color: ${({ theme }) => theme.lightBgColorDisabled};
  }
`;
