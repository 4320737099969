import styled from 'styled-components';

import { device } from 'resources/theme';
import { usePagination } from 'hooks';
import { IconImage } from 'components/icons';

export const Pagination = (props: any) => {
  const { onPageChange, totalCount, siblingCount = 2, currentPage, pageSize } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if ((currentPage === 0 && paginationRange) || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const onFirst = () => {
    onPageChange(1);
  };

  const onLast = () => {
    onPageChange(Math.ceil(totalCount / pageSize));
  };

  const lastPage = paginationRange ? paginationRange[paginationRange.length - 1] : undefined;
  return (
    <StyledList>
      <li className={currentPage === 1 ? 'disabled' : ''}>
        <StyledArrow onClick={onFirst} icon='doubleArrowRight' />
        <StyledArrow onClick={onPrevious} icon='arrowRight' />
      </li>
      {paginationRange?.map((pageNumber) => {
        return (
          <li
            key={pageNumber}
            className={pageNumber === currentPage ? 'selected' : 'clickable'}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li className={currentPage === lastPage ? 'disabled' : ''}>
        <StyledArrow onClick={onNext} icon='arrowRight' />
        <StyledArrow onClick={onLast} icon='doubleArrowRight' />
      </li>
    </StyledList>
  );
};

const StyledArrow = styled(IconImage)`
  cursor: pointer;
`;

const StyledList = styled.ul`
  display: flex;
  margin: 53px auto;
  color: white;
  gap: 0px;
  list-style-type: none;
  padding: 0;
  li {
    font-family: 'Ginora Sans';
    font-style: normal;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSizeXL};
    line-height: 16px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    background: linear-gradient(
      154.14deg,
      #edc73d 7.04%,
      #fdfacc 27.76%,
      #f4eebb 32.95%,
      #decd8e 42.45%,
      #ba9946 55.4%,
      #b4903a 57.99%,
      #e6d48a 81.31%,
      #fdfacc 92.54%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-left: 24px;
  }
  .selected {
    font-weight: 700;
    font-size: 32px;
  }
  .clickable {
    cursor: pointer;
  }
  .disabled {
    transform: scale(0.6, 0.6);
    pointer-events: none;
  }
  li:first-child {
    margin-right: 30px;

    ${StyledArrow} {
      transform: rotate(180deg);
      margin-right: 10px;
      margin-left: 0;
    }
  }
  li:last-child {
    margin-left: 30px;
    ${StyledArrow} {
      margin-left: 10px;
    }
  }
  @media ${device.mobileL} {
    li:first-child {
      margin-right: 10px;
    }
    li:last-child {
      margin-left: 10px;
    }
  }
`;
