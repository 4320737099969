import React from 'react';
import { FreeMintSimpleModal } from './FreeMintSimpleModal';
import { freeMintStore } from '../../../store';
import { FreeMintModalKeys } from '../../../constants';
import { useTranslation } from 'react-i18next';

interface IProps {
  onClose?: () => void;
}

export const FreeMintUnsuccessfulMintModal = ({ onClose }: IProps) => {
  const { t } = useTranslation('views/freeMint');

  return (
    <FreeMintSimpleModal
      icon='goldenXMark'
      title={t('modal.unsuccessfulMint.title')}
      text={t('modal.unsuccessfulMint.text')}
      onClose={onClose}
      onSubmit={() => {
        freeMintStore.setModal(FreeMintModalKeys.NftOrder);
      }}
      submitButtonText={t('modal.unsuccessfulMint.buttonText')}
    />
  );
};
