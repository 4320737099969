import { mainTheme } from 'resources/theme';

export const checkColor = (status: string) => {
  switch (status) {
    case 'done':
      return mainTheme.linearGradientGold;
    case 'active':
      return mainTheme.lightTextColor;
    default:
      return '#8792A2';
  }
};
