import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router';

import { device } from 'resources/theme';
import { RoutesEnum } from 'constants/index';
import { AccsesNFTCard, GoldenButton } from 'components';

export const NotAuthorizedView = () => {
  const { t } = useTranslation('views/purchase');
  const navigate = useNavigate();
  return (
    <StyledContainer>
      <StyledCard>{t('notAuthorizedView.accsessNFT')}</StyledCard>
      <StyledBtnContainer>
        <StyledGoldenButton onClick={() => navigate(RoutesEnum.signIn)}>
          {t('notAuthorizedView.logInToPurchase')}
        </StyledGoldenButton>
      </StyledBtnContainer>
    </StyledContainer>
  );
};

const StyledCard = styled(AccsesNFTCard)`
  margin: auto;
  @media ${device.mobileL} {
    margin-top: 24px;
  }
`;

const StyledContainer = styled.div`
  padding: 40px 0 100px;
  width: 100%;
  @media ${device.mobileL} {
    padding-top: 0px;
    padding-bottom: 60px;
  }
`;

const StyledBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  float: top;
`;

const StyledGoldenButton = styled(GoldenButton)`
  font-size: ${({ theme }) => theme.fontSizeL};
`;
