import React from 'react';
import styled from 'styled-components';

import { device } from 'resources/theme';

import { Modal } from './Modal';
import { IconImage } from '../icons';

interface IProps {
  isOpen: boolean;
  close?: () => void;
  message: string;
}

export const SuccessModal = ({ isOpen, close, message }: IProps) => {
  return (
    <Modal maxWidthForLaptop='485px' isOpen={isOpen} close={close}>
      <StyledModalContainer>
        <StyledModalIcon icon='goldenCheckMark' />
        <StyledModalMessage>{message}</StyledModalMessage>
      </StyledModalContainer>
    </Modal>
  );
};

const StyledModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.laptop} {
    height: 95px;
  }
`;

const StyledModalIcon = styled(IconImage)`
  width: 49px;
  height: 49px;
  @media ${device.laptop} {
    height: 35px;
    width: 35px;
  }
  @media ${device.mobileL} {
    height: 33px;
    width: 33px;
  }
`;

const StyledModalMessage = styled.span`
  padding-left: 40px;
  font-family: 'Ginora Sans', serif !important;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-transform: uppercase;
  text-align: center;
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSizeM};
    line-height: 120%;
    padding-left: 30px;
  }
  @media ${device.mobileL} {
    padding-left: 24px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0px;
  }
`;
