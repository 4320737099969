import { makeAutoObservable } from 'mobx';
import { CryptoHuntersContract } from '../blockchain';

export class CryptoHuntersStore {
  isMintEnabled: boolean | undefined;
  isPublicSale: boolean | undefined;

  constructor() {
    makeAutoObservable(this);
    this.init();
  }

  async init() {
    try {
      this.isMintEnabled = await CryptoHuntersContract.isMintEnabled();
      this.isPublicSale = await CryptoHuntersContract.isPublicSaleEnabled();
    } catch (e) {
      console.log('Error occurred while fetching mint and public sale start date');
    }
  }
}

export const cryptoHuntersStore = new CryptoHuntersStore();
