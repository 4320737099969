import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { RoutesEnum } from 'constants/index';
import { authStore } from 'store';

interface IHookOption {
  isRedirect?: boolean;
  redirectRoute?: RoutesEnum;
}

export const useAdminAuthRequire = ({ isRedirect = true, redirectRoute = RoutesEnum.home }: IHookOption = {}): void => {
  const isAuthorized = authStore.isAuthorized;
  const isAdmin = authStore.isAdmin;
  const navigate = useNavigate();

  const isAdminCheck = async () => {
    const checkResult = await authStore.isAdminCheck();
    if (!checkResult) {
      navigate(redirectRoute);
    }
  };

  useEffect(() => {
    if (!isAuthorized) {
      return navigate(redirectRoute);
    }
    if (isAdmin) return;

    isAdminCheck();
  }, [isAuthorized, isAdmin, history, isAuthorized, isRedirect, redirectRoute]);
};
