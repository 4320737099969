import { ethers } from 'ethers';
import { metamaskProvider, rpcProvider } from 'blockchain';

export const getAccountBalance = async (accountAddress: string): Promise<string> => {
  return ethers.utils.formatEther(await rpcProvider.getBalance(accountAddress));
};

export const fixMetamaskConnection = async (waitSeconds = 1) => {
  // Reloads the page after n seconds if Metamask is installed but not initialized
  if (
    typeof window !== 'undefined' &&
    typeof metamaskProvider !== 'undefined' &&
    !metamaskProvider._state.initialized
  ) {
    setTimeout(() => window.location.reload(), waitSeconds * 1000);
  }
};
