import { ReactNode } from 'react';
import styled from 'styled-components';

import { device } from 'resources/theme';

interface IProps {
  color?: 'mainLabelColor';
  children: ReactNode;
}

export const Tag = ({ color = 'mainLabelColor', children, ...props }: IProps) => {
  return (
    <StyledTag color={color} {...props}>
      {children}
    </StyledTag>
  );
};

const StyledTag = styled.span<{ color: string }>`
  padding: 6px 30px;
  background-color: ${({ theme, color }) => theme[color]};
  font-size: ${({ theme }) => theme.tagFontSize};
  border-radius: 10px;

  @media ${device.mobileL} {
    font-size: ${({ theme }) => theme.fontSizeXs};
    padding: 4px 20px;
    border-radius: 6px;
  }
`;
