import { GoldenLabel } from 'components';
import { useTranslation } from 'react-i18next';
import { device } from 'resources/theme';

import styled from 'styled-components';
import { INFTAttributes } from 'types';

interface INFTImage {
  src: string;
  id: number;
  attributes?: INFTAttributes[] | null;
  isAfterMint?: boolean;
}

export const NFTImage = ({ src, attributes = null, isAfterMint = true, id }: INFTImage) => {
  const status = attributes ? attributes.find((attr) => attr.trait_type === 'Status') : null;
  const prize = status ? status.value : null;
  const { t } = useTranslation('views/profile');
  return (
    <StyledContainer>
      <StyledImageContainer>
        <StyledInnerContainer isAfterMint={isAfterMint}>
          <StyledImage isAfterMint={isAfterMint} src={src} />
          {isAfterMint ? <></> : <StyledText>{t('nft.uncoverNFT')}</StyledText>}
          <StyledPriceLabel>
            <StyledPriceSpan>{`ID: ${id}`}</StyledPriceSpan>
            {prize && <StyledPriceSpan>{prize}</StyledPriceSpan>}
          </StyledPriceLabel>
        </StyledInnerContainer>
      </StyledImageContainer>
    </StyledContainer>
  );
};

const StyledText = styled.div`
  margin: 0 auto 58px auto;
  font-family: 'Ginora Sans';
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizeL};
  line-height: 110%;
  text-align: center;
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSizeS};
    margin: 0 auto 46px auto;
  }
  @media ${device.mobileL} {
    font-size: ${({ theme }) => theme.fontSizeM};
    margin: 0 auto 17px auto;
  }
`;

const StyledImageContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  border-style: solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: ${({ theme }) => theme.linearGradientGoldTransparent};
  background: rgba(255, 255, 255, 0.05);
`;

const StyledInnerContainer = styled.div<{ isAfterMint?: boolean }>`
  padding: ${({ isAfterMint }) => (isAfterMint ? '23px 26px' : '25px 52px')};
  @media ${device.laptop} {
    padding: 15px 17px};
  }
  @media ${device.mobileL} {
    padding: ${({ isAfterMint }) => (isAfterMint ? '14px 16px' : '14px 23px')};
  }
`;

const StyledImage = styled.img<{ isAfterMint?: boolean }>`
  object-fit: cover;
  width: ${({ isAfterMint }) => (isAfterMint ? '405px' : '350px')};
  height: ${({ isAfterMint }) => (isAfterMint ? '405px' : '350px')};
  margin-bottom: 30px;
  @media ${device.laptop} {
    width: ${({ isAfterMint }) => (isAfterMint ? '273px' : '273px')};
    height: ${({ isAfterMint }) => (isAfterMint ? '273px' : '259px')};
    margin-bottom: ${({ isAfterMint }) => (isAfterMint ? '18px' : '0px')};
  }
  @media ${device.mobileL} {
    width: ${({ isAfterMint }) => (isAfterMint ? '262px' : '250px')};
    height: ${({ isAfterMint }) => (isAfterMint ? '240px' : '240px')};
  }
`;

const StyledSpan = styled.span`
  font-family: 'Ginora Sans', serif !important;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeL};
`;

const StyledPriceSpan = styled(StyledSpan)`
  display: block;
  font-size: ${({ theme }) => theme.fontSizeXL};
  @media ${device.laptop} {
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSizeM};
  }
`;

const StyledPriceLabel = styled(GoldenLabel)`
  width: 100%;
  text-align: center;
  @media ${device.laptop} {
    padding: 6px 0;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 6px;
`;
