import { createGlobalStyle } from 'styled-components';
import { ITheme } from 'types';

export const FreeMintGlobalStyle = createGlobalStyle<{ theme: ITheme }>`

  * {
    font-family: "Montserrat Regular", sans-serif;
  }
  
  h1, h2, h3 {
    margin: 0;
  }
  
  body {
    padding: 0 !important;
    margin: 0 !important;
    background: none !important;
    background-color: ${({ theme }) => theme.freeMintBgColor} !important;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
`;
