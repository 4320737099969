import { ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  children: ReactNode;
  onClick?: () => void;
}

export const PrimaryButton = ({ children, onClick, ...props }: IProps) => {
  return (
    <StyledButton onClick={onClick} {...props}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.mainColor};
  color: ${({ theme }) => theme.lightTextColor};
  border: 1px solid ${({ theme }) => theme.mainColorDark};
  outline: none;
  padding: 6px 16px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.mainColorDark};
  }
`;
