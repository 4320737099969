import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { GoldenButton } from 'components';
import { device } from 'resources/theme';
import { appConfig } from 'config';

export const FrozenSaleView = () => {
  const { t } = useTranslation('views/purchase');
  return (
    <StyledContainer>
      <StyledTitle>
        <StyledTitleText>{t('frozenSale.title')}</StyledTitleText>
      </StyledTitle>
      <StyledDescription>{t('frozenSale.description')}</StyledDescription>
      <StyledButtonWrapper>
        <a href={appConfig.homePageUrl}>
          <GoldenButton>{t('frozenSale.backToHome')}</GoldenButton>
        </a>
      </StyledButtonWrapper>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin: 80px auto 200px auto;

  @media ${device.laptopL} {
    margin-top: -30px;
  }

  @media ${device.mobileL} {
    margin: 20px auto 60px auto;
  }
`;

const StyledTitle = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  margin: 30px auto;
  max-width: 700px;

  @media ${device.laptopL} {
    margin-top: -30px;
  }

  @media ${device.mobileL} {
    max-width: 340px;
    margin: 30px auto;
    font-size: ${({ theme }) => theme.fontSizeXL};
    flex-direction: column-reverse;
  }
`;

const StyledTitleText = styled.span`
  display: block;
  font-family: 'Ginora Sans', serif !important;
  margin-top: 40px;
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  letter-spacing: 1px;
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
  text-align: center;

  @media ${device.laptopL} {
    margin-top: 0;
    max-width: 480px;
  }

  @media ${device.mobileL} {
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

const StyledDescription = styled.p`
  color: ${({ theme }) => theme.lightTextColor};
  font-size: ${({ theme }) => theme.fontSizeM};

  line-height: 24px;
  font-weight: 300;
  text-align: center;
  margin: 16px auto;
  max-width: 580px;

  @media ${device.laptopL} {
    font-size: ${({ theme }) => theme.fontSizeS};
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;
`;
