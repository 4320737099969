import { ReactNode } from 'react';
import styled from 'styled-components';
import { device } from 'resources/theme';
import { Icons } from 'types';
import { IconImage } from 'components/icons';

interface IProps {
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  icon?: Icons;
  isFreeMint?: boolean;
}

export const GoldenButton = ({ children, onClick, disabled = false, icon, isFreeMint, ...props }: IProps) => {
  return (
    <StyledContainer>
      <StyledButton onClick={onClick} disabled={disabled} isFreeMint={!!isFreeMint} {...props}>
        <span>{children}</span>
        {icon && <IconImage icon={icon} />}
      </StyledButton>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: relative;
  width: fit-content;
  background: transparent;
  :before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 5px;
    padding: 1px;
    background: ${({ theme }) => theme.linearGradientGold};
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

const StyledButton = styled.button<{ isFreeMint: boolean }>`
  user-select: ${({ isFreeMint }) => (isFreeMint ? 'none' : 'inherit')};
  display: flex;
  margin: 0;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 12px;
  text-transform: uppercase;
  z-index: 1;
  padding: 10px 20px;
  position: relative;
  background: ${({ theme }) => theme.linearGradientGold};
  outline: none;
  font-family: 'Ginora Sans', serif !important;
  font-size: ${({ theme }) => theme.fontSizeL};
  box-sizing: border-box;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;

  span {
    display: inline;
    background: ${({ theme }) => theme.linearGradientGold};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-family: 'Ginora Sans', serif !important;
  }

  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSizeM};
  }

  &:hover,
  &:active {
    color: ${({ theme }) => theme.bgColor};
    background-clip: unset;
    -webkit-background-clip: unset;
    -moz-background-clip: unset;
    text-fill-color: ${({ theme }) => theme.bgColor};
    -webkit-text-fill-color: ${({ theme }) => theme.bgColor};
    -moz-text-fill-color: ${({ theme }) => theme.bgColor};

    span {
      color: ${({ theme }) => theme.bgColor};
      background: transparent;
      background-clip: unset;
      -webkit-background-clip: unset;
      -moz-background-clip: unset;
      text-fill-color: ${({ theme }) => theme.bgColor};
      -webkit-text-fill-color: ${({ theme }) => theme.bgColor};
      -moz-text-fill-color: ${({ theme }) => theme.bgColor};
    }

    img {
      filter: brightness(0);
    }
  }

  &:active {
    background: ${({ theme, isFreeMint }) => (isFreeMint ? theme.linearGradientGold : '#c4c4c4')};
  }

  &:disabled {
    background: #c4c4c4;
    cursor: auto;
    span {
      color: ${({ theme }) => theme.bgColor};
      background: transparent;
      background-clip: unset;
      -webkit-background-clip: unset;
      -moz-background-clip: unset;
      text-fill-color: ${({ theme }) => theme.bgColor};
      -webkit-text-fill-color: ${({ theme }) => theme.bgColor};
      -moz-text-fill-color: ${({ theme }) => theme.bgColor};
    }
  }
`;
