import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { externalLinks, RoutesEnum } from 'constants/index';
import { GoldenButton, IconImage } from 'components';
import { device } from 'resources/theme';
import { openExternalLink } from 'utils';

interface IProps {
  nftLeft?: number | null;
}

export const NoNftView = ({ nftLeft = 100010 }: IProps) => {
  const { t } = useTranslation('views/purchase');
  const navigate = useNavigate();
  const noNftLeft = nftLeft === 0;
  return (
    <StyledContainer>
      {!noNftLeft && (
        <StyledTitle>
          <StyledIcon icon='party' />
          <StyledTitleText>{t('noNft.title')}</StyledTitleText>
        </StyledTitle>
      )}
      <StyledDescription>{noNftLeft ? t('noNft.descriptionNoNftLeft') : t('noNft.description')}</StyledDescription>
      <StyledButtonWrapper>
        {noNftLeft ? (
          <GoldenButton onClick={() => openExternalLink(externalLinks.discord)} icon='discord'>
            {t('noNft.join')}
          </GoldenButton>
        ) : (
          <GoldenButton onClick={() => navigate(RoutesEnum.profileNFT)}>{t('noNft.viewYourNft')}</GoldenButton>
        )}
      </StyledButtonWrapper>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin: 80px auto 200px auto;

  @media ${device.laptopL} {
    margin-top: -30px;
  }

  @media ${device.mobileL} {
    margin: 20px auto 60px auto;
  }
`;

const StyledTitle = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  margin: 30px auto;
  max-width: 700px;

  @media ${device.laptopL} {
    margin-top: -30px;
  }

  @media ${device.mobileL} {
    max-width: 340px;
    margin: 30px auto;
    font-size: ${({ theme }) => theme.fontSizeXL};
    flex-direction: column-reverse;
  }
`;

const StyledTitleText = styled.span`
  display: block;
  font-family: 'Ginora Sans', serif !important;
  margin-top: 40px;
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  letter-spacing: 1px;
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
  text-align: center;

  @media ${device.laptopL} {
    margin-top: 0;
    max-width: 480px;
    font-size: ${({ theme }) => theme.headlineSmallerSize};
  }

  @media ${device.mobileL} {
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

const StyledIcon = styled(IconImage)`
  width: 123px;
  height: 123px;
  margin-right: 20px;

  @media ${device.laptopL} {
    width: 74px;
    height: 89px;
    margin-right: 10px;
  }

  @media ${device.mobileL} {
    width: 60px;
    height: 60px;
  }
`;

const StyledDescription = styled.p`
  color: ${({ theme }) => theme.lightTextColor};
  font-size: ${({ theme }) => theme.fontSizeM};

  line-height: 24px;
  font-weight: 300;
  text-align: center;
  margin: 16px auto;
  max-width: 480px;

  @media ${device.laptopL} {
    font-size: ${({ theme }) => theme.fontSizeS};
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;
`;
