import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { appConfig } from 'config';
import { marketplaceStore } from 'store';
import { GoldenButton, NFTImage, Loader } from 'components';
import { CryptoHuntersRoutesEnum } from 'constants/index';
import { device } from 'resources/theme';
import { useFetch } from 'hooks';
import { getNFTs } from 'api';
import { ChNftImage } from '../../components/ch-nft-image';

const isAfterMint = appConfig.saleTs < Date.now();

export const ProfileNFT = observer(() => {
  const { t } = useTranslation('views/profile');
  const navigate = useNavigate();
  const { response, loading } = useFetch(async () => await getNFTs({ page: 1, ipp: 10000 }), []);
  const NFTlist = response ? response.result : [];
  return (
    <>
      <StyledNFTImageContainer>
        {loading ? (
          <StyledLoadingSpace />
        ) : NFTlist.length > 0 ? (
          NFTlist.map((item) => {
            const metaData = item.tokenMeta;
            const key = `${metaData.name || ''}_${item.tokenId}`;
            const contractAddress = appConfig.cryptoHunters.contracts.CryptoHunters.address.toLowerCase();
            if (item.contractAddress.toLowerCase() === contractAddress) {
              return (
                <ChNftImage
                  src={item.tokenMeta.image}
                  key={key}
                  id={item.tokenId}
                  name={metaData.name}
                  attributes={metaData.attributes || []}
                />
              );
            } else {
              return (
                <NFTImage
                  src={item.tokenMeta.image}
                  key={key}
                  id={item.tokenId}
                  attributes={metaData.attributes}
                  isAfterMint={isAfterMint}
                />
              );
            }
          })
        ) : (
          <StyledMessageContainer>
            <StyledHeader>{t('nft.noNFT')}</StyledHeader>
          </StyledMessageContainer>
        )}
      </StyledNFTImageContainer>
      <StyledBtnContainer>
        {!marketplaceStore.isSale ? (
          <a href={appConfig.homePageUrl}>
            <GoldenButton>{t('details.backToHome')}</GoldenButton>
          </a>
        ) : (
          <GoldenButton
            onClick={() => {
              // navigate(RoutesEnum.mint) // TODO revert
              navigate(CryptoHuntersRoutesEnum.cryptoHuntersMint); // TODO remove
            }}
          >
            {t('nft.mint')}
          </GoldenButton>
        )}
      </StyledBtnContainer>
      <Loader isLoading={loading} />
    </>
  );
});

const StyledLoadingSpace = styled.div`
  height: 180px;
`;

const StyledMessageContainer = styled.div`
  width: 95%;
  max-width: 600px;
  margin: auto;
  padding-top: 140px;

  @media ${device.laptopL} {
    padding-top: 24px;
  }
`;

const StyledHeader = styled.div`
  font-family: 'Ginora Sans';
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;

  @media ${device.laptopL} {
    font-size: ${({ theme }) => theme.headlineSmallerSize};
  }

  @media ${device.mobileL} {
    font-size: ${({ theme }) => theme.fontSizeXL};
  }
`;

const StyledNFTImageContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  @media ${device.laptop} {
    justify-content: center;
    gap: 60px;
  }
  @media ${device.mobileL} {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledBtnContainer = styled.div`
  margin: 48px auto 120px;
  @media ${device.laptop} {
    button {
      font-size: ${({ theme }) => theme.fontSizeS};
    }
    margin: 32px auto 68px;
  }
  @media ${device.mobileL} {
    margin: 48px auto 60px;
  }
`;
