import { ReactNode } from 'react';
import styled from 'styled-components';
import { device } from 'resources/theme';
import { SquareIconButton } from '../buttons/SquareIconButton';

interface IProps {
  onClose?: () => void;
  children?: ReactNode;
  maxWidth?: string;
}
export const WhitelistModal = ({ onClose, children, maxWidth }: IProps) => {
  return (
    <StyledBackground>
      <StyledContainer maxWidth={maxWidth}>
        <StyledButtonsContainer>
          <StyledCloseButton onClick={onClose}>✕</StyledCloseButton>
        </StyledButtonsContainer>
        <StyledModalWindow>{children}</StyledModalWindow>
      </StyledContainer>
    </StyledBackground>
  );
};

const StyledBackground = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
`;

const StyledContainer = styled.div<{ maxWidth?: string }>`
  position: relative;
  background: ${({ theme }) => theme.linearGradientGold};
  width: 50%;
  max-width: 500px;
  height: auto;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 2px;
  color: rgba(0, 0, 139, 0.9);
  @media ${device.mobileL} {
    width: calc(100% - 44px);
  }
`;

const StyledModalWindow = styled.div`
  background: ${({ theme }) => theme.bgColor};
  width: auto;
  height: 100%;
  border-radius: 10px;
  padding: 50px 40px;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledCloseButton = styled(SquareIconButton)`
  position: absolute;
  top: -25px;
  right: 0;
  transform: translate(0, -100%);
  &:after {
    position: absolute;
    top: -25px;
    right: 0;
    transform: translate(0, -100%);
  }
  @media ${device.laptop} {
    width: 26px;
    height: 26px;
    font-size: 20px;
    font-weight: 700;
  }
`;
