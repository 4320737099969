import { makeAutoObservable } from 'mobx';

import { IBaseError } from 'types';
import { ErrorKeys } from 'constants/index';

export class ErrorStore {
  errors: Record<string, IBaseError | null> = {};

  constructor() {
    makeAutoObservable(this);
  }

  setError(error: IBaseError) {
    this.errors = {
      ...this.errors,
      [error.key]: error,
    };
    return error;
  }

  removeError(key: ErrorKeys) {
    this.errors = {
      ...this.errors,
      [key]: null,
    };
  }
}

export const errorStore = new ErrorStore();
