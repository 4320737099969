import { appConfig } from 'config';
import {
  ISignInRequestBody,
  ISignInResponseBody,
  ISignatureCodeRequestQuery,
  ISignatureCodeResponseBody,
  IRefreshAccessTokenResponseBody,
  IRefreshAccessTokenRequestBody,
  IUserVrsRequestBody,
  IUserVrsResponseBody,
  IUserWhitelistRequestQuery,
} from 'types';

import { API } from './API';

export function getSignatureCode(params: ISignatureCodeRequestQuery): Promise<ISignatureCodeResponseBody> {
  return API.get<ISignatureCodeResponseBody>(`${appConfig.apiUrl}/auth/codeForSign`, { params, isPublic: true }).then(
    (response) => response.data,
  );
}

export function signIn(body: ISignInRequestBody): Promise<ISignInResponseBody> {
  return API.post<ISignInRequestBody, ISignInResponseBody>(`${appConfig.apiUrl}/auth/signIn`, body, {
    isPublic: true,
  }).then((response) => response.data);
}

export function refreshAccessToken(body: IRefreshAccessTokenRequestBody): Promise<IRefreshAccessTokenResponseBody> {
  return API.post<IRefreshAccessTokenRequestBody, IRefreshAccessTokenResponseBody>(
    `${appConfig.apiUrl}/auth/refresh`,
    body,
    { isPublic: true },
  ).then((response) => response.data);
}

export function getUserVrs(body: IUserVrsRequestBody): Promise<IUserVrsResponseBody> {
  return API.post<IUserVrsRequestBody, IUserVrsResponseBody>(`${appConfig.apiUrl}/nft/preSale`, body).then(
    (response) => response.data,
  );
}

export function addUserToWhitelist(params: IUserWhitelistRequestQuery): Promise<string> {
  return API.get<string>(`${appConfig.apiUrl}/whiteList/addToWhiteList`, { params }).then((response) => response.data);
}
