import { createGlobalStyle } from 'styled-components';
import { ITheme } from 'types';
import bgEllipse from 'resources/images/bgEllipse.svg';
import bgEllipse2 from 'resources/images/bgEllipse2.svg';
import bgEllipse3 from 'resources/images/bgEllipse3.svg';
import bgEllipse4 from 'resources/images/bgEllipse4.svg';
import { device } from './media';

export const GlobalStyle = createGlobalStyle<{ theme: ITheme }>`

  * {
    font-family: "Poppins", sans-serif;
  }

  body {
    padding: 0;
    margin: 0;
    background-color: ${({ theme }) => theme.bgColor};
    background-image: url(${bgEllipse}), url(${bgEllipse2}), url(${bgEllipse3}), url(${bgEllipse4});
    background-position: right top, left top, left bottom, right bottom;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
    background-attachment: fixed;
    @media ${device.laptopM}{
      background-image: none;
    }
  }
  
  a {
    text-decoration: none;
  }
`;
