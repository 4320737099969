import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { externalLinks } from 'constants/index';
import { GoldenButton } from 'components';
import { device } from 'resources/theme';
import { openExternalLink } from 'utils';

export const CHMintDisabledView = () => {
  const { t } = useTranslation('views/cryptoHunters');
  return (
    <StyledContainer>
      <StyledDescription>{t('purchase.mintDisabledView.textLine1')}</StyledDescription>
      <StyledDescription>{t('purchase.mintDisabledView.textLine2')}</StyledDescription>
      <StyledDescription>{t('purchase.mintDisabledView.textLine3')}</StyledDescription>
      <StyledDescription>{t('purchase.mintDisabledView.textLine4')}</StyledDescription>
      <StyledButtonWrapper>
        <GoldenButton onClick={() => openExternalLink(externalLinks.discord)} icon='discord'>
          {t('purchase.mintDisabledView.joinUs')}
        </GoldenButton>
      </StyledButtonWrapper>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin: -100px auto auto auto;
  padding-bottom: 200px;
  @media ${device.tablet} {
    margin-top: 0;
    padding-bottom: 0;
  }
`;

const StyledDescription = styled.p`
  color: ${({ theme }) => theme.lightTextColor};
  font-size: ${({ theme }) => theme.fontSizeL};

  line-height: 24px;
  font-weight: 300;
  text-align: center;
  margin: 16px auto;
  max-width: 480px;

  @media ${device.laptopL} {
    font-size: ${({ theme }) => theme.fontSizeM};
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;
`;
