import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { ErrorKeys, WalletTypes } from 'constants/index';
import { authStore } from 'store';
import { IconImage, Tag } from 'components';
import { ErrorMessageView } from 'views';
import { device } from 'resources/theme';

export const SignInCardView = observer(() => {
  const { t } = useTranslation();

  const onConnect = async (walletType: WalletTypes) => {
    await authStore.signIn(walletType);
  };

  return (
    <StyledCardWrapper>
      <StyledCard>
        <StyledCardHeader>{t('signInView.cardTitle')}</StyledCardHeader>
        <StyledConnectButton onClick={() => onConnect(WalletTypes.Metamask)}>
          <StyledIcon icon='metamask' /> {t('wallet.metamask')}
          <StyledTag>Popular</StyledTag>
        </StyledConnectButton>
        <StyledConnectButton onClick={() => onConnect(WalletTypes.WalletConnect)}>
          <StyledIcon icon='walletConnect' /> {t('wallet.walletConnect')}
        </StyledConnectButton>
      </StyledCard>
      <ErrorMessageView keys={[ErrorKeys.ConnectError, ErrorKeys.SignInError]} />
    </StyledCardWrapper>
  );
});

const StyledCardWrapper = styled.div`
  max-width: 610px;
  margin: 40px auto;

  @media ${device.mobileL} {
    max-width: 460px;
  }
`;

const StyledCard = styled.div`
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.lightBgColor};
`;

const StyledCardHeader = styled.div`
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  letter-spacing: 1px;
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
  padding: 32px 74px 20px 74px;
  @media ${device.mobileL} {
    text-align: center;
    padding: 12px 20px 4px 20px;
    font-size: ${({ theme }) => theme.fontSizeXL};
  }
`;

const StyledConnectButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.textColor};
  font-size: ${({ theme }) => theme.fontSizeXL};
  padding: 24px 76px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  transition: all 300ms ease;

  &:hover {
    background-color: ${({ theme }) => theme.activeBgColor};
    box-shadow: 0px 4px 24px ${({ theme }) => theme.activeShadowColor};

    &:last-child {
      border-radius: 0 0 10px 10px;
    }
  }

  @media ${device.mobileL} {
    padding: 10px 36px;
    font-size: ${({ theme }) => theme.fontSizeM};
  }
`;

const StyledIcon = styled(IconImage)`
  width: 58px;
  height: 58px;
  margin-right: 30px;

  @media ${device.mobileL} {
    width: 34px;
    height: 34px;
    margin-right: 16px;
  }
`;

const StyledTag = styled(Tag)`
  margin-left: 120px;

  @media ${device.mobileL} {
    margin-left: 54px;
  }

  @media ${device.mobileM} {
    margin-left: 24px;
  }

  @media ${device.mobileM} {
    display: none;
  }
`;
