import { ethers, providers } from 'ethers';
import { appConfig } from 'config';
import { rpcProvider } from '../rpcProvider';

export class FreeMintContract {
  static publicContract = new ethers.Contract(
    appConfig.contracts.FreeMint.address,
    appConfig.contracts.FreeMint.abi,
    rpcProvider,
  );
  contract: ethers.Contract;

  constructor(provider: providers.Web3Provider) {
    this.contract = new ethers.Contract(
      appConfig.contracts.FreeMint.address,
      appConfig.contracts.FreeMint.abi,
      provider.getSigner(),
    );
  }

  static isClaimEnabled = async () => {
    return await FreeMintContract.publicContract.claimEnabled();
  };

  static getMaxTokens = async () => {
    return await FreeMintContract.publicContract.maxTokens();
  };

  static getTotalSupply = async () => {
    return await FreeMintContract.publicContract.totalSupply();
  };

  getNotClaimedTokens = async (accountAddress: string) => {
    const nonClaimedTokens = await this.contract.getNotClaimedTokens(accountAddress);
    return nonClaimedTokens.map((token: any) => token.toString());
  };

  claimTokens = async (tokens: string[]) => {
    const tx = await this.contract.claimNftTokens(tokens);
    return await tx.wait();
  };
}
