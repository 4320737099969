export enum LocalStorageKeys {
  Wallet = 'Wallet',
  WalletAddress = 'WalletAddress',
  AuthorizedAddress = 'AuthorizedAddress',
  AccessToken = 'AccessToken',
  RefreshToken = 'RefreshToken',
  IsAdmin = 'isAdmin',
  IsSale = 'IsSale',
  IsPresale = 'IsPresale',
  SaleTs = 'SaleTs',
  IsCHWhitelistSale = 'IsCHWhitelistSale',
  IsCHPublicSale = 'IsCHPublicSale',
}
