import { IconImage } from 'components/icons';
import { Tooltip } from 'components/tooltips/Tooltip';
import { useState } from 'react';
import { device } from 'resources/theme';
import styled from 'styled-components';
import { IStep } from 'types';
import { checkColor } from 'utils';

interface IProps {
  steps: IStep[];
}

export const Steps = ({ steps }: IProps) => {
  const [stepsVisible, setStepsVisible] = useState(false);
  return (
    <StyledMainContainer>
      <StyledText>
        {`${steps.filter((item) => item.status !== 'pending').length}/${steps.length}:
        ${steps.find((item) => item.status === 'active')?.title}`}
        <StyledIconImage icon='arrowDown' onClick={() => setStepsVisible(!stepsVisible)} />
      </StyledText>
      {steps.map((step, index) => {
        const { title, status, description } = step;
        const color = checkColor(status);
        const isDone = status === 'done';
        const percent = isDone ? Math.round((100 / steps.length) * (index + 1)) : null;

        return (
          <StyledStepContainer key={index}>
            <StyledPercentsContainer>
              {percent ? <StyledPercents>{percent}%</StyledPercents> : null}
            </StyledPercentsContainer>
            <StyledGoldenLine color={color} />
            <Tooltip text={description}>
              <StyledTitleContainer index={steps.length - index} stepsVisible={stepsVisible}>
                <StyledInnerContainer index={index} lastIndex={steps.length - 1}>
                  {isDone ? <StyledDoneImg icon='doneMarkInCircle' /> : <StyledCircle color={color} />}
                  <StyledTitle color={color}>{title}</StyledTitle>
                  <StyledDescription>{description}</StyledDescription>
                </StyledInnerContainer>
              </StyledTitleContainer>
            </Tooltip>
          </StyledStepContainer>
        );
      })}
    </StyledMainContainer>
  );
};

const StyledDescription = styled.div`
  display: none;
  @media ${device.mobileL} {
    margin-top: -5px;
    display: block;
    grid-area: c;
    justify-self: start;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    width: 100%;
    height: 42px;
    overflow: hidden;
  }
`;

const StyledText = styled.div`
  display: none;
  position: absolute;
  top: 40px;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizeM};
  line-height: 120%;
  background: ${({ theme }) => theme.linearGradientGold};
  box-sizing: border-box;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  align-items: center;
  @media ${device.mobileL} {
    display: flex;
  }
`;

const StyledIconImage = styled(IconImage)`
  margin-left: 17px;
  width: 18px;
`;

const StyledMainContainer = styled.div`
  display: flex;
  margin-top: 103px;
  width: 100%;
`;

const StyledStepContainer = styled.div`
  width: 100%;
  margin: 0 4px;
  @media ${device.mobileL} {
    margin-top: 30px;
  }
`;
const StyledPercentsContainer = styled.div`
  height: 39px;
  position: relative;
  background: ${({ theme }) => theme.linearGradientGold};
  box-sizing: border-box;
  border: none;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  @media ${device.mobileL} {
    display: none;
  }
`;

const StyledPercents = styled.span`
  font-family: Poppins;
  font-size: 26px;
  font-weight: 800;
  line-height: 39px;
  letter-spacing: 0px;
  text-align: left;
  outline: none;
`;

const StyledGoldenLine = styled.div<{ color: string }>`
  height: 7.8px;
  background: ${({ color }) => color};
  border-radius: 10px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  @media ${device.mobileL} {
    border-radius: 0px;
    margin: 0 3px;
  }
`;

const StyledDoneImg = styled(IconImage)`
  width: 30px;
  height: 30px;
  @media ${device.mobileL} {
    grid-area: b;
    justify-self: end;
  }
`;

const StyledCircle = styled.div<{ color: string }>`
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px solid ${({ color }) => color};
  @media ${device.mobileL} {
    grid-area: b;
    border-color: #8792a2;
    justify-self: end;
  }
`;

const StyledTitleContainer = styled.div<{ index: number; stepsVisible: boolean }>`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 7.8px;
  @media ${device.mobileL} {
    display: ${({ stepsVisible }) => (stepsVisible ? 'flex' : 'none')};
    position: absolute;
    z-index: 3;
    bottom: ${({ index }) => (index === 1 ? '0px' : `${(index - 1) * 89 + 12}px`)};
    left: 0px;
    width: 100%;
    background: #edf7ff;
  }
`;

const StyledInnerContainer = styled.div<{ index: number; lastIndex: number }>`
  display: flex;
  align-items: center;
  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-template-areas:
      'a b'
      'c .';
    height: 82px;
    width: 100%;
    background: #ffffff;
    box-shadow: 1px 1px 1px rgba(193, 203, 208, 0.7), -1px -1px 2px rgba(240, 240, 240, 0.71);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px 16px;
    margin: ${({ index, lastIndex }) =>
      index === 0 ? '16px 16px 4px' : index === lastIndex ? '4px 16px 16px' : '4px 16px'};
  }
`;

const StyledTitle = styled.div<{ color: string }>`
  padding: 0 14px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 39px;
  letter-spacing: 0px;
  outline: none;
  position: relative;
  background: ${({ color }) => color};
  outline: none;
  box-sizing: border-box;
  border: none;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  @media ${device.laptop} {
    font-size: 20px;
  }
  @media ${device.laptopM} {
    font-size: 18px;
  }
  @media ${device.tablet} {
    font-size: 16px;
  }
  @media ${device.mobileL} {
    padding: 0;
    grid-area: a;
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    background: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }
`;
