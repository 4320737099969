import React from 'react';
import { FreeMintSimpleModal } from './FreeMintSimpleModal';
import { useTranslation } from 'react-i18next';

interface IProps {
  onClose?: () => void;
}

export const FreeMintSuccessfulMintModal = ({ onClose }: IProps) => {
  const { t } = useTranslation('views/freeMint');

  return (
    <FreeMintSimpleModal
      icon='goldenCheckMark'
      title={t('modal.successfulMint.title')}
      text={t('modal.successfulMint.text')}
      onClose={onClose}
    />
  );
};
