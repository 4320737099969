import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import { GoldenButton, Loader, SecondaryButton } from 'components';
import { ALL_NFTS_COUNT, REQUEST_TIMEOUT, AdminRoutesEnum, ErrorKeys, ErrorReasons } from 'constants/index';
import { MarketplaceContract, NftContract } from 'blockchain';
import { useAdminAuthRequire, useFetch, useModal } from 'hooks';
import { device } from 'resources/theme';
import { errorStore, marketplaceStore, walletStore } from 'store';
import { MintModalView } from './MintModalView';

export const AdminNFTBannerView = observer(() => {
  useAdminAuthRequire();
  const { t } = useTranslation('views/admin');
  const navigation = useNavigate();
  const [NFTLeft, setNFTLeft] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(!NFTLeft);
  const { isOpen, open, close } = useModal();
  const NFTRemain = ALL_NFTS_COUNT - Number(NFTLeft);

  const {
    response: isFrozen,
    loading: freezeLoading,
    retry: getFreeze,
  } = useFetch(() => MarketplaceContract.getFreeze(), []);

  const { loading } = useFetch(() => marketplaceStore.loadDrawData(), []);

  useEffect(() => {
    if (marketplaceStore.drawData && marketplaceStore.drawData.bronzeCounter >= ALL_NFTS_COUNT) {
      navigation(AdminRoutesEnum.adminWinners);
    }
  }, [marketplaceStore.drawData]);

  const getNftLeft = async (retry: number, isFirstLoad: boolean) => {
    try {
      setNFTLeft(await NftContract.getTotalSupply());
      isFirstLoad && setIsLoading(false);
    } catch (error) {
      if (retry <= 0) {
        await getNftLeft(retry - 1, isFirstLoad);
      } else {
        setNFTLeft(0);
        isFirstLoad && setIsLoading(false);
      }
    }
  };

  const setFreeze = async (isFrozen: boolean) => {
    if (!walletStore.web3Provider) {
      return;
    }

    setIsLoading(true);
    try {
      const contract = new MarketplaceContract(walletStore.web3Provider);
      const tx = await contract.setFreeze(isFrozen);
      await tx.wait();
      getFreeze();
      setIsLoading(false);
    } catch (err) {
      errorStore.setError({ ...err, key: ErrorKeys.FreezeError, reason: ErrorReasons.Unknown });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNftLeft(3, true);
    const interval = setInterval(() => {
      getNftLeft(1, false);
    }, REQUEST_TIMEOUT);
    return () => clearInterval(interval);
  }, []);

  const renderActionButton = () => {
    if (!NFTRemain) {
      return (
        <StyledGoldenButton onClick={() => navigation(AdminRoutesEnum.adminDraw)}>
          {t('buttons.start')}
        </StyledGoldenButton>
      );
    }

    if (isFrozen === null) {
      return null;
    }

    if (isFrozen) {
      return (
        <StyledSecondaryButton onClick={() => setFreeze(false)}>
          <StyledReStartIcon /> {t('buttons.restart')}
        </StyledSecondaryButton>
      );
    }

    return (
      <StyledSecondaryButton onClick={() => setFreeze(true)}>
        <StyledFreezeIcon /> {t('buttons.freeze')}
      </StyledSecondaryButton>
    );
  };

  return (
    <StyledContainer>
      <Loader isLoading={isLoading || freezeLoading || loading} />;
      <StyledCardContainer>
        <StyledCardWrapper>
          <StyledCardTextContainer>
            <StyledCardHeader>{t('minted')}</StyledCardHeader>
            <StyledMintedNumber>{NFTLeft}</StyledMintedNumber>
          </StyledCardTextContainer>
        </StyledCardWrapper>
        <StyledCardWrapper>
          <StyledCardTextContainer>
            <StyledCardHeader>{t('remain')}</StyledCardHeader>
            <StyledMintedNumber>{NFTRemain}</StyledMintedNumber>
          </StyledCardTextContainer>
        </StyledCardWrapper>
      </StyledCardContainer>
      {!!NFTRemain && <StyledGoldenButton onClick={() => open()}>{t('buttons.mint')}</StyledGoldenButton>}
      {renderActionButton()}
      <MintModalView NFTRemain={NFTRemain} isOpen={isOpen} close={close} onMint={() => getNftLeft(1, false)} />
    </StyledContainer>
  );
});

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  text-align: center;
`;

const StyledCardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 134px;
  width: 100%;
  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
    margin-bottom: 36px;
  }
`;

const StyledCardWrapper = styled.div`
  position: relative;
  max-width: 624px;
  width: 100%;
  margin: 0 20px;
  background: transparent;
  background-clip: padding-box;
  border-width: 3.96px;
  box-sizing: border-box;
  :before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    border-radius: 23.76px;
    padding: 3.96px;
    background: linear-gradient(
      122.47deg,
      #ffc149 2.47%,
      #fffea1 11.82%,
      rgba(255, 254, 161, 0) 27.72%,
      rgba(255, 254, 161, 0) 74.66%,
      #b48900 104.8%
    );
    filter: drop-shadow(0px 3.16751px 15.8376px rgba(0, 0, 0, 0.4));
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  @media ${device.tablet} {
    margin-bottom: 36px;
    max-width: 436px;
  }
`;

const StyledCardTextContainer = styled.div`
  padding: 111px 116px 97px;
  @media ${device.tablet} {
    padding: 31px 0;
  }
`;

const StyledCardHeader = styled.div`
  background: ${({ theme }) => theme.linearGradientGold};
  outline: none;
  font-family: 'Ginora Sans';
  font-weight: 500;
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  box-sizing: border-box;
  border: none;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  text-transform: uppercase;

  @media ${device.tablet} {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizeL};
  }
`;

const StyledMintedNumber = styled(StyledCardHeader)`
  padding-top: 13px;
  font-size: 96px;

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.pageTitlesFontSize};
  }
`;

const StyledGoldenButton = styled(GoldenButton)`
  min-width: 220px;
  text-transform: capitalize;
  padding: 21px 53px;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  margin-top: 30px;
`;

const StyledFreezeIcon = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  margin-bottom: -3px;
  background-color: #eb5757;
`;

const StyledReStartIcon = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  background-color: #57eb5d;
  margin-bottom: -3px;
  border-radius: 50%;
`;
