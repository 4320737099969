import { ReactNode } from 'react';
import styled from 'styled-components';

import bg from 'resources/images/notAuthorisedViewImg.png';
import chBg from 'resources/images/cryptoHunters/access-nft.png';
import { device } from 'resources/theme/media';

interface IProps {
  children: ReactNode;
  isCH?: boolean;
}

export const AccsesNFTCard = ({ children, isCH, ...props }: IProps) => {
  return (
    <StyledCard className='AccessNFTCard' {...props} isCH={!!isCH}>
      <div>
        <StyledText>{children}</StyledText>
      </div>
    </StyledCard>
  );
};

const StyledCard = styled.div<{ isCH: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 472px;
  height: 465px;
  border-style: solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: ${({ theme }) => theme.linearGradientGold};
  background-position: center 25.5px;
  box-sizing: border-box;
  background-image: ${({ isCH }) => (isCH ? `url(${chBg})` : `url(${bg})`)};
  background-size: 419.03px 419.97px;
  background-repeat: no-repeat;
  background-origin: border-box;
  @media ${device.mobileL} {
    width: 295px;
    height: 290px;
    background-position: center 14px;
    background-size: 261.89px 262.48px;
  }
  @media ${device.laptop} {
    width: 268px;
    height: 264px;
    background-position: center 13px;
    background-size: 236px 238px;
  }
`;

const StyledText = styled.span`
  font-family: 'Ginora Sans' !important;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  line-height: 120%;
  text-transform: uppercase;
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media ${device.mobileL} {
    font-size: ${({ theme }) => theme.fontSizeXL};
    line-height: 120%;
  }
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSizeXL};
    line-height: 120%;
  }
`;
