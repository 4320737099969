import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { GoldenButton, IconImage, NavMenu, SocialLinks } from 'components';
import { headerMenuItems, footerRightSideMenuItems, profileMenuItemsMobile, socialLinksItems } from 'constants/menu';
import { authStore, freeMintStore } from 'store';
import { RoutesEnum } from 'constants/routes';
import { FreeMintModalKeys } from '../../constants';

interface IProps {
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FreeMintMobileMenu = observer(({ setOpenMenu }: IProps) => {
  const { t } = useTranslation();
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      {!openProfileMenu ? (
        <>
          {' '}
          <StyledNavMenu>
            <NavMenu items={headerMenuItems} rotation='column' setOpenMenu={setOpenMenu} />
          </StyledNavMenu>
          <StyledProfileItem
            onClick={() => {
              authStore.isAuthorized ? setOpenProfileMenu(!openProfileMenu) : navigate(RoutesEnum.signIn);
              !authStore.isAuthorized ? setOpenMenu(false) : {};
            }}
          >
            <IconImage icon='yellowProfile' />
            <span>{t('header.profile')}</span>
            <StyledArrow icon='arrow' />
          </StyledProfileItem>
          <StyledWalletBtn>
            {!authStore.isAuthorized && (
              <GoldenButton
                isFreeMint={true}
                onClick={() => {
                  if (authStore.isAuthorized) {
                    freeMintStore.setModal(FreeMintModalKeys.NftOrder);
                  } else {
                    freeMintStore.setModal(FreeMintModalKeys.NotLoggedIn);
                  }
                  setOpenMenu(false);
                }}
              >
                {t('freeMint.mintNow')}
              </GoldenButton>
            )}
          </StyledWalletBtn>
          <SocialLinks isHeader={true} items={socialLinksItems} />
          <StyledFooterNavMenu>
            <NavMenu items={footerRightSideMenuItems} rotation='column' rightSide={true} setOpenMenu={setOpenMenu} />
          </StyledFooterNavMenu>
        </>
      ) : (
        <>
          <StyledGoBackItem
            onClick={() => {
              setOpenProfileMenu(!openProfileMenu);
            }}
          >
            <StyledBackArrowIcon icon='arrow' />
            <IconImage icon='yellowProfile' />
            <span>{t('header.profile')}</span>
          </StyledGoBackItem>
          <StyledProfileMenu>
            <NavMenu
              items={profileMenuItemsMobile}
              rotation='column'
              setOpenMenu={setOpenMenu}
              setOpenProfileMenu={setOpenProfileMenu}
            />
          </StyledProfileMenu>
        </>
      )}
    </>
  );
});

const StyledNavMenu = styled.div`
  li {
    font-size: ${({ theme }) => theme.fontSizeL};
  }
`;

const StyledProfileItem = styled.div`
  display: flex;
  padding-left: 34px;
  padding-right: 24px;
  span {
    padding-left: 16px;
    font-family: 'Ginora Sans';
    font-style: normal;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizeL};
    line-height: 23px;
    color: ${({ theme }) => theme.textColorGold};
  }
`;

const StyledArrow = styled(IconImage)`
  position: fixed;
  right: 18px;
`;

const StyledWalletBtn = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  button {
    background: ${({ theme }) => theme.linearGradientGold};
    span {
      color: ${({ theme }) => theme.bgColor};
      background: transparent;
      background-clip: unset;
      -webkit-background-clip: unset;
      -moz-background-clip: unset;
      text-fill-color: ${({ theme }) => theme.bgColor};
      -webkit-text-fill-color: ${({ theme }) => theme.bgColor};
      -moz-text-fill-color: ${({ theme }) => theme.bgColor};
    }
  }
`;

const StyledFooterNavMenu = styled.div`
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 0px;
    li {
      color: ${({ theme }) => theme.textColorGold};
    }
    li:nth-child(odd) {
      justify-self: start;
    }
    li:nth-child(even) {
      justify-self: end;
    }
  }
`;

const StyledGoBackItem = styled.div`
  img {
    filter: brightness(0) invert(100%);
    margin-right: 16px;
  }
  background: #828282;
  height: 63px;
  display: flex;
  align-items: center;
  padding-left: 35px;
  span {
    color: #fff;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizeL};
    line-height: 23px;
    font-family: 'Ginora Sans', serif !important;
    font-weight: 400;
  }
`;

const StyledBackArrowIcon = styled(IconImage)`
  transform: rotate(180deg);
`;

const StyledProfileMenu = styled.div`
  li {
    font-size: ${({ theme }) => theme.fontSizeL};
  }
  img {
    filter: brightness(0) invert(100%);
  }
  li {
    &:hover img,
    &:active img {
      filter: unset;
    }
  }
  li,
  a {
    &:hover,
    &:active {
      color: ${({ theme }) => theme.textColorGold};
      background: transparent;
      background-clip: unset;
      text-fill-color: ${({ theme }) => theme.textColorGold};
      -webkit-background-clip: unset;
      -webkit-text-fill-color: ${({ theme }) => theme.textColorGold};
      -moz-background-clip: unset;
      -moz-text-fill-color: ${({ theme }) => theme.textColorGold};
    }
  }
`;
