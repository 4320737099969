import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { ErrorKeys, ErrorReasons } from 'constants/index';
import { errorStore } from 'store';
import { IconImage } from 'components';

interface IProps {
  keys: ErrorKeys[];
}

export const ErrorMessageView = observer(({ keys = [], ...props }: IProps) => {
  const { t } = useTranslation();
  const error = useMemo(() => {
    const key = keys.find((key) => !!errorStore.errors[key]);
    return key ? errorStore.errors[key] : null;
  }, [errorStore.errors]);

  if (!error) {
    return <></>;
  }

  return (
    <StyledErrorMessage {...props}>
      <StyledIcon icon='errorCross' />
      <StyledErrorText>{t(`error.${error.reason}`, `error.${ErrorReasons.Unknown}`)}</StyledErrorText>
    </StyledErrorMessage>
  );
});

const StyledErrorMessage = styled.div`
  color: ${({ theme }) => theme.errorColor};
  font-size: ${({ theme }) => theme.errorFontSize};
`;

const StyledErrorText = styled.span`
  display: inline-block;
  margin-left: 12px;
`;

const StyledIcon = styled(IconImage)`
  width: 10px;
  height: 10px;
`;
