import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconImage } from 'components/icons';

interface IProps {
  textToCopy: string;
}

export const CopyButton = ({ textToCopy, ...props }: IProps) => {
  const [isCopied, setCopied] = useState(false);
  const copy = async () => {
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);
  };
  const resetCopyState = () => setCopied(false);

  useEffect(() => {
    isCopied
      ? setTimeout(() => {
          resetCopyState();
        }, 2000)
      : {};
  }, [isCopied]);

  return (
    <StyledContainer onClick={copy} onMouseEnter={resetCopyState} {...props}>
      {!isCopied ? <IconImage icon='copy' /> : <IconImage icon='doneMark' />}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: fit-content;
  height: fit-content;
  display: inline;
  cursor: pointer;
  position: relative;
`;
