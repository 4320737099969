import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { CryptoHuntersRoutesEnum, FreeMintRoutesEnum, RoutesEnum } from 'constants/index';
import { authStore } from 'store';

interface IHookOption {
  isRedirect?: boolean;
  isAuthRequired?: boolean;
  redirectRoute?: RoutesEnum | FreeMintRoutesEnum | CryptoHuntersRoutesEnum;
}

export const useAuthRequire = ({
  isAuthRequired = true,
  isRedirect = true,
  redirectRoute = RoutesEnum.home,
}: IHookOption = {}): void => {
  const isAuthorized = authStore.isAuthorized;
  const navigate = useNavigate();

  useEffect(() => {
    if ((!isAuthorized && isAuthRequired) || (isAuthorized && !isAuthRequired)) {
      navigate(redirectRoute);
    }
  }, [history, isAuthorized, isRedirect, redirectRoute]);
};
