import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import 'resources/fonts/index.css';
import 'i18n';

import { mainTheme, GlobalStyle } from 'resources/theme';
import { Loader } from 'components';
import App from './App';

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={mainTheme}>
        <Suspense fallback={<Loader isLoading={true} />}>
          <App />
        </Suspense>
        <GlobalStyle />
      </ThemeProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root'),
);
