import { MarketplaceContract } from 'blockchain';
import { makeAutoObservable } from 'mobx';

import { ErrorKeys, ErrorReasons, LocalStorageKeys, WalletTypes } from 'constants/index';
import { getFromLS, removeFromLS, setToLS } from 'utils';
import { getSignatureCode, refreshAccessToken, signIn } from 'api';

import { walletStore } from './wallet';
import { errorStore } from './error';

import { ROLE_HASH } from 'constants/index';

export class AuthStore {
  accountAddress: string = getFromLS(LocalStorageKeys.AuthorizedAddress, '');
  accessToken: string = getFromLS(LocalStorageKeys.AccessToken, '');
  refreshToken: string = getFromLS(LocalStorageKeys.RefreshToken, '');
  isAdmin: string = getFromLS(LocalStorageKeys.IsAdmin, '');

  constructor() {
    makeAutoObservable(this);
  }

  get isAuthorized(): boolean {
    return !!this.accessToken && walletStore.connectedAccount === this.accountAddress;
  }

  async isAdminCheck() {
    try {
      const hasAdminRole = await MarketplaceContract.isAdmin(ROLE_HASH, authStore.accountAddress);
      if (hasAdminRole) {
        this.isAdmin = hasAdminRole.toString();
        setToLS(LocalStorageKeys.IsAdmin, hasAdminRole);
      }
      return hasAdminRole;
    } catch (e) {
      return false;
    }
  }

  async signIn(walletType: WalletTypes) {
    switch (walletType) {
      case WalletTypes.Metamask:
        await walletStore.connectToMetamask();
        break;
      case WalletTypes.WalletConnect:
        await walletStore.connectToWalletConnect();
        break;
    }

    if (!this.isAuthorized) {
      await this.signInWithSignature();
    }
  }

  async refreshAccessToken() {
    const { accessToken, refreshToken } = await refreshAccessToken({
      accessToken: this.accessToken,
      refreshToken: this.refreshToken,
    });
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    setToLS(LocalStorageKeys.AccessToken, accessToken);
    setToLS(LocalStorageKeys.RefreshToken, refreshToken);
  }

  signOut() {
    this.accountAddress = '';
    this.accessToken = '';
    this.refreshToken = '';
    this.isAdmin = '';
    removeFromLS(LocalStorageKeys.AuthorizedAddress);
    removeFromLS(LocalStorageKeys.AccessToken);
    removeFromLS(LocalStorageKeys.RefreshToken);
    removeFromLS(LocalStorageKeys.IsAdmin);
  }

  private async signInWithSignature() {
    try {
      const accountAddress = walletStore.connectedAccount;
      const { code, message } = await getSignatureCode({ accountAddress });
      const signature = (await walletStore.walletProvider?.signMessage(accountAddress, `${message}: ${code}`)) || '';
      const { accessToken, refreshToken } = await signIn({ accountAddress, signature });
      this.accountAddress = accountAddress;
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
      setToLS(LocalStorageKeys.AuthorizedAddress, accountAddress);
      setToLS(LocalStorageKeys.AccessToken, accessToken);
      setToLS(LocalStorageKeys.RefreshToken, refreshToken);
      setToLS(LocalStorageKeys.IsAdmin, '');
      errorStore.removeError(ErrorKeys.SignInError);
    } catch (err) {
      if (err.message === 'Address verification failed') {
        errorStore.setError({ ...err, key: ErrorKeys.SignInError, reason: ErrorReasons.AmlFailed });
      } else {
        errorStore.setError({ ...err, key: ErrorKeys.SignInError, reason: ErrorReasons.SignInFailed });
      }
    }
  }
}

export const authStore = new AuthStore();
