export enum RoutesEnum {
  home = '/',
  signIn = '/sign-in',
  mint = '/mint',
  presale = '/presale',
  profile = '/profile/*',
  profileNFT = `/profile/my-collection`,
  profileDetails = `/profile/profile-details`,
  profileNFTRelative = 'my-collection',
  profileDetailsRelative = 'profile-details',
  error = '/*',
}

export enum FreeMintRoutesEnum {
  freeMint = '/free-mint',
}

export enum CryptoHuntersRoutesEnum {
  checkWhitelist = '/whitelist',
  cryptoHuntersMint = '/crypto-hunters',
}

export enum AdminRoutesEnum {
  admin = '/admin',
  adminBanner = '/admin/banner',
  adminDraw = '/admin/draw',
  adminWinners = '/admin/winners',
}
