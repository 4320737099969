import styled from 'styled-components';
import { device } from 'resources/theme';
import { IconImage, Logo, NavMenu, SocialLinks } from 'components';
import { footerMenuItems, footerRightSideMenuItems, socialLinksItems } from 'constants/index';

export const FooterView = () => {
  return (
    <StyledContainer>
      <StyledSeparatingLine icon='separatingLine' />
      <StyledNavMenu>
        <NavMenu items={footerMenuItems} rotation='column' />
      </StyledNavMenu>
      <StyledFlexWrapper>
        <Logo footer={true} />
      </StyledFlexWrapper>
      <StyledRightSideFooterContainer>
        <SocialLinks items={socialLinksItems} />
        <StyledFooterNavMenu>
          <NavMenu items={footerRightSideMenuItems} rotation='column' rightSide={true} />
        </StyledFooterNavMenu>
      </StyledRightSideFooterContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  box-sizing: border-box;
  background: transparent;
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;
  height: fit-content;
  width: 100%;
  max-width: 1520px;
  left: 0;
  bottom: 0;
  padding: 60px 1.5rem 56px;
  margin: auto;
  @media ${device.mobileL} {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 60px 0 25px 0;
  }
  // &:before {
  //   content: 'v0.7.0';
  //   position: absolute;
  //   width: 100%;
  //   bottom: 52px;
  //   color: ${({ theme }) => theme.copyrightTextColor};
  //   text-align: center;
  //   font-weight: 300;
  //   @media ${device.mobileL} {
  //     font-size: ${({ theme }) => theme.fontSizeXxs};
  //     line-height: 150%;
  //     position: relative;
  //     padding-top: 40px;
  //     order: 4;
  //   }
  //   font-size: ${({ theme }) => theme.fontSizeM};
  // }
  &:after {
    content: '©2022 Seaside club. All rights reserved';
    position: absolute;
    width: 100%;
    bottom: 28px;
    color: ${({ theme }) => theme.copyrightTextColor};
    text-align: center;
    font-weight: 300;
    @media ${device.mobileL} {
      font-size: ${({ theme }) => theme.fontSizeXxs};
      line-height: 150%;
      position: relative;
      padding-top: 24px;
    }
    font-size: ${({ theme }) => theme.fontSizeM};
  }
`;

const StyledSeparatingLine = styled(IconImage)`
  width: 100%;
  position: absolute;
  top: -5px;
`;

const StyledNavMenu = styled.div`
  flex-basis: 15%;
  @media ${device.mobileL} {
    display: none !important;
  }
`;

const StyledFlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`;

const StyledRightSideFooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-basis: 15%;
  flex-direction: column;
  justify-content: space-between;
  @media ${device.mobileL} {
    padding-right: 0px;
  }
`;

const StyledFooterNavMenu = styled.div`
  @media ${device.mobileL} {
    ul {
      display: grid !important;
      grid-template-columns: 1fr 1fr !important;
      gap: 16px 0px !important;
      li {
        font-size: ${({ theme }) => theme.fontSizeXs};
      }
      li:nth-child(odd) {
        justify-self: start !important;
      }
      li:nth-child(even) {
        justify-self: end !important;
      }
    }
  }
`;
