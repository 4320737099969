import { makeAutoObservable } from 'mobx';

export class FreeMintStore {
  modalKey = '';

  constructor() {
    makeAutoObservable(this);
  }

  closeModal() {
    this.modalKey = '';
  }

  setModal(modalKey: string) {
    this.modalKey = modalKey;
  }
}

export const freeMintStore = new FreeMintStore();
