import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import logoSvg from 'resources/images/logoSeaside.svg';
import logoMobileSvg from 'resources/images/logoSeasideMobile.svg';
import { device } from 'resources/theme';

interface IProps {
  footer?: boolean;
}
export const Logo = ({ footer = false }: IProps) => {
  const { t } = useTranslation();
  return (
    <StyledContainer footer={footer}>
      <LogoImage src={logoSvg} footer={footer} />
      <LogoImageMobile src={logoMobileSvg} footer={footer} />
      <LogoText footer={footer}>{t('logoText')}</LogoText>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ footer: boolean }>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: ${({ footer }) => footer && 'column'};
  gap: ${({ footer }) => (footer ? '25px' : '10px')};
  width: fit-content;
  height: ${({ footer }) => (footer ? '200px' : '48px')};
  @media ${device.mobileL} {
    gap: ${({ footer }) => (footer ? '13px' : '10px')};
    width: ${({ footer }) => (footer ? '245px' : '112px')};
    height: ${({ footer }) => (footer ? '122px' : '48px')};
  }
`;

const LogoImage = styled.img<{ footer: boolean }>`
  width: ${({ footer }) => (footer ? '61px' : '21px')};
  height: ${({ footer }) => (footer ? '148px' : '48px')};
  @media ${device.mobileL} {
    display: none;
    width: ${({ footer }) => (footer ? '39px' : '21px')};
    height: ${({ footer }) => (footer ? '91px' : '48px')};
  }
`;

const LogoImageMobile = styled.img<{ footer: boolean }>`
  display: none;
  @media ${device.mobileL} {
    display: block;
    width: ${({ footer }) => (footer ? '39px' : '21px')};
    height: ${({ footer }) => (footer ? '91px' : '48px')};
  }
`;

const LogoText = styled.div<{ footer: boolean }>`
  font-family: 'Trajan Pro Regular', serif !important;
  font-size: ${({ theme, footer }) => (footer ? theme.fontSizeM : theme.fontSizeS)};
  line-height: 16px;
  text-transform: uppercase;
  width: ${({ footer }) => (footer ? '100%' : '70px')};
  height: 28px;
  color: ${({ theme }) => theme.mainLogoColor};
  text-align: ${({ footer }) => (footer ? 'center' : 'left')};
  @media ${device.mobileL} {
    font-size: ${({ footer }) => (footer ? '16px' : '10px')};
    line-height: ${({ footer }) => (footer ? '19px' : '12px')};
    height: 22px;
  }
`;

// const LogoText = styled.div<{ footer: boolean }>`
// font-family: 'Trajan Pro Regular', serif !important;
// font-size: ${({ theme, footer }) => (footer ? theme.fontSizeM : theme.fontSizeS)};
// line-height: 16px;
// text-transform: uppercase;
// width: ${({ footer }) => (footer ? '100%' : '70px')};
// height: 28px;
// color: ${({ theme }) => theme.mainLogoColor};
// text-align: ${({ footer }) => (footer ? 'center' : 'left')};
// @media ${device.mobileL} {
//   font-size: ${({ theme, footer }) => (footer ? theme.fontSizeM : theme.fontSizeXxs)};
//   line-height: ${({ footer }) => (footer ? '18px' : '11px')};
//   height: 22px;
// }
// `;

// const FreeMintLogoText = styled.div<{ footer: boolean }>`
//   font-family: 'Trajan Pro Regular', serif !important;
//   font-size: ${({ theme, footer }) => (footer ? theme.fontSizeM : theme.fontSizeS)};
//   line-height: 16px;
//   text-transform: uppercase;
//   width: ${({ footer }) => (footer ? '100%' : '70px')};
//   height: 28px;
//   color: ${({ theme }) => theme.mainLogoColor};
//   text-align: ${({ footer }) => (footer ? 'center' : 'left')};
//   @media ${device.mobileL} {
//     font-size: ${({ footer }) => (footer ? '16px' : '10px')};
//     line-height: ${({ footer }) => (footer ? '19px' : '12px')};
//     height: 22px;
//   }
// `;
