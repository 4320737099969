import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { freeMintStore } from 'store';
import {
  FreeMintNftOrderModal,
  FreeMintNotLoggedInModal,
  FreeMintSuccessfulMintModal,
  FreeMintUnsuccessfulMintModal,
} from './modal';
import { FreeMintModalKeys } from '../../constants';
import { device } from 'resources/theme';

export const FreeMintModalView = observer(() => {
  const closeModal = () => {
    freeMintStore.closeModal();
  };

  return (
    <StyledContainer>
      {freeMintStore.modalKey === FreeMintModalKeys.NftOrder && <FreeMintNftOrderModal onClose={closeModal} />}
      {freeMintStore.modalKey === FreeMintModalKeys.NotLoggedIn && <FreeMintNotLoggedInModal onClose={closeModal} />}
      {freeMintStore.modalKey === FreeMintModalKeys.SuccessfulMint && (
        <FreeMintSuccessfulMintModal onClose={closeModal} />
      )}
      {freeMintStore.modalKey === FreeMintModalKeys.UnsuccessfulMint && (
        <FreeMintUnsuccessfulMintModal onClose={closeModal} />
      )}
    </StyledContainer>
  );
});

const StyledContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    'a a b b'
    'a a c c'
    'a a d .'
    'a a e e'
    '. . f .';
  box-sizing: border-box;
  padding: 0 100px 100px;
  @media ${device.laptop} {
    padding: 0 85px 100px 0;
  }
  @media ${device.tablet} {
    padding: 0 0 60px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      'a'
      'b'
      'c'
      'd'
      'e'
      'f';
  }
`;
