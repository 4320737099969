import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { marketplaceStore } from 'store';
import { GoldenButton } from 'components';
import { appConfig } from 'config';
import { getTimeLeft } from 'utils';
import { device } from 'resources/theme';

interface IProps {
  isWhitelisted?: boolean;
}

export const NoNftTimerView = observer(({ isWhitelisted = false }: IProps) => {
  const { t } = useTranslation(['views/purchase', 'common']);
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(marketplaceStore.targetDate));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(getTimeLeft(marketplaceStore.targetDate));
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  });

  return (
    <StyledContainer>
      <StyledTitle>
        {marketplaceStore.isPresale
          ? t(isWhitelisted ? 'noNftTimer.titleSaleWhitelisted' : 'noNftTimer.titleSale')
          : t('noNftTimer.titlePresale')}
      </StyledTitle>
      <StyledDescription>
        {marketplaceStore.isPresale ? t('noNftTimer.descriptionSale') : t('noNftTimer.descriptionPresale')}
      </StyledDescription>
      <StyledTimer>
        <StyledTimerUnit>
          <StyledTimerText>
            {timeLeft.days} <StyledTimerLabel>{t('common:units.days')}</StyledTimerLabel>
          </StyledTimerText>
        </StyledTimerUnit>
        <StyledTimerUnit>
          <StyledTimerText>
            {timeLeft.hours} <StyledTimerLabel>{t('common:units.hours')}</StyledTimerLabel>
          </StyledTimerText>
        </StyledTimerUnit>
        <StyledTimerUnit>
          <StyledTimerText>
            {timeLeft.minutes} <StyledTimerLabel>{t('common:units.minutes')}</StyledTimerLabel>
          </StyledTimerText>
        </StyledTimerUnit>
        <StyledTimerUnit>
          <StyledTimerText>
            {timeLeft.seconds} <StyledTimerLabel>{t('common:units.seconds')}</StyledTimerLabel>
          </StyledTimerText>
        </StyledTimerUnit>
      </StyledTimer>
      <StyledButtonWrapper>
        <a href={appConfig.homePageUrl}>
          <GoldenButton>{t('noNftTimer.home')}</GoldenButton>
        </a>
      </StyledButtonWrapper>
    </StyledContainer>
  );
});

const StyledContainer = styled.div`
  margin: 120px auto 200px auto;

  @media ${device.laptopL} {
    margin: -30px auto 60px auto;
  }

  @media ${device.mobileL} {
    margin: 20px auto 60px auto;
  }
`;

const StyledTitle = styled.h2`
  font-weight: 400;
  margin: 0 auto;
  font-family: 'Ginora Sans', serif !important;
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  letter-spacing: 1px;
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
  text-align: center;
  max-width: 700px;

  @media ${device.laptopL} {
    max-width: unset;
    font-size: ${({ theme }) => theme.headlineSmallerSize};
  }

  @media ${device.mobileL} {
    max-width: 340px;
    font-size: ${({ theme }) => theme.fontSizeXL};
    flex-direction: column-reverse;
  }
`;

const StyledDescription = styled.p`
  color: ${({ theme }) => theme.lightTextColor};
  font-size: ${({ theme }) => theme.fontSizeM};
  line-height: 24px;
  font-weight: 300;
  text-align: center;
  margin: 16px auto;
  max-width: 840px;
  @media ${device.laptopL} {
    font-size: ${({ theme }) => theme.fontSizeS};
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;
`;

const StyledTimer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  max-width: 860px;
  text-align: center;
`;

const StyledTimerUnit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  margin: 20px;
  border: 6px solid ${({ theme }) => theme.mainLabelColor};
  border-radius: 50%;

  @media ${device.laptopL} {
    width: 100px;
    height: 100px;
  }

  @media ${device.mobileL} {
    width: 62px;
    height: 62px;
    margin: 8px;
    border: 3px solid ${({ theme }) => theme.mainLabelColor};
  }
`;

const StyledTimerText = styled.div`
  font-family: 'Ginora Sans', serif !important;
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  font-weight: 300;
  color: ${({ theme }) => theme.lightTextColor};
  line-height: 16px;
  text-transform: uppercase;

  @media ${device.mobileL} {
    font-size: ${({ theme }) => theme.fontSizeXL};
  }
`;

const StyledTimerLabel = styled.div`
  font-family: 'Ginora Sans', serif !important;
  font-size: ${({ theme }) => theme.fontSizeXs};
  margin-top: 8px;

  @media ${device.mobileL} {
    margin-top: 4px;
    font-size: ${({ theme }) => theme.fontSizeXxxs};
  }
`;
