export enum Environment {
  Production = 'production',
  Development = 'development',
  Staging = 'staging',
}
export const REQUEST_TIMEOUT = 10000;

export const PRE1658696400000OKENS_AVAILABLE = 2;
export const 1658696400000OKENS_AVAILABLE = 3;
export const ALL_NFTS_COUNT = 10010;
export const NFT_PRICE = 0.00002;
export const DEFAULT_VRS = {
  v: 0,
  r: '0x0000000000000000000000000000000000000000000000000000000000000000',
  s: '0x0000000000000000000000000000000000000000000000000000000000000000',
};
export const ROLE_HASH = '0xf4c08ce52610a799971e5eff0eeca341a482961bcfb939a59f17c33bbaafb446';

export const PAGE_LIMIT = 9;

export const RANDOM_NUMBER_GAS_LIMIT = 2500000;

export const BRONZE_DRAW_GAS_LIMIT = 100000;

export const RANDOM_NUMBER_AMOUNT = 50;
