import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { GoldenButton, IconImage, Loader } from 'components';
import styled from 'styled-components';
import { copyToClipboard } from 'utils';
import { device } from 'resources/theme';
import { WhitelistSuccessfulModal } from './modal/WhitelistSuccessfulModal';
import { WhitelistUnsuccessfulModal } from './modal/WhitelistUnsuccessfulModal';
import { authStore, walletStore } from '../../../store';
import { getChWhitelistSignature } from '../../../api';

export const CheckWhitelistView = observer(() => {
  const { t } = useTranslation('views/cryptoHunters');
  const [showWhitelistSuccessfulModal, setShowWhitelistSuccessfulModal] = useState<boolean>(false);
  const [showWhitelistUnsuccessfulModal, setShowWhitelistUnsuccessfulModal] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (authStore.isAuthorized && walletStore.connectedAccount) {
      setAddress(walletStore.connectedAccount);
    }
  }, [authStore.isAuthorized, walletStore.connectedAccount]);

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.match('^[a-zA-Z0-9]*$') !== null) {
      setAddress(value);
    }
  };

  const handleInputKeypress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleWhitelistCheck();
    }
  };

  const handleWhitelistCheck = async () => {
    if (address.trim() === '') {
      setError(t('checkWhitelist.enterWalletAddress'));
      return;
    }
    if (address.trim().length != 42) {
      setError(t('checkWhitelist.invalidAddress'));
      return;
    }

    try {
      setError('');
      setIsLoading(true);
      const response = await getChWhitelistSignature({
        address: address.trim().toLowerCase(),
      });
      const isWhitelisted = response.signature !== '';
      if (isWhitelisted) {
        setShowWhitelistSuccessfulModal(true);
      } else {
        setShowWhitelistUnsuccessfulModal(true);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setError(t('checkWhitelist.whitelistError'));
    }
  };

  return (
    <div>
      {showWhitelistSuccessfulModal && (
        <WhitelistSuccessfulModal
          onClose={() => {
            setShowWhitelistSuccessfulModal(false);
          }}
        />
      )}
      {showWhitelistUnsuccessfulModal && (
        <WhitelistUnsuccessfulModal
          onClose={() => {
            setShowWhitelistUnsuccessfulModal(false);
          }}
        />
      )}
      <StyledContainer>
        <StyledTitle>{t('checkWhitelist.title')}</StyledTitle>
        <StyledDescription>{t('checkWhitelist.description')}</StyledDescription>
        <StyledInputBox>
          <StyledInput
            type='text'
            placeholder={t('checkWhitelist.walletAddress')}
            isError={!!error}
            value={address}
            onChange={handleAddressChange}
            onKeyPress={handleInputKeypress}
          />
          {isCopied ? (
            <StyledDoneIcon icon='doneMark'></StyledDoneIcon>
          ) : (
            <StyledCopyIcon
              icon='copyToClipboard'
              onClick={() => {
                copyToClipboard(address || '');
                setIsCopied(true);
                setTimeout(() => {
                  setIsCopied(false);
                }, 2000);
              }}
            />
          )}
        </StyledInputBox>
        {error ? <StyledError>{error}</StyledError> : null}
        <StyledButtonWrapper>
          <GoldenButton onClick={handleWhitelistCheck}>{t('checkWhitelist.checkWallet')}</GoldenButton>
        </StyledButtonWrapper>
      </StyledContainer>
      <Loader isLoading={isLoading} />
    </div>
  );
});

const StyledContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  @media ${device.mobileL} {
    justify-content: flex-start;
  }
`;

const StyledTitle = styled.p`
  text-transform: uppercase;
  font-family: 'Ginora Sans', serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0 0 16px 0;
  text-align: center;

  @media ${device.mobileL} {
    font-size: 24px;
    line-height: 34px;
    text-align: left;
  }
`;

const StyledDescription = styled.p`
  margin: 0 auto 16px auto;
  max-width: 590px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;

  @media ${device.mobileL} {
    font-size: 14px;
    line-height: 21px;
    text-align: left;
  }
`;

const StyledInputBox = styled.div`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 600px;
  width: 100%;
  @media ${device.laptop} {
    max-width: 100%;
  }
`;

const StyledCopyIcon = styled(IconImage)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-22px, -50%);
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const StyledDoneIcon = styled(StyledCopyIcon)`
  cursor: auto;
`;

const StyledInput = styled.input<{ isError?: boolean }>`
  width: 100%;
  padding: 16px 52px 15px 32px;
  background: #fff3f3;
  border: ${({ isError, theme }) => (isError ? `1px solid ${theme.errorColor}` : 'unset')};
  border-radius: 10px;
  outline: none;
  font-family: 'Poppins', serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeM};
  line-height: 24px;
  box-sizing: border-box;
  @media ${device.laptop} {
    padding: 12px 52px 12px 17px;
    font-size: ${({ theme }) => theme.fontSizeS};
    line-height: 150%;
  }
`;

const StyledError = styled.div`
  font-family: 'Poppins', serif !important;
  font-style: normal;
  font-size: ${({ theme }) => theme.fontSizeM};
  color: ${({ theme }) => theme.errorColor};
  font-weight: 400;
  line-height: 20px;
  margin-top: 10px;
  text-align: center;
`;

const StyledButtonWrapper = styled.div`
  margin: 20px auto 40px auto;
  button {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
  }

  @media ${device.laptop} {
    button {
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
    }
  }
`;
