import WalletConnectProvider from '@walletconnect/web3-provider';
import { providers } from 'ethers';

import { appConfig } from 'config';
import { IWalletProvider } from 'types';

export const getWalletConnectProvider = () =>
  new WalletConnectProvider({
    infuraId: appConfig.infuraId,
  });

export class WcWalletProvider implements IWalletProvider {
  provider: WalletConnectProvider;

  constructor() {
    this.provider = getWalletConnectProvider();
    this.provider.enable();
  }

  async getConnectedAccount(): Promise<string> {
    return this.provider.accounts[0];
  }

  async getChainId(): Promise<number> {
    return this.provider.chainId;
  }

  async signMessage(accountAddress: string, message: string): Promise<string> {
    return this.provider.send('personal_sign', [message, accountAddress.toLowerCase()]);
  }

  getWeb3Provider(): providers.Web3Provider {
    return new providers.Web3Provider(this.provider);
  }
}
