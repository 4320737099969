import { ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

export const GoldenLabel = ({ children, onClick, ...props }: IProps) => {
  return (
    <StyledGoldenLabel onClick={onClick} {...props}>
      {children}
    </StyledGoldenLabel>
  );
};

const StyledGoldenLabel = styled.div`
  box-sizing: border-box;
  background: ${({ theme }) => theme.linearGradientGoldSharpAngle};
  color: ${({ theme }) => theme.bgColor};
  border-radius: 10px;
  padding: 10px 24px;
`;
