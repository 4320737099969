import { ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';

import spinner from 'resources/images/spinner.png';

interface IProps {
  isLoading: boolean;
  message?: string;
  children?: ReactNode;
}

export const Loader = ({ isLoading, message, children = '' }: IProps) => {
  return (
    <>
      {isLoading ? (
        <StyledBackground>
          <div>
            <StyledImage>
              <img src={spinner} alt='loading' />
            </StyledImage>
            {message && <StyledMessage>{message}</StyledMessage>}
          </div>
        </StyledBackground>
      ) : (
        children
      )}
    </>
  );
};

const rotateAnim = keyframes`
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const StyledImage = styled.div`
  img {
    width: 75px;
    animation-name: ${rotateAnim};
    animation-duration: 1.2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
`;

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
`;

const StyledMessage = styled.div`
  width: 300px;
  max-width: 90vw;
  padding: 10px 30px;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.tooltipBgColor};
  font-size: ${({ theme }) => theme.fontSizeS};
  color: ${({ theme }) => theme.lightTextColor};
  box-shadow: 0px 0px 5.4876px rgba(55, 65, 81, 0.06), 0px 2.7438px 8.23141px rgba(55, 65, 81, 0.1);
  border-radius: 6px;
`;
