import { useMemo } from 'react';

export const DOTS = '...';

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

interface IProps {
  totalCount: number;
  pageSize: number;
  siblingCount: number;
  currentPage: number;
}

export const usePagination = ({ totalCount, pageSize, siblingCount = 1, currentPage }: IProps) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    const totalPageNumbers = siblingCount + 1;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(
      currentPage > totalPageCount - siblingCount
        ? currentPage - siblingCount - (currentPage - totalPageCount + siblingCount)
        : currentPage - siblingCount,
      1,
    );
    const rightSiblingIndex = Math.min(
      currentPage <= siblingCount
        ? currentPage + siblingCount + (siblingCount - currentPage + 1)
        : currentPage + siblingCount,
      totalPageCount,
    );

    {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [...middleRange];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};
