import styled from 'styled-components';
import { device } from 'resources/theme';
import { Icons } from 'types/components/icons';
import facebookSvg from 'resources/images/facebook.svg';
import twitterSvg from 'resources/images/twitter.svg';
import discordSvg from 'resources/images/discord.svg';
import instagramSvg from 'resources/images/instagram.svg';
import youtubeSvg from 'resources/images/youtube.svg';
import mediumSvg from 'resources/images/medium.svg';
import facebookInactiveSvg from 'resources/images/facebook-inactive.svg';
import twitterInactiveSvg from 'resources/images/twitter-inactive.svg';
import discordInactiveSvg from 'resources/images/discord-inactive.svg';
import instagramInactiveSvg from 'resources/images/instagram-inactive.svg';
import youtubeInactiveSvg from 'resources/images/youtube-inactive.svg';
import mediumInactiveSvg from 'resources/images/medium-inactive.svg';
import goldProfileSvg from 'resources/images/goldProfile.svg';
import walletSvg from 'resources/images/wallet.svg';
import separatingLineSvg from 'resources/images/sep-line.svg';
import errorSvg from 'resources/images/error.svg';
import copyToClipboardSvg from 'resources/images/copy-to-clipboard.svg';
import verticalLineSvg from 'resources/images/vertical-line.svg';
import metamaskSvg from 'resources/images/metamask.svg';
import walletConnectSvg from 'resources/images/walletconnect.svg';
import errorCrossSvg from 'resources/images/error-cross.svg';
import yellowProfile from 'resources/images/profile.svg';
import arrow from 'resources/images/arrow.svg';
import logout from 'resources/images/logout.svg';
import settings from 'resources/images/settings.svg';
import collection from 'resources/images/collection.svg';
import about from 'resources/images/about.svg';
import copySvg from 'resources/images/copy.svg';
import checkMarkSvg from 'resources/images/checkMark.svg';
import partySvg from 'resources/images/party.svg';
import goldenCheckMarkSvg from 'resources/images/golden-check-mark.svg';
import arrowRight from 'resources/images/arrowRight.svg';
import aboutGold from 'resources/images/aboutGold.svg';
import collectionGold from 'resources/images/collectionGold.svg';
import logoutGold from 'resources/images/logoutGold.svg';
import doneMarkSvg from 'resources/images/doneMark.svg';
import error404Svg from 'resources/images/error-404.svg';
import doneMarkInCircleSvg from 'resources/images/doneMarkInCircle.svg';
import arrowDown from 'resources/images/arrowDown.svg';
import doubleArrowLeft from 'resources/images/double-arrow-left.svg';
import doubleArrowRight from 'resources/images/double-arrow-right.svg';
import goldenXMarkSvg from 'resources/images/golden-x-mark.svg';

interface IProps {
  icon: Icons;
  onClick?: () => void;
}
const icons: Record<Icons, { src: string }> = {
  facebook: { src: facebookSvg },
  instagram: { src: instagramSvg },
  discord: { src: discordSvg },
  twitter: { src: twitterSvg },
  youtube: { src: youtubeSvg },
  medium: { src: mediumSvg },
  goldProfile: { src: goldProfileSvg },
  yellowProfile: { src: yellowProfile },
  wallet: { src: walletSvg },
  facebookInactive: { src: facebookInactiveSvg },
  instagramInactive: { src: instagramInactiveSvg },
  discordInactive: { src: discordInactiveSvg },
  twitterInactive: { src: twitterInactiveSvg },
  youtubeInactive: { src: youtubeInactiveSvg },
  mediumInactive: { src: mediumInactiveSvg },
  separatingLine: { src: separatingLineSvg },
  error: { src: errorSvg },
  copyToClipboard: { src: copyToClipboardSvg },
  verticalLine: { src: verticalLineSvg },
  metamask: { src: metamaskSvg },
  walletConnect: { src: walletConnectSvg },
  errorCross: { src: errorCrossSvg },
  arrow: { src: arrow },
  logout: { src: logout },
  settings: { src: settings },
  collection: { src: collection },
  about: { src: about },
  copy: { src: copySvg },
  checkMark: { src: checkMarkSvg },
  party: { src: partySvg },
  goldenCheckMark: { src: goldenCheckMarkSvg },
  arrowRight: { src: arrowRight },
  aboutGold: { src: aboutGold },
  collectionGold: { src: collectionGold },
  logoutGold: { src: logoutGold },
  doneMark: { src: doneMarkSvg },
  error404: { src: error404Svg },
  doneMarkInCircle: { src: doneMarkInCircleSvg },
  arrowDown: { src: arrowDown },
  doubleArrowLeft: { src: doubleArrowLeft },
  doubleArrowRight: { src: doubleArrowRight },
  goldenXMark: { src: goldenXMarkSvg },
};

export const IconImage = ({ icon, ...props }: IProps) => {
  const iconRecord = icons[icon];
  return <StyledIconImage src={iconRecord.src} {...props} />;
};

const StyledIconImage = styled.img<{ inactive?: boolean }>`
  height: 26px;
  width: 26px;
  @media ${device.laptop} {
    height: 23px;
    width: 23px;
  }
`;
