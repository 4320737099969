import { appConfig } from 'config';
import { IMenuItem, ISocialLinkItem } from 'types';
import { externalLinks } from 'constants/externalLinks';
import { CryptoHuntersRoutesEnum, RoutesEnum } from './routes';

export const headerMenuItems: IMenuItem[] = [
  { name: 'header.links.home', link: appConfig.homePageUrl || '#', isExternal: true },
  { name: 'header.links.whitelist', link: CryptoHuntersRoutesEnum.checkWhitelist, isExternal: false },
  { name: 'header.links.aboutUs', link: `${appConfig.homePageUrl}#about`, isExternal: true },
  { name: 'header.links.roadmap', link: `${appConfig.homePageUrl}#roadmap`, isExternal: true },
  { name: 'header.links.membership', link: `${appConfig.homePageUrl}#membership`, isExternal: true },
  { name: 'header.links.community', link: `${appConfig.homePageUrl}#community`, isExternal: true },
];

export const footerMenuItems: IMenuItem[] = [
  { name: 'header.links.aboutUs', link: `${appConfig.homePageUrl}#about`, isExternal: true },
  { name: 'header.links.membership', link: `${appConfig.homePageUrl}#membership`, isExternal: true },
  { name: 'header.links.roadmap', link: `${appConfig.homePageUrl}#roadmap`, isExternal: true },
  { name: 'header.links.community', link: `${appConfig.homePageUrl}#community`, isExternal: true },
  { name: 'footer.links.mintNow', link: CryptoHuntersRoutesEnum.cryptoHuntersMint, isExternal: false }, // TODO change link to RoutesEnum.mint
];

export const footerRightSideMenuItems: IMenuItem[] = [
  // { name: 'footer.links.ourTeam', link: '#' },
  // { name: 'footer.links.smartContract', link: '#' },
  // { name: 'footer.links.termsOfService', link: '#' },
  { name: 'footer.links.cryptoHunters', link: `${appConfig.cryptoHunters.pageUrl}`, isExternal: true },
  { name: 'footer.links.privacyPolicy', link: `${appConfig.homePageUrl}/privacy-policy.html`, isExternal: true },
];

export const profileMenuItems: IMenuItem[] = [
  { name: 'profile.links.myProfile', link: RoutesEnum.profileDetails, icon: 'aboutGold' },
  { name: 'profile.links.myCollection', link: RoutesEnum.profileNFT, icon: 'collectionGold' },
  { name: 'profile.links.logOut', link: '#', icon: 'logoutGold' },
];

export const profileMenuItemsMobile: IMenuItem[] = [
  { name: 'profile.links.myProfile', link: RoutesEnum.profileDetails, icon: 'about' },
  { name: 'profile.links.myCollection', link: RoutesEnum.profileNFT, icon: 'collection' },
  { name: 'profile.links.logOut', link: '#', icon: 'logout' },
];

export const socialLinksItems: ISocialLinkItem[] = [
  { name: 'facebook', basicIcon: 'facebookInactive', hoveredIcon: 'facebook', link: externalLinks.facebook },
  { name: 'instagram', basicIcon: 'instagramInactive', hoveredIcon: 'instagram', link: externalLinks.instagram },
  { name: 'discord', basicIcon: 'discordInactive', hoveredIcon: 'discord', link: externalLinks.discord },
  { name: 'twitter', basicIcon: 'twitterInactive', hoveredIcon: 'twitter', link: externalLinks.twitter },
  { name: 'youtube', basicIcon: 'youtubeInactive', hoveredIcon: 'youtube', link: externalLinks.youtube },
  { name: 'medium', basicIcon: 'mediumInactive', hoveredIcon: 'medium', link: externalLinks.medium },
];
