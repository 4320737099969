import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { FreeMintModal, GoldenButton } from 'components';
import { FreeMintRoutesEnum, RoutesEnum } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { device } from 'resources/theme';

interface IProps {
  onClose?: () => void;
}

export const FreeMintNotLoggedInModal = ({ onClose }: IProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('views/freeMint');

  return (
    <FreeMintModal onClose={onClose} maxWidth='600px'>
      <StyledWrapper>
        <StyledTitle>{t('modal.notLoggedIn.title')}</StyledTitle>
        <StyledDescription>{t('modal.notLoggedIn.text')}</StyledDescription>
        <StyledButtonsContainer
          onClick={() => {
            navigate({
              pathname: RoutesEnum.signIn,
              search: `?redirect=${FreeMintRoutesEnum.freeMint.replace('/', '')}`,
            });
          }}
        >
          <GoldenButton isFreeMint={true}>{t('modal.notLoggedIn.buttonText')}</GoldenButton>
        </StyledButtonsContainer>
      </StyledWrapper>
    </FreeMintModal>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const StyledTitle = styled.div`
  font-family: 'Syne Extra';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  background: linear-gradient(120.43deg, #ffc149 25.88%, #fffea1 41.78%, #b48900 68.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const StyledDescription = styled.p`
  width: auto;
  font-family: Montserrat Regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: ${({ theme }) => theme.lightTextColor};
  text-align: center;
  margin-bottom: 24px;
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSizeXs};
  }
  @media ${device.mobileL} {
    margin-bottom: 17px;
  }
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;
