import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PriceBlock, Modal, IconImage } from 'components';
import { device } from 'resources/theme';
import { userStore } from 'store';
import { copyToClipboard, formatAddress } from 'utils';

interface IProps {
  totalPrice: number;
  isOpen: boolean;
  loading?: boolean;
  className?: string;
  close?: () => void;
  children?: ReactNode;
  disable?: boolean;
  onSubmit?: () => void;
  submitButtonText?: string;
  maxWidthForLaptop?: string;
  errorMessage?: string;
}

export const CHPurchaseModalView = ({
  totalPrice,
  isOpen,
  close,
  onSubmit,
  submitButtonText,
  loading,
  maxWidthForLaptop,
  errorMessage = '',
}: IProps) => {
  const { t } = useTranslation('views/purchase');
  const [isCopied, setIsCopied] = useState<boolean>(false);
  return (
    <Modal
      isOpen={isOpen}
      close={close}
      onSubmit={onSubmit}
      submitButtonText={submitButtonText}
      loading={loading}
      maxWidthForLaptop={maxWidthForLaptop}
    >
      <StyledHeader>
        <StyledHeaderHeadline>{t('authorizedView.modal.youNeed')}</StyledHeaderHeadline>
        <StyledPriceBlock value={totalPrice} label={t('authorizedView.eth')} />
        <StyledGasDetails>{t('authorizedView.modal.plusGas')}</StyledGasDetails>
      </StyledHeader>
      <StyledDescription>{t('authorizedView.modal.description')}</StyledDescription>
      <StyledContainer>
        <StyledGoldSpan>{t('authorizedView.modal.yourEthWallet')}</StyledGoldSpan>
        <StyledWalletAddressField onMouseEnter={() => setIsCopied(false)}>
          {window.matchMedia(device.mobileL).matches
            ? formatAddress(userStore.user.accountAddress, 12, 10)
            : userStore.user.accountAddress}
          {isCopied ? (
            <IconImage icon='doneMark' />
          ) : (
            <StyledIconCopy
              icon='copy'
              onClick={() => {
                copyToClipboard(userStore.user.accountAddress || '');
                setIsCopied(true);
                setTimeout(() => {
                  setIsCopied(false);
                }, 2000);
              }}
            />
          )}
        </StyledWalletAddressField>
        {errorMessage ? (
          <StyledErrorMessage>
            <StyledIcon icon='errorCross' />
            <StyledErrorText>{errorMessage}</StyledErrorText>
          </StyledErrorMessage>
        ) : null}
      </StyledContainer>
    </Modal>
  );
};

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    'a a . b'
    'c c c c'
    'd d d d';
  margin-bottom: 40px;
  @media ${device.mobileL} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      '. a .'
      'c c c'
      'd d d'
      '. b .';
    margin-bottom: 15px;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  @media ${device.mobileL} {
    display: grid;
    gap: 4px 9px;
    align-items: baseline;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'a b'
      'c c';
  }
`;

const StyledHeaderHeadline = styled.span`
  font-family: 'Ginora Sans', serif !important;
  font-weight: 400;
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  color: ${({ theme }) => theme.lightTextColor};
  text-transform: uppercase;
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSizeXL};
  }
  @media ${device.mobileL} {
    grid-area: a;
    justify-self: right;
    font-weight: 500;
  }
`;

const StyledPriceBlock = styled(PriceBlock)`
  font-weight: 500;
  span {
    font-weight: 500;
  }
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSizeXL};
  }
  @media ${device.mobileL} {
    grid-area: b;
    justify-self: left;
    span {
      font-family: 'Ginora Sans', serif !important;
      font-weight: 500;
    }
  }
`;
const StyledGasDetails = styled.span`
  font-family: 'Ginora Sans', serif !important;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeL};
  color: ${({ theme }) => theme.lightTextColor};
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSizeM};
  }
  @media ${({ theme }) => theme.mobileL} {
    grid-area: c;
    justify-self: center;
    font-weight: 500;
  }
`;

const StyledDescription = styled.p`
  width: auto;
  font-size: ${({ theme }) => theme.fontSizeM};
  font-weight: 300;
  color: ${({ theme }) => theme.lightTextColor};
  text-align: center;
  margin-bottom: 43px;
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSizeXs};
  }
  @media ${device.mobileL} {
    margin-bottom: 17px;
  }
`;

const StyledGoldSpan = styled.div`
  grid-area: a;
  align-self: center;
  font-family: 'Ginora Sans', serif !important;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizeXL};
  background: ${({ theme }) => theme.linearGradientGold};
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  margin-bottom: 6px;
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSizeL};
  }
  @media ${device.mobileL} {
    font-weight: 500;
    justify-self: center;
  }
`;

const StyledWalletAddressField = styled.div`
  grid-area: c;
  width: 100%;
  box-sizing: border-box;
  background: ${({ theme }) => theme.inputBgColor};
  border-radius: 10px;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizeM};
  color: #000;
  @media ${device.laptop} {
    font-size: 11px;
    padding: 10px 22px;
    line-height: 150%;
  }
`;

const StyledIconCopy = styled(IconImage)`
  cursor: pointer;
`;

const StyledErrorMessage = styled.div`
  grid-area: d;
  color: ${({ theme }) => theme.errorColor};
  font-size: ${({ theme }) => theme.errorFontSize};
`;

const StyledErrorText = styled.span`
  display: inline-block;
  margin-left: 12px;
`;

const StyledIcon = styled(IconImage)`
  width: 10px;
  height: 10px;
`;
