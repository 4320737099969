import { Route, Routes, Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { MainLayoutView } from 'views';
import { IRoute } from 'types';
import { Breadcrumbs } from 'components';
import { device } from 'resources/theme';
import { RoutesEnum } from 'constants/index';

interface IProps {
  routes: IRoute[];
  freeMintRoutes: IRoute[];
  adminRoutes: IRoute[];
}

export const Pages = ({ routes, freeMintRoutes, adminRoutes }: IProps) => {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Routes>
      <Route path={RoutesEnum.home} element={<MainLayoutView />}>
        {routes.map((route) => {
          const { component: Component } = route;
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <StyledContainer>
                  {route.path === '/*' ? null : route.parent && <Breadcrumbs route={route} />}
                  <Component />
                </StyledContainer>
              }
            />
          );
        })}
      </Route>
      {/*<Route path={RoutesEnum.home} element={<MainLayoutView isFreeMint={true} />}>*/}
      {/*  {freeMintRoutes.map((route) => {*/}
      {/*    const { component: Component } = route;*/}
      {/*    return (*/}
      {/*      <Route*/}
      {/*        key={route.path}*/}
      {/*        path={route.path}*/}
      {/*        element={*/}
      {/*          <StyledContainer>*/}
      {/*            <Component />*/}
      {/*          </StyledContainer>*/}
      {/*        }*/}
      {/*      />*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</Route>*/}
      <Route path={RoutesEnum.home} element={<StyledAdminLayout children={<Outlet />} />}>
        {adminRoutes.map((route) => {
          const { component: Component } = route;
          return <Route key={route.path} path={route.path} element={<Component />} />;
        })}
      </Route>
    </Routes>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  max-width: 1520px;
  padding: 0px 40px;
  margin: auto;
  box-sizing: border-box;

  @media ${device.tablet} {
    padding: 0 0;
  }
`;

const StyledAdminLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1520px;
  height: 100%;
  padding: 0px 40px;
  margin: auto;
  box-sizing: border-box;

  @media ${device.mobileL} {
    padding: 0 20px;
  }
`;
