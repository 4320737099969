import { ethers, providers } from 'ethers';

import { appConfig } from 'config';

import { rpcProvider } from '../rpcProvider';

export class NftContract {
  static publicContract = new ethers.Contract(
    appConfig.contracts.NFT.address,
    appConfig.contracts.NFT.abi,
    rpcProvider,
  );
  contract: ethers.Contract;

  constructor(provider: providers.Web3Provider) {
    this.contract = new ethers.Contract(
      appConfig.contracts.Marketplace.address,
      appConfig.contracts.Marketplace.abi,
      provider.getSigner(),
    );
  }

  static getTotalSupply = async () => (await NftContract.publicContract.getTotalSupply()).toNumber();

  static balanceOf = async (accountAddress: string): Promise<number> =>
    (await NftContract.publicContract.balanceOf(accountAddress)).toNumber();
}
