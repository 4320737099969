import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { device } from 'resources/theme';
import { authStore, marketplaceStore, userStore } from 'store';
import { ALL_NFTS_COUNT, PRE1658696400000OKENS_AVAILABLE, 1658696400000OKENS_AVAILABLE, RoutesEnum } from 'constants/index';
import { IconImage, Loader, WhiteBtn } from 'components';
import { NotAuthorizedView, AuthorizedView, NoNftView, NoNftTimerView, FrozenSaleView } from 'views';
import { formatAddress } from 'utils';
import { MarketplaceContract, NftContract } from 'blockchain';
import { useFetch } from 'hooks';

export const PurchaseView = observer(() => {
  const { t } = useTranslation('views/purchase');
  const navigate = useNavigate();
  const nftLimit = marketplaceStore.isPresale
    ? PRE1658696400000OKENS_AVAILABLE
    : userStore.isWhitelisted
    ? 1658696400000OKENS_AVAILABLE + PRE1658696400000OKENS_AVAILABLE
    : 1658696400000OKENS_AVAILABLE;
  const [nftLeft, setNftLeft] = useState<number | null>(null);

  const getNftLeft = async (retry: number) => {
    try {
      setNftLeft(ALL_NFTS_COUNT - (await NftContract.getTotalSupply()));
    } catch (e) {
      if (retry <= 0) {
        await getNftLeft(retry - 1);
      } else {
        setNftLeft(0);
      }
    }
  };

  useEffect(() => {
    getNftLeft(3);
    const intervalID = setInterval(() => getNftLeft(1), 60000);
    return () => clearInterval(intervalID);
  }, []);

  useEffect(() => {
    authStore.isAuthorized && userStore.getUser();
  }, [authStore.isAuthorized]);

  const {
    response: userNftCount,
    loading: userNftCountLoading,
    retry: userNftCountRetry,
  } = useFetch(
    () => (authStore.isAuthorized ? NftContract.balanceOf(authStore.accountAddress) : null),
    [authStore.isAuthorized],
  );
  const { response: isFrozen, loading: isFrozenLoading } = useFetch(
    () => (authStore.isAuthorized ? MarketplaceContract.getFreeze() : null),
    [authStore.isAuthorized],
  );
  const isLoading = nftLeft === null || (userNftCount === null && userNftCountLoading) || isFrozenLoading;
  const userNftLimit = nftLimit - Number(userNftCount || 0);
  const canPurchase =
    !isFrozen &&
    (marketplaceStore.isPresale
      ? userStore.isWhitelisted && userNftLimit > 0
      : marketplaceStore.isSale
      ? userNftLimit > 0
      : false);

  const viewContent = useMemo(() => {
    if (!authStore.isAuthorized) {
      return <NotAuthorizedView />;
    }

    if (isFrozen) {
      return <FrozenSaleView />;
    }

    if (canPurchase && nftLeft !== null && nftLeft > 0) {
      return <AuthorizedView maxNft={nftLeft < userNftLimit ? nftLeft : userNftLimit} onPurchase={userNftCountRetry} />;
    }

    if (!marketplaceStore.isSale) {
      return <NoNftTimerView isWhitelisted={userStore.isWhitelisted} />;
    }

    return <NoNftView nftLeft={nftLeft} />;
  }, [authStore.isAuthorized, userStore.isWhitelisted, canPurchase, userNftLimit, nftLeft, marketplaceStore.isSale]);

  return (
    <StyledWrapper>
      <Loader isLoading={isLoading}>
        <StyledContainer>
          <StyledHeader>
            <StyledHeaderText>
              {canPurchase && (
                <>
                  <StyledNumber>
                    {typeof nftLeft === 'number' ? nftLeft.toLocaleString().replace(',', ' ') : '...'}
                  </StyledNumber>
                  <span>{t('header.nftLeft')}</span>
                </>
              )}
            </StyledHeaderText>
            {authStore.isAuthorized ? (
              <StyledWalletBtn onClick={() => navigate(RoutesEnum.profileDetails)}>
                <StyledWallet>
                  <IconImage icon='wallet' />
                </StyledWallet>
                <StyledUsername>{userStore.user?.username || formatAddress(authStore.accountAddress)}</StyledUsername>
              </StyledWalletBtn>
            ) : (
              <StyledWalletBtn onClick={() => navigate(RoutesEnum.signIn)}>
                {t('header.connectWallet')}
                <StyledWallet>
                  <IconImage icon='wallet' />
                </StyledWallet>
              </StyledWalletBtn>
            )}
          </StyledHeader>
        </StyledContainer>
        {viewContent}
      </Loader>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  min-height: 50vh;
`;

const StyledContainer = styled.div`
  padding-top: 24px;
  width: 100%;
  @media ${device.laptop} {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  @media ${device.mobileL} {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-areas:
    '. a b'
    '. a b';
  align-items: center;
  margin-bottom: 40px;
  @media ${device.laptop} {
    margin-bottom: 0;
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
  }
  @media ${device.mobileL} {
    margin-bottom: 24px;
    padding: 0px;
  }
`;

const StyledNumber = styled.span`
  margin-right: 13px;
  padding-bottom: 25px;
  font-size: 96px;
  line-height: 120%;
  font-family: 'Ginora Sans' !important;
  white-space: nowrap;
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.pageTitlesFontSize};
    line-height: 160%;
    margin-right: 10px;
    padding-bottom: 5px;
  }
  @media ${device.mobileL} {
    font-size: ${({ theme }) => theme.fontSizeXXL};
    line-height: 140%;
    margin-right: 10px;
    padding-bottom: 10px;
  }
`;

const StyledWalletBtn = styled(WhiteBtn)`
  grid-area: b;
  justify-self: end;
  grid-gap: 13px;
  @media ${device.mobileL} {
    margin-top: 18px;
  }
`;

const StyledHeaderText = styled.div`
  grid-area: a;
  justify-self: center;
  display: flex;
  align-items: center;
  height: 139px;
  font-family: 'Ginora Sans' !important;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  line-height: 120%;
  text-transform: uppercase;
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  span {
    background: ${({ theme }) => theme.linearGradientGold};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.pageTitlesSmallerSize};
    height: 85px;
  }
  @media ${device.mobileL} {
    height: fit-content;
    font-size: ${({ theme }) => theme.fontSizeXL};
    line-height: 140%;
  }
`;

const StyledWallet = styled.div`
  height: 26px;
  width: 26px;
  position: relative;
  img {
    @media ${device.laptop} {
      width: 18px !important;
      height: 18px !important;
    }
  }
`;

const StyledUsername = styled.div`
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
