import styled from 'styled-components';
import { SquareIconButton } from 'components/buttons/SquareIconButton';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { device } from 'resources/theme';

interface IProps {
  max: number;
  min: number;
  count: SetStateAction<number>;
  setCount: Dispatch<SetStateAction<number>>;
}

export const NFTCounter = ({ max, min, setCount, count, ...props }: IProps) => {
  const [error, setError] = useState<string>('');
  const { t } = useTranslation();
  const incrementCount = () => {
    if (count < max) {
      setCount((prevState) => prevState + 1);
      setError('');
    } else {
      setError(`${t('counter.error.max')}${max}`);
    }
  };
  const decrementCount = () => {
    if (count > min) {
      setCount((prevState) => prevState - 1);
      setError('');
    } else {
      setError(`${t('counter.error.min')}${min}`);
    }
  };
  return (
    <StyledContainer {...props}>
      <StyledButtonContainer>
        <StyledButton onClick={decrementCount}>−</StyledButton>
        <StyledSpan>{count}</StyledSpan>
        <StyledButton onClick={incrementCount}>+</StyledButton>
      </StyledButtonContainer>
      <StyledErrorSpan>{error && error}</StyledErrorSpan>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin: 24px 0 56px;
  @media ${device.mobileL} {
    margin: 16px 0 24px;
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 220px;
  @media ${device.laptopL} {
    max-width: 190px;
  }
`;

const StyledButton = styled(SquareIconButton)`
  border-radius: 0;
  width: 50px;
  height: 50px;
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  @media ${device.laptopL} {
    width: 45px;
    height: 45px;
  }
  @media ${device.mobileL} {
    width: 40px;
    height: 40px;
  }
`;

const StyledSpan = styled.span`
  background: ${({ theme }) => theme.linearGradientGold};
  font-size: ${({ theme }) => theme.fontSizeXXL};
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  @media ${device.laptopL} {
    font-size: ${({ theme }) => theme.headlineSmallerSize};
  }
  @media ${device.mobileL} {
    font-size: ${({ theme }) => theme.fontSizeXL};
  }
`;

const StyledErrorSpan = styled.span`
  display: block;
  height: 16px;
  color: ${({ theme }) => theme.errorColor};
  font-size: ${({ theme }) => theme.fontSizeXs};
`;
