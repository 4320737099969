import { Pages } from './routes';

import { generateAppRoutes } from 'utils';
import { freeMintRoutesArray, routesArray } from 'constants/index';
import { adminRoutesArray } from 'constants/index';
import { hot } from 'react-hot-loader/root';

export const App = () => {
  const routes = routesArray();
  const freeMintRoutes = freeMintRoutesArray();
  const adminRoutes = adminRoutesArray();
  const appRoutes = generateAppRoutes(routes);
  const appFreeMintRoutes = generateAppRoutes(freeMintRoutes);
  const appAdminRoutes = generateAppRoutes(adminRoutes);

  return (
    <>
      <Pages routes={appRoutes} freeMintRoutes={appFreeMintRoutes} adminRoutes={appAdminRoutes} />
    </>
  );
};

export default hot(App);
