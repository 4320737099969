import React from 'react';
import styled from 'styled-components';
import circleCenterSvg from 'resources/images/freeMint/gallery/circleCenter.svg';
import ellipseRightSvg from 'resources/images/freeMint/gallery/ellipseRight.svg';
import ellipseLeftSvg from 'resources/images/freeMint/gallery/ellipseLeft.svg';

interface IProps {
  onClick: () => void;
  isSelected: boolean;
}

export const GalleryButton = ({ isSelected, onClick }: IProps) => {
  return (
    <StyledGalleryButtonWrapper
      onClick={() => {
        if (!isSelected) {
          onClick();
        }
      }}
      isSelected={isSelected}
    >
      <StyledGalleryCircle />
      {isSelected && <StyledGalleryEllipseRight />}
      {isSelected && <StyledGalleryEllipseLeft />}
    </StyledGalleryButtonWrapper>
  );
};

const StyledGalleryButtonWrapper = styled.div<{ isSelected: boolean }>`
  position: relative;
  width: 24px;
  height: 24px;
  margin: 10px;
  border-radius: 12px;
  cursor: ${({ isSelected }) => (isSelected ? 'initial' : 'pointer')};
  user-select: none;
`;

const StyledGalleryCircle = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 1px;
  margin: auto;
  width: 6.21px;
  height: 6.21px;
  background-image: url(${circleCenterSvg});
  background-size: contain;
  border-radius: 5px;
  text-align: center;
  user-select: none;
`;

const StyledGalleryEllipseRight = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  width: 18px;
  height: 17px;
  background-image: url(${ellipseRightSvg});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  user-select: none;
`;

const StyledGalleryEllipseLeft = styled.div`
  position: absolute;
  bottom: 2px;
  left: 2px;
  width: 18px;
  height: 16px;
  background-image: url(${ellipseLeftSvg});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  user-select: none;
`;
