import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validationProfileSchema } from 'schemas';
import { observer } from 'mobx-react-lite';

import { IconImage, GoldenButton, Loader, SuccessModal } from 'components';
import styled from 'styled-components';
import { copyToClipboard, formatAddress } from 'utils';
import { userStore } from 'store';
import { device } from 'resources/theme';
import { ErrorMessageView } from 'views/common';
import { ErrorKeys } from 'constants/index';
import { useModal } from 'hooks';
import { IUser } from 'types';

interface IError {
  [x: string]: string;
}

export const EditUserProfileView = observer(() => {
  const { t } = useTranslation('views/profile');
  const { open, close, isOpen } = useModal();
  const [errors, setErrors] = useState<IError>({ userName: '', email: '' });
  const [profileData, setProfileData] = useState<IUser>({ username: '', email: '', accountAddress: '' });
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const isMobile = window.matchMedia(device.mobileL).matches;

  useEffect(() => {
    userStore.getUser();
  }, []);

  useEffect(() => {
    const user = userStore.user;
    setProfileData(user);
  }, [userStore.user]);

  const handleEditUser = async () => {
    const username = profileData.username;
    const email = profileData.email;
    const user = { email, username };
    const isValid = await validationProfileSchema.isValid(user);

    if (isValid) {
      const isSuccess = await userStore.editUser(user);
      if (isSuccess) {
        setErrors({ userName: '', email: '' });
        setDisabled(true);
        open();
      }
    } else {
      await validationProfileSchema.validate(user, { abortEarly: false }).catch((err) => {
        const errors = (err.inner as IError[]).reduce((acc, error) => {
          return {
            ...acc,
            [error.path]: error.message,
          };
        }, {});
        setErrors(errors);
      });
    }
  };

  const handleSetUserName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const username = e.target.value;
    setProfileData((current) => ({ ...current, username }));
    if (username === userStore.user.username && profileData.email === userStore.user.email) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const handleSetEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setProfileData((current) => ({ ...current, email }));
    if (email === userStore.user.email && profileData.username === userStore.user.username) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  return (
    <div>
      <SuccessModal message={t('your personal data is saved')} isOpen={isOpen} close={close} />
      <StyledContainer>
        <StyledHint>{t('edit.userName')}:</StyledHint>
        <StyledInputBox>
          <StyledInput
            type='text'
            placeholder={t('edit.userName')}
            isError={!!errors.userName}
            value={profileData.username || ''}
            onChange={handleSetUserName}
          />
          {errors.userName ? <StyledErrorIcon icon='error' /> : null}
          {errors.userName ? (
            <StyledError>
              <StyledX>✖</StyledX>
              {t(errors.userName)}
            </StyledError>
          ) : null}
        </StyledInputBox>
        <StyledHint>{t('edit.emailAddress')}:</StyledHint>
        <StyledInputBox>
          <StyledInput
            type='text'
            placeholder={t('edit.emailAddress')}
            isError={!!errors.email}
            value={profileData.email || ''}
            onChange={handleSetEmail}
          />
          {errors.email ? <StyledErrorIcon icon='error' /> : null}
          {errors.email ? (
            <StyledError>
              <StyledX>✖</StyledX>
              {t(errors.email)}
            </StyledError>
          ) : null}
        </StyledInputBox>
        <StyledHint>{t('edit.yourETNWallet')}:</StyledHint>
        <StyledWalletBox>
          <StyledWalletWrapper onMouseEnter={() => setIsCopied(false)}>
            {isMobile ? formatAddress(profileData.accountAddress) : profileData.accountAddress}
            {isCopied ? (
              <StyledDoneIcon icon='doneMark'></StyledDoneIcon>
            ) : (
              <StyledCopyIcon
                icon='copyToClipboard'
                onClick={() => {
                  copyToClipboard(profileData.accountAddress || '');
                  setIsCopied(true);
                  setTimeout(() => {
                    setIsCopied(false);
                  }, 2000);
                }}
              />
            )}
          </StyledWalletWrapper>
        </StyledWalletBox>
        <ErrorMessageView keys={[ErrorKeys.ProfileEditError]} />
        <StyledButtonWrapper>
          <GoldenButton onClick={handleEditUser} disabled={disabled}>
            {t('edit.save')}
          </GoldenButton>
        </StyledButtonWrapper>
      </StyledContainer>
      <Loader isLoading={!userStore.user.accountAddress} />
    </div>
  );
});

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const StyledHint = styled.p`
  font-family: 'Ginora Sans', serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeXL};
  line-height: 28px;
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0 0 16px 0;

  @media ${device.laptopM} {
    font-size: 18px;
    line-height: 20.71px;
    letter-spacing: 0px;
  }

  @media ${device.mobileL} {
    font-size: 16px;
    line-height: 19.2px
    letter-spacing: 0px;
  }
`;

const StyledInputBox = styled.div`
  position: relative;
  max-width: 460px;
  margin-bottom: 35px;
  @media ${device.laptop} {
    max-width: 365px;
    margin-bottom: 33px;
  }
`;

const StyledWalletBox = styled.div`
  position: relative;
  max-width: 639px;
`;

const StyledCopyIcon = styled(IconImage)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-22px, -50%);
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const StyledDoneIcon = styled(StyledCopyIcon)`
  cursor: auto;
`;

const StyledInput = styled.input<{ isError?: boolean }>`
  width: 100%;
  padding: 16px 52px 15px 32px;
  background: #fff3f3;
  border: ${({ isError, theme }) => (isError ? `1px solid ${theme.errorColor}` : 'unset')};
  border-radius: 10px;
  outline: none;
  font-family: 'Poppins', serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeM};
  line-height: 24px;
  box-sizing: border-box;
  @media ${device.laptop} {
    padding: 12px 52px 12px 17px;
    font-size: ${({ theme }) => theme.fontSizeS};
    line-height: 150%;
  }
`;

const StyledWalletWrapper = styled.div<{ isError?: boolean }>`
  width: 100%;
  padding: 16px 52px 15px 32px;
  background: #fff3f3;
  border: ${({ isError, theme }) => (isError ? `1px solid ${theme.errorColor}` : 'unset')};
  border-radius: 10px;
  outline: none;
  font-family: 'Poppins', serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeM};
  line-height: 24px;
  box-sizing: border-box;
  @media ${device.laptop} {
    padding: 12px 52px 12px 17px;
    font-size: ${({ theme }) => theme.fontSizeS};
    line-height: 150%;
  }
`;

const StyledErrorIcon = styled(IconImage)`
  position: absolute;
  right: 0;
  transform: translate(-22px, 50%);
  width: 24px;
  height: 24px;
`;

const StyledX = styled.span`
  padding-right: 12px;
  padding-left: 12px;
`;

const StyledError = styled.div`
  font-family: 'Poppins', serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeXs};
  line-height: 20px;
  color: ${({ theme }) => theme.errorColor};
`;

const StyledButtonWrapper = styled.div`
  margin: 48px 0 120px;
  @media ${device.laptop} {
    button {
      font-size: ${({ theme }) => theme.fontSizeS};
      line-height: 16px;
    }
    margin: 42px 0 72px;
  }
`;
