import { ethers, providers } from 'ethers';
import { appConfig } from 'config';
import { rpcProvider } from '../rpcProvider';

export class CryptoHuntersContract {
  static publicContract = new ethers.Contract(
    appConfig.cryptoHunters.contracts.CryptoHunters.address,
    appConfig.cryptoHunters.contracts.CryptoHunters.abi,
    rpcProvider,
  );
  contract: ethers.Contract;

  constructor(provider: providers.Web3Provider) {
    this.contract = new ethers.Contract(
      appConfig.cryptoHunters.contracts.CryptoHunters.address,
      appConfig.cryptoHunters.contracts.CryptoHunters.abi,
      provider.getSigner(),
    );
  }

  static getTotalSupply = async () => {
    const totalSupply = await CryptoHuntersContract.publicContract.totalSupply();
    return totalSupply.toNumber();
  };

  static balanceOf = async (address: string) => {
    const balance = await CryptoHuntersContract.publicContract.balanceOf(address);
    return balance.toNumber();
  };

  static publicSaleTokensMinted = async (address: string) => {
    const publicSalesMinted = await CryptoHuntersContract.publicContract.publicMint(address);
    return publicSalesMinted.toNumber();
  };

  static whitelistTokensMinted = async (address: string) => {
    const whiteListMinted = await CryptoHuntersContract.publicContract.whitelist(address);
    return whiteListMinted.toNumber();
  };

  static isMintEnabled = async () => {
    return await CryptoHuntersContract.publicContract.isMintEnabled();
  };

  static isPublicSaleEnabled = async () => {
    return await CryptoHuntersContract.publicContract.publicMintEnabled();
  };

  mint = async (quantity: number, signature: string, price: number) => {
    const tx = await this.contract.mint(quantity, signature, {
      value: ethers.utils.parseEther(String(price)).toString(),
    });
    return tx.wait();
  };
}
