import { providers } from 'ethers';

import { IWalletProvider } from 'types';

export const metamaskProvider = (window as any).ethereum;

export class MetamaskWalletProvider implements IWalletProvider {
  provider: providers.Web3Provider;

  constructor() {
    this.provider = new providers.Web3Provider(metamaskProvider);
  }

  async getConnectedAccount(): Promise<string> {
    return (await this.provider?.send('eth_requestAccounts', []))[0];
  }

  async getChainId(): Promise<number> {
    return (await this.provider.getNetwork())?.chainId;
  }

  async signMessage(accountAddress: string, message: string): Promise<string> {
    return this.provider.send('personal_sign', [message, accountAddress.toLowerCase()]);
  }

  getWeb3Provider(): providers.Web3Provider {
    return this.provider;
  }
}
