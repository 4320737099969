import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Route, Routes } from 'react-router';

import { ProfileNavigation } from 'components';
import { RoutesEnum } from 'constants/routes';
import { useAuthRequire } from 'hooks';

import { EditUserProfileView } from './EditUserProfileView';
import { ProfileNFT } from './ProfileNFT';
import { device } from 'resources/theme';

export const UserProfileView = () => {
  const { t } = useTranslation('views/profile');
  useAuthRequire();

  return (
    <StyledMainContainer>
      <StyledHeader>{t('prof.yourProf')}</StyledHeader>
      <ProfileNavigation />
      <Routes>
        <Route path={RoutesEnum.profileDetailsRelative} element={<EditUserProfileView />} />
        <Route path={RoutesEnum.profileNFTRelative} element={<ProfileNFT />} />
      </Routes>
    </StyledMainContainer>
  );
};

const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled.h1`
  margin: 30px 0 40px;
  max-width: 481px;
  font-family: 'Ginora Sans', serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  text-align: left;
  text-transform: uppercase;
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media ${device.laptop} {
    font-weight: 500;
    font-size: 32px;
    margin: 18px 0 24px;
  }
`;
