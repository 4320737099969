import { ReactNode } from 'react';
import styled from 'styled-components';
import { device } from 'resources/theme';

interface IProps {
  onClose?: () => void;
  children?: ReactNode;
  maxWidth?: string;
}
export const FreeMintModal = ({ onClose, children, maxWidth }: IProps) => {
  return (
    <StyledBackground>
      <StyledContainer maxWidth={maxWidth}>
        <StyledCloseButtonContainer>
          <StyledCloseButton onClick={onClose}>✕</StyledCloseButton>
        </StyledCloseButtonContainer>
        <StyledModalWindow>{children}</StyledModalWindow>
      </StyledContainer>
    </StyledBackground>
  );
};

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 110vh;
  width: 100vw;
  z-index: 4;
  background: rgba(0, 0, 0, 0.5);
`;

const StyledContainer = styled.div<{ maxWidth: string | undefined }>`
  position: relative;
  width: 80%;
  max-width: ${({ maxWidth }) => maxWidth || '1260px'};
  height: auto;
  top: 50%;
  left: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 2px;
  color: rgba(0, 0, 139, 0.9);
  @media ${device.laptop} {
    width: 70%;
  }
  @media ${device.mobileL} {
    width: 80%;
  }
`;

const StyledModalWindow = styled.div`
  background: linear-gradient(270deg, #4a90f9 0%, #153666 83.33%);
  width: auto;
  height: 100%;
  border-radius: 10px;
  padding: 40px 100px 30px 80px;
  @media ${device.mobileL} {
    padding: 40px 20px 30px 20px;
  }
`;

const StyledCloseButtonContainer = styled.div`
  position: absolute;
  top: -25px;
  right: 0;
  transform: translate(0, -100%);
  &:hover {
    opacity: 0.5;
  }
  @media ${device.laptop} {
    width: 26px;
    height: 26px;
    font-size: 20px;
    font-weight: 700;
  }
`;

const StyledCloseButton = styled.button`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  z-index: 0;
  width: 27px;
  height: 27px;
  position: relative;
  background: ${({ theme }) => theme.freeMintModalCloseButtonGradient};
  outline: none;
  font-family: 'Ginora Sans', serif !important;
  font-weight: 400;
  font-size: 100%;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  color: #4a90f9;
  background-clip: unset;
  -webkit-background-clip: unset;
  -moz-background-clip: unset;
  text-fill-color: #4a90f9;
  -webkit-text-fill-color: #4a90f9;
  -moz-text-fill-color: #4a90f9;

  img {
    filter: brightness(0);
  }
`;
