import React from 'react';
import styled from 'styled-components';
import { FreeMintModal, GoldenButton, IconImage } from 'components';
import { Icons } from '../../../types';
import { device } from 'resources/theme';

interface IProps {
  icon: Icons;
  title: string;
  text?: string;
  onClose?: () => void;
  onSubmit?: () => void;
  submitButtonText?: string;
}

export const FreeMintSimpleModal = ({
  icon,
  title,
  text,
  onClose,
  onSubmit,
  submitButtonText = 'Continue',
}: IProps) => {
  return (
    <FreeMintModal onClose={onClose} maxWidth='600px'>
      <StyledWrapper>
        <StyledTitleContainer>
          <StyledModalIcon icon={icon} />
          <StyledTitle>{title}</StyledTitle>
        </StyledTitleContainer>
        <StyledDescription>{text}</StyledDescription>
        {onSubmit ? (
          <StyledButtonContainer>
            <GoldenButton isFreeMint={true} onClick={onSubmit}>
              {submitButtonText}
            </GoldenButton>
          </StyledButtonContainer>
        ) : (
          <></>
        )}
      </StyledWrapper>
    </FreeMintModal>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  @media ${device.mobileL} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const StyledModalIcon = styled(IconImage)`
  width: 49px;
  height: 49px;
  @media ${device.laptop} {
    height: 35px;
    width: 35px;
  }
  @media ${device.mobileL} {
    height: 33px;
    width: 33px;
  }
`;

const StyledTitle = styled.div`
  max-width: 250px;
  text-align: left;
  padding-left: 25px;
  font-family: 'Syne Extra';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  background: linear-gradient(120.43deg, #ffc149 25.88%, #fffea1 41.78%, #b48900 68.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media ${device.mobileL} {
    text-align: center;
  }
`;

const StyledDescription = styled.p`
  width: auto;
  font-family: Montserrat Regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: ${({ theme }) => theme.lightTextColor};
  text-align: center;
  margin-bottom: 24px;
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSizeXs};
  }
  @media ${device.mobileL} {
    margin-bottom: 17px;
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
