import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import imageBorderBgSvg from 'resources/images/freeMint/about/imageBorderBg.svg';
import { GalleryButton } from './GalleryButton';
import { device } from '../../../../resources/theme';

interface IProps {
  items: string[];
}

export const Gallery = ({ items }: IProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showImage, setShowImage] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    let timeout = setTimeout(() => {
      let newIndex = selectedIndex + 1;
      if (newIndex > items.length - 1) {
        newIndex = 0;
      }

      setFadeOut(true);
      timeout = setTimeout(() => {
        setShowImage(false);
        setSelectedIndex(newIndex);
        setFadeOut(false);
        setShowImage(true);
      }, 1000);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [selectedIndex, setSelectedIndex, setShowImage, setFadeOut]);

  return (
    <StyledGalleryWrapper>
      <StyledGalleryImageContainer>
        <StyledImage fadeOut={fadeOut} showImage={showImage} src={items[selectedIndex]} alt='' />
      </StyledGalleryImageContainer>
      <StyledGalleryButtonsContainer>
        {items.map((item, index) => {
          return (
            <GalleryButton
              key={index.toString()}
              onClick={() => {
                setFadeOut(true);
                setShowImage(false);
                setSelectedIndex(index);
                setFadeOut(false);
                setShowImage(true);
              }}
              isSelected={selectedIndex === index}
            />
          );
        })}
      </StyledGalleryButtonsContainer>
    </StyledGalleryWrapper>
  );
};

const StyledGalleryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  min-width: 336px;
  max-width: 536px;
  margin-top: 24px;
  margin-bottom: 10px;
`;

const StyledGalleryImageContainer = styled.div`
  box-sizing: border-box;
  background-image: url(${imageBorderBgSvg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 35px;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
  }

  @media ${device.mobileL} {
    padding: 25px;
  }
`;

const StyledImage = styled.img<{ fadeOut: boolean; showImage: boolean }>`
  border-radius: 20px;
  animation: ${({ fadeOut }) => (fadeOut ? 'fadeOut 2s' : 'fadeIn 5s')};
  visibility: ${({ showImage }) => (showImage ? 'visible' : 'hidden')};
`;

const StyledGalleryButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  min-width: 200px;
`;
