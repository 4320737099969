import { userStore } from 'store';
import { makeAutoObservable } from 'mobx';
import { ethers, providers } from 'ethers';

import { ErrorKeys, ErrorReasons, LocalStorageKeys, WalletTypes } from 'constants/index';
import { metamaskProvider, getWalletConnectProvider, MetamaskWalletProvider, WcWalletProvider } from 'blockchain';
import { getFromLS, removeFromLS, setToLS, toHex } from 'utils';
import { appConfig } from 'config';
import { IWalletProvider } from 'types';

import { errorStore } from './error';

export class WalletStore {
  type: WalletTypes | '' = getFromLS(LocalStorageKeys.Wallet, '');
  connectedAccount: string = getFromLS(LocalStorageKeys.WalletAddress, '');

  constructor() {
    makeAutoObservable(this);
    this.init();
  }

  get isConnected(): boolean {
    return !!this.connectedAccount;
  }

  get walletProvider(): IWalletProvider | null {
    switch (this.type) {
      case WalletTypes.Metamask:
        return metamaskProvider ? new MetamaskWalletProvider() : null;
      case WalletTypes.WalletConnect:
        return new WcWalletProvider();
      default:
        return null;
    }
  }

  get web3Provider(): providers.Web3Provider | undefined {
    return this.walletProvider?.getWeb3Provider();
  }

  async checkConnection() {
    if (this.type) {
      const walletProvider = this.walletProvider;
      if (walletProvider) {
        try {
          const connectedAccount = await walletProvider.getConnectedAccount();
          const chainId = await walletProvider.getChainId();
          if (!connectedAccount || !chainId || toHex(chainId) !== toHex(appConfig.networkId)) {
            this.disconnect();
          } else {
            this.connectedAccount = connectedAccount;
            setToLS(LocalStorageKeys.WalletAddress, this.connectedAccount);
          }
        } catch (error) {
          this.disconnect();
        }
      } else {
        this.disconnect();
      }
    }
  }

  async connectToMetamask() {
    if (metamaskProvider) {
      try {
        await metamaskProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: toHex(appConfig.networkId) }],
        });
        const provider = new ethers.providers.Web3Provider(metamaskProvider);
        const [connectedAccount] = await provider.send('eth_requestAccounts', []);
        this.connect(WalletTypes.Metamask, connectedAccount);
        errorStore.removeError(ErrorKeys.ConnectError);
      } catch (err) {
        throw errorStore.setError({ ...err, key: ErrorKeys.ConnectError, reason: this.getMetamaskErrorReason(err) });
      }
    } else {
      throw errorStore.setError({ ...new Error(), key: ErrorKeys.ConnectError, reason: ErrorReasons.NoMetamask });
    }
  }

  async connectToWalletConnect() {
    try {
      const [connectedAccount] = await getWalletConnectProvider().enable();
      this.connect(WalletTypes.WalletConnect, connectedAccount);
      errorStore.removeError(ErrorKeys.ConnectError);
    } catch (err) {
      throw errorStore.setError({ ...err, key: ErrorKeys.ConnectError, reason: ErrorReasons.WalletNotConnected });
    }
  }

  disconnect() {
    userStore.clearUser();
    this.type = '';
    this.connectedAccount = '';
    removeFromLS(LocalStorageKeys.Wallet);
    removeFromLS(LocalStorageKeys.WalletAddress);
  }

  private connect(walletType: WalletTypes, connectedAccount: string) {
    this.type = walletType;
    this.connectedAccount = connectedAccount;
    setToLS(LocalStorageKeys.Wallet, this.type);
    setToLS(LocalStorageKeys.WalletAddress, this.connectedAccount);
  }

  private init() {
    this.checkConnection();

    if (metamaskProvider) {
      metamaskProvider.on('accountsChanged', () => this.checkConnection());
      metamaskProvider.on('chainChanged', () => this.checkConnection());
    }

    const walletConnectProvider = getWalletConnectProvider();
    if (walletConnectProvider) {
      walletConnectProvider.on('accountsChanged', () => this.checkConnection());
      walletConnectProvider.on('chainChanged', () => this.checkConnection());
    }
  }

  private getMetamaskErrorReason(error: { code: number }): ErrorReasons {
    switch (error.code) {
      case 4001:
        return ErrorReasons.WalletRequestRejected;
      default:
        return ErrorReasons.Unknown;
    }
  }
}

export const walletStore = new WalletStore();
