import React from 'react';
import styled from 'styled-components';
import { device } from 'resources/theme';
import { useTranslation } from 'react-i18next';
import { IconImage, WhitelistModal } from 'components';

interface IProps {
  onClose?: () => void;
}

export const WhitelistSuccessfulModal = ({ onClose }: IProps) => {
  const { t } = useTranslation('views/cryptoHunters');

  return (
    <WhitelistModal onClose={onClose}>
      <StyledWrapper>
        <StyledTitleContainer>
          <StyledModalIcon icon='goldenCheckMark' />
          <StyledTitle>{t('checkWhitelist.successfulModal.primaryTextLine1')}</StyledTitle>
          <StyledTitle>{t('checkWhitelist.successfulModal.primaryTextLine2')}</StyledTitle>
        </StyledTitleContainer>
        <StyledDescription>{t('checkWhitelist.successfulModal.secondaryText')}</StyledDescription>
      </StyledWrapper>
    </WhitelistModal>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.mobileL} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const StyledModalIcon = styled(IconImage)`
  width: 34px;
  height: 34px;
  margin-bottom: 16px;
`;

const StyledTitle = styled.div`
  font-family: 'Ginora Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  background: linear-gradient(120.43deg, #ffc149 25.88%, #fffea1 41.78%, #b48900 68.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media ${device.mobileL} {
    font-size: 16px;
    line-height: 19px;
  }
`;

const StyledDescription = styled.p`
  width: auto;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px;

  @media ${device.mobileL} {
    font-size: 14px;
    line-height: 21px;
  }
`;
