import styled from 'styled-components';

interface IProps {
  percentage: number;
  label?: string;
  total?: number;
}

export const Progress = ({ percentage, label, total = 100 }: IProps) => {
  const progressPercentage = `${Math.floor(percentage * (100 / total))}%`;
  return (
    <StyledContainer>
      <StyledLineContainer>
        <StyledProgressLine style={{ width: progressPercentage }} />
      </StyledLineContainer>
      <StyledLabel>{label || progressPercentage}</StyledLabel>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  margin: 20px 0;
`;

const StyledLineContainer = styled.div`
  position: relative;
  height: 10px;
  width: 100%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.lightBgColor};
`;

const StyledProgressLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  border-radius: 10px;
  background: ${({ theme }) => theme.linearGradientGold};
`;

const StyledLabel = styled.p`
  color: ${({ theme }) => theme.lightBgColor};
`;
