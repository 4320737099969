export const toHex = (d: number): string => {
  return '0x' + Number(d).toString(16);
};

export const formatAddress = (accountAddress = '', charsOnBegining = 7, charsOnEnd = 4) => {
  if (accountAddress.indexOf('0x') === 0) {
    accountAddress = accountAddress.slice(2).toUpperCase();
  }
  return accountAddress.length > 5
    ? `0x${accountAddress.slice(0, charsOnBegining)}...${accountAddress.slice(-charsOnEnd)}`
    : accountAddress;
};
