import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Logo, GoldenButton, IconImage, NavMenu } from 'components';
import { device } from 'resources/theme';
import { authStore, userStore } from 'store';
import { CryptoHuntersRoutesEnum, headerMenuItems, profileMenuItems, RoutesEnum } from 'constants/index';
import { MobileMenu } from './MobileMenu';
import { openExternalLink } from 'utils';
import { externalLinks } from 'constants/externalLinks';
import bgEllipse from 'resources/images/bgEllipse.svg';
import bgEllipse2 from 'resources/images/bgEllipse2.svg';
import { useLocation } from 'react-router-dom';

export const HeaderView = observer(() => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { username } = userStore.user;

  return (
    <StyledContainer>
      <StyledInnerContainer>
        <Logo />
        <StyledCheckbox
          id='menu'
          type='checkbox'
          checked={openMenu}
          onChange={() => {
            setOpenMenu(!openMenu);
          }}
        />
        <StyledLabel htmlFor='menu'>
          <span></span>
        </StyledLabel>
        <StyledHeaderMenuCountainer>
          <NavMenu items={headerMenuItems} />
          <StyledProfileBtn
            onClick={() => {
              if (!authStore.isAuthorized) {
                let redirectPage;
                if (location.pathname === CryptoHuntersRoutesEnum.cryptoHuntersMint) {
                  redirectPage = CryptoHuntersRoutesEnum.cryptoHuntersMint;
                } else if (location.pathname === CryptoHuntersRoutesEnum.checkWhitelist) {
                  redirectPage = CryptoHuntersRoutesEnum.checkWhitelist;
                }

                if (redirectPage) {
                  navigate({
                    pathname: RoutesEnum.signIn,
                    search: `?redirect=${redirectPage.replace('/', '')}`,
                  });
                } else {
                  navigate(RoutesEnum.signIn);
                }
              }
            }}
          >
            <ProfileIconImage icon='goldProfile' />
            <span>{authStore.isAuthorized ? (username ? username : t('header.yourProfile')) : t('header.login')}</span>
            {authStore.isAuthorized ? (
              <StyledDropDown>
                <NavMenu items={profileMenuItems} rotation='column'></NavMenu>
              </StyledDropDown>
            ) : (
              <></>
            )}
          </StyledProfileBtn>
        </StyledHeaderMenuCountainer>
        <StyledDiscordButton>
          <GoldenButton onClick={() => openExternalLink(externalLinks.discord)} icon='discord'>
            {t('header.joinDiscord')}
          </GoldenButton>
        </StyledDiscordButton>
        <StyledMobileMenu>
          <MobileMenu setOpenMenu={setOpenMenu} />
        </StyledMobileMenu>
      </StyledInnerContainer>
    </StyledContainer>
  );
});

const StyledDropDown = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 96px;
  right: -50px;
  background: ${({ theme }) => theme.bgColor};
  display: flex;
  border: #bc9b49 1px solid;
  border-top: none;
  img {
    filter: brightness(0) invert(100%);
  }
  ul {
    margin: 0;
  }
  li {
    &:nth-last-child(1) {
      border-bottom: none;
    }
    padding-left: 21px;
    padding-right: 21px;
    display: flex;
    align-items: center;
    height: 58px;
    border-bottom: 1px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      90deg,
      rgba(188, 155, 73, 0) 0%,
      #bc9b49 23.21%,
      #f6e389 85.81%,
      rgba(246, 227, 137, 0) 100%
    );
  }
  visibility: hidden;
`;

const ProfileIconImage = styled(IconImage)`
  filter: brightness(0) invert(100%); //changes color of icon from gold to white
`;

const StyledProfileBtn = styled.div`
  display: flex;
  position: relative;
  height: 100px;
  align-items: center;
  gap: 10px;
  font-family: 'Ginora Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: ${({ theme }) => theme.lightTextColor};
  cursor: pointer;
  &:hover,
  &:active {
    span {
      background: ${({ theme }) => theme.linearGradientGold};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    ${ProfileIconImage} {
      filter: unset;
    }
    ${StyledDropDown} {
      background: ${({ theme }) => theme.bgColor};
      -webkit-background-clip: unset;
      -webkit-text-fill-color: ${({ theme }) => theme.lightTextColor};
      background-clip: unset;
      text-fill-color: ${({ theme }) => theme.lightTextColor};
      color: ${({ theme }) => theme.lightTextColor};
      visibility: visible;
    }
  }
`;

const StyledMobileMenu = styled.div`
  position: fixed;
  top: 70px;
  left: 0;
  display: none;
  flex-direction: column;
  width: 100vw;
  background: ${({ theme }) => theme.bgColor};
  backdrop-filter: blur(50px);
  ul {
    margin-top: 0;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const StyledLabel = styled.label`
  cursor: pointer;
  display: none;
  span {
    background: ${({ theme }) => theme.textColorGold};
    display: block;
    height: 4px;
    position: relative;
    width: 28px;
    &:before {
      content: '';
      background: ${({ theme }) => theme.textColorGold};
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 8px;
    }
    &:after {
      content: '';
      background: ${({ theme }) => theme.textColorGold};
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      top: -8px;
    }
  }
`;
const StyledDiscordButton = styled.div``;

const StyledHeaderMenuCountainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledCheckbox = styled.input`
  display: none;
  &:checked ~ ${StyledMobileMenu} {
    display: flex;
  }
  &:checked ~ ${StyledLabel} {
    width: 29px;
    height: 29px;
    background: ${({ theme }) => theme.linearGradientGold};
    span {
      background: transparent;
      &:before {
        background: #000;
        transform: rotate(-45deg);
        top: 13px;
        left: 4px;
        width: 21px;
        border-radius: 5px;
      }
      &:after {
        background: #000;
        transform: rotate(45deg);
        top: 13px;
        left: 4px;
        width: 21px;
        border-radius: 5px;
      }
    }
  }
`;

const StyledInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: auto;
`;

const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding: 0 15rem 0;
  width: 100%;
  margin: 0;
  height: 91px;
  border-bottom: 1px solid #bc9b49;
  background: ${({ theme }) => theme.bgColor};
  background-image: url(${bgEllipse}), url(${bgEllipse2});
  background-position: right top, left top;
  background-repeat: no-repeat, no-repeat;
  z-index: 2;
  @media ${device.laptop} {
    background-image: none;
    padding: 0 2rem 0;
  }
  @media ${device.tablet} {
    height: 70px;
    border-bottom: none;
    padding-right: 24px;
    padding-left: 24px;
    justify-content: space-between;
    ${StyledHeaderMenuCountainer} {
      display: none;
    }
    ${StyledDiscordButton} {
      display: none;
    }
    ${StyledLabel} {
      display: block;
    }
  }
`;
