import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { ErrorKeys, WalletTypes } from 'constants/index';
import { authStore } from 'store';
import { IconImage } from 'components';
import { ErrorMessageView } from 'views';
import { useAuthRequire } from 'hooks';
import { device } from 'resources/theme';
import { useEffect } from 'react';
import { fixMetamaskConnection } from 'utils';

export const AdminSignInView = observer(() => {
  useAuthRequire({ isAuthRequired: false });

  useEffect(() => {
    fixMetamaskConnection();
  }, []);

  const { t } = useTranslation();
  return (
    <StyledContainer>
      <StyledCardHeader>{t('signInView.cardTitle')}</StyledCardHeader>
      <StyledCardWrapper>
        <StyledCard>
          <StyledConnectButton onClick={() => authStore.signIn(WalletTypes.Metamask)}>
            <StyledIcon icon='metamask' /> {t('Sing in with MetaMask')}
          </StyledConnectButton>
        </StyledCard>
        <ErrorMessageView keys={[ErrorKeys.ConnectError, ErrorKeys.SignInError]} />
      </StyledCardWrapper>
    </StyledContainer>
  );
});

const StyledContainer = styled.div`
  padding: 74px 20px 120px 20px;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  @media ${device.mobileL} {
    padding: 24px 20px 24px 20px;
  }
`;

const StyledCardWrapper = styled.div`
  max-width: 610px;
  margin: 30px auto;

  @media ${device.mobileL} {
    max-width: 460px;
  }
`;

const StyledCard = styled.div`
  margin-bottom: 10px;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.lightBgColor};
`;

const StyledCardHeader = styled.div`
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  letter-spacing: 1px;
  color: ${({ theme }) => theme.textColorGold};
  text-transform: uppercase;
  padding: 32px 74px 20px 74px;

  @media ${device.mobileL} {
    text-align: center;
    padding: 12px 20px 4px 20px;
    font-size: ${({ theme }) => theme.fontSizeXL};
  }
`;

const StyledConnectButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.textColor};
  font-size: ${({ theme }) => theme.fontSizeXL};
  padding: 24px 76px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  transition: all 300ms ease;
  border-radius: 50px;
  box-shadow: 0px 4px 24px ${({ theme }) => theme.activeShadowColor};

  &:hover {
    background-color: ${({ theme }) => theme.activeBgColor};
    box-shadow: 0px 4px 24px ${({ theme }) => theme.activeShadowColor};
  }

  @media ${device.mobileL} {
    padding: 10px 36px;
    font-size: ${({ theme }) => theme.fontSizeM};
  }
`;

const StyledIcon = styled(IconImage)`
  width: 58px;
  height: 58px;
  margin-right: 30px;

  @media ${device.mobileL} {
    width: 34px;
    height: 34px;
    margin-right: 16px;
  }
`;
