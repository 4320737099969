import { NavLink } from 'react-router-dom';
import { device } from 'resources/theme';
import styled from 'styled-components';

import { pathTo } from 'utils';
import { IRoute } from 'types';

interface IProps {
  route: IRoute;
}

export const Breadcrumbs = ({ route }: IProps) => (
  <StyledContainer>
    {pathTo(route).map((crumb, index, breadcrumbs) => (
      <StyledItem style={{ color: 'white' }} key={index}>
        {index < breadcrumbs.length - 1 && (
          <>
            {crumb.isExternal ? (
              <a href={crumb.url}>{crumb.label}</a>
            ) : (
              <NavLink to={crumb.path}>{crumb.label}</NavLink>
            )}
            <span>|</span>
          </>
        )}
        <StyledGoldCrumb>{index === breadcrumbs.length - 1 && crumb.label}</StyledGoldCrumb>
      </StyledItem>
    ))}
  </StyledContainer>
);

const StyledContainer = styled.nav`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.lightTextColor};
  margin-top: 43px;
  font-family: 'Ginora Sans';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeM};
  line-height: 18px;
  @media ${device.mobileL} {
    margin-top: 0;
    margin-bottom: 16px;
  }
  div:first-child {
    border: none;
  }
  span {
    font-family: 'Ginora Sans', serif !important;
    display: inline-block;
    text-align: center;
    padding: 0 1.5px 3px 1.5px;
    font-size: 30px;
    line-height: 30px;
    font-weight: 100;
  }
  span:last-of-type {
    background: ${({ theme }) => theme.linearGradientGoldBreadcrumbs};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  div:last-child {
    background: ${({ theme }) => theme.linearGradientGoldBreadcrumbs};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`;

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Ginora Sans', serif !important;
  font-weight: 400;
  a {
    font-family: 'Ginora Sans', serif !important;
    font-weight: 400;
    text-transform: inherit;
    text-decoration: none;
    color: inherit;
  }
`;

const StyledGoldCrumb = styled.div`
  display: inline;
  background: ${({ theme }) => theme.linearGradientGold};
  font-family: 'Ginora Sans', serif !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
