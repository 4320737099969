import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { GoldenButton, Loader, Progress } from 'components';
import { ErrorKeys, ErrorReasons, RANDOM_NUMBER_GAS_LIMIT } from 'constants/index';
import { errorStore, marketplaceStore, walletStore } from 'store';
import { MarketplaceContract } from 'blockchain';
import { sleep } from 'utils';
import { ErrorMessageView } from 'views/common';
import { device } from 'resources/theme';
import { RANDOM_NUMBER_AMOUNT } from 'constants/common';

export const GenerateRandomNumbersView = observer(() => {
  const { t } = useTranslation('views/admin');
  const [isLoading, setLoading] = useState(false);
  const randomNumbersLength = marketplaceStore.drawData?.winnersLength || 0;

  const checkStepFinish = async (interval: number) => {
    let isFinished;
    try {
      const newLength = await MarketplaceContract.getWinnersLength();
      isFinished = newLength > randomNumbersLength;
    } catch (e) {
      isFinished = false;
    }

    if (!isFinished) {
      await sleep(interval);
      await checkStepFinish(interval);
    }
  };

  const handleStep = async () => {
    if (!walletStore.web3Provider) {
      return;
    }

    try {
      const contract = new MarketplaceContract(walletStore.web3Provider);
      const tx = await contract.requestRandomNumbers(RANDOM_NUMBER_GAS_LIMIT, RANDOM_NUMBER_AMOUNT);
      setLoading(true);
      await tx.wait();
      await checkStepFinish(30000);
      await marketplaceStore.loadDrawData();
      errorStore.removeError(ErrorKeys.DrawError);
    } catch (err) {
      errorStore.setError({ ...err, key: ErrorKeys.DrawError, reason: ErrorReasons.Unknown });
    }
    setLoading(false);
  };

  return (
    <>
      <StyledTitle>
        <Trans t={t} i18nKey='drawSteps.generateRandomNumbers.title' components={{ span: <span /> }} />
      </StyledTitle>
      <StyledDescription>{t(`drawSteps.generateRandomNumbers.description`)}</StyledDescription>
      <Progress percentage={randomNumbersLength} total={110} label={`${randomNumbersLength} / 110`} />
      <StyledButtonWrap>
        <StyledGoldenButton onClick={handleStep}>{t(`drawSteps.generateRandomNumbers.button`)}</StyledGoldenButton>
      </StyledButtonWrap>
      <ErrorMessageView keys={[ErrorKeys.DrawError]} />
      <Loader isLoading={isLoading} message={t(`drawSteps.generateRandomNumbers.loadingMessage`)} />
    </>
  );
});

const StyledTitle = styled.h2`
  font-family: 'Ginora Sans', serif;
  font-weight: 400;
  margin: 0;
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  letter-spacing: 1px;
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
  text-align: center;

  @media ${device.mobileL} {
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizeXL};
  }
`;

const StyledDescription = styled.p`
  margin: 20px 0;
  font-size: ${({ theme }) => theme.fontSizeM};
  font-weight: 300;
  color: ${({ theme }) => theme.lightTextColor};
  text-align: center;

  @media ${device.mobileL} {
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizeS};
  }
`;

const StyledButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const StyledGoldenButton = styled(GoldenButton)`
  padding: 21px 53px;
`;
