import { ITheme } from 'types';

export const mainTheme: ITheme = {
  // Color Scheme
  mainColor: '#f6b639',
  mainLogoColor: '#D3B96A',
  mainColorDark: '#d49620',
  mainLabelColor: '#D4AF37',
  errorColor: '#EB5757',
  bgColor: '#000000',
  lightBgColor: '#ffffff',
  lightBgColorHover: '#F2F2F2',
  lightBgColorDisabled: '#c4c4c4',
  activeBgColor: '#E3FAFF',
  textColor: '#000000',
  lightTextColor: '#ffffff',
  bgLightBlurColor: '#FFFFFF0D',
  copyrightTextColor: '#c4c4c4',
  inputBgColor: '#d6d6d6',
  textColorGold: '#D4AF37',
  activeShadowColor: 'rgba(58, 129, 191, 0.5)',
  walletBtnColor: '#FFF',
  tooltipBgColor: '#2a2f45',
  linearGradientGold: 'linear-gradient(120.43deg, #FFC149 25.88%, #FFFEA1 41.78%, #B48900 68.28%)',
  linearGradientGoldSharpAngle: 'linear-gradient(169.43deg, #FFC149 25.88%, #FFFEA1 41.78%, #B48900 68.28%)',
  linearGradientGoldTransparent:
    'linear-gradient(130.43deg, #FFC149 7.88%, #FFFEA1 15.78%, transparent 35%, transparent 70%, #B48900 90.28%)',
  linearGradientGoldBreadcrumbs: 'linear-gradient(120.43deg, #fefbc9 25.88%, #d4af37 68.28%)',
  //Font size
  pageTitlesFontSize: '40px',
  pageTitlesSmallerSize: '32px',
  headlineSmallerSize: '28px',
  buttonsFontSize: '20px',
  tagFontSize: '16px',
  errorFontSize: '14px',
  fontSizeXxxs: '8px',
  fontSizeXxs: '10px',
  fontSizeXs: '13px',
  fontSizeS: '14px',
  fontSizeM: '16px',
  fontSizeML: '18px',
  fontSizeL: '20px',
  fontSizeXL: '24px',
  fontSizeXXL: '36px',
  freeMintBgColor: '#19021D',
  freeMintBgGradient: 'linear-gradient(90deg, rgba(25,51,65,1) 0%, rgba(23,38,60,1) 35%, rgba(18,4,32,1) 100%)',
  freeMintModalCloseButtonGradient: 'linear-gradient(90deg, #C9CECE 0%, #F1F2F2 35%, #E0E2E3 100%)',
  freeMintGalleryImageBg: 'linear-gradient(198.44deg, rgba(255, 255, 255, 0.1) 14.49%, rgba(255, 255, 255, 0) 87.5%)',
};
