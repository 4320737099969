import React from 'react';
import styled from 'styled-components';
import { device } from 'resources/theme';
import { useTranslation } from 'react-i18next';
import { GoldenButton, WhitelistModal } from 'components';
import { openExternalLink } from '../../../../utils';
import { externalLinks } from '../../../../constants';

interface IProps {
  onClose?: () => void;
}

export const WhitelistUnsuccessfulModal = ({ onClose }: IProps) => {
  const { t } = useTranslation('views/cryptoHunters');

  return (
    <WhitelistModal onClose={onClose}>
      <StyledWrapper>
        <StyledTitleContainer>
          <StyledTitle>{t('checkWhitelist.unsuccessfulModal.primaryText')}</StyledTitle>
        </StyledTitleContainer>
        <StyledDescription>{t('checkWhitelist.unsuccessfulModal.secondaryText1')}</StyledDescription>
        <StyledDescription2>{t('checkWhitelist.unsuccessfulModal.secondaryText2')}</StyledDescription2>
        <StyledDiscordButton>
          <GoldenButton onClick={() => openExternalLink(externalLinks.discord)} icon='discord'>
            {t('checkWhitelist.sendSupportTicket')}
          </GoldenButton>
        </StyledDiscordButton>
      </StyledWrapper>
    </WhitelistModal>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.mobileL} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const StyledTitle = styled.div`
  font-family: 'Ginora Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  background: linear-gradient(120.43deg, #ffc149 25.88%, #fffea1 41.78%, #b48900 68.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media ${device.mobileL} {
    font-size: 16px;
    line-height: 19px;
  }
`;

const StyledDescription = styled.p`
  width: auto;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;

  @media ${device.mobileL} {
    font-size: 14px;
    line-height: 21px;
  }
`;

const StyledDescription2 = styled(StyledDescription)`
  margin-top: 0;
  margin-bottom: 24px;
`;

const StyledDiscordButton = styled.div``;
