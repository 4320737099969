export const getTimeLeft = (endDate: Date) => {
  const dateNow = new Date();
  let seconds = Math.floor((endDate.getTime() - dateNow.getTime()) / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};
