import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useAuthRequire } from 'hooks';
import { device } from 'resources/theme';
import { fixMetamaskConnection } from 'utils';
import { SignInCardView } from './SignInCardView';
import { CryptoHuntersRoutesEnum, FreeMintRoutesEnum } from '../../constants';
import styled from 'styled-components';

export const SignInView = observer(() => {
  const [redirectRoute, setRedirectRoute] = useState<FreeMintRoutesEnum | CryptoHuntersRoutesEnum | undefined>(
    undefined,
  );

  useAuthRequire({
    isAuthRequired: false,
    redirectRoute,
  });
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const redirectPage = queryParams.get('redirect');
    switch (`/${redirectPage}`) {
      case FreeMintRoutesEnum.freeMint:
        setRedirectRoute(FreeMintRoutesEnum.freeMint);
        break;
      case CryptoHuntersRoutesEnum.cryptoHuntersMint:
        setRedirectRoute(CryptoHuntersRoutesEnum.cryptoHuntersMint);
        break;
      case CryptoHuntersRoutesEnum.checkWhitelist:
        setRedirectRoute(CryptoHuntersRoutesEnum.checkWhitelist);
        break;
    }
    fixMetamaskConnection();
  }, []);

  const { t } = useTranslation();
  return (
    <StyledContainer>
      <StyledTitle>{t('signInView.title')}</StyledTitle>
      <StyledDescription>{t('signInView.description')}</StyledDescription>
      <SignInCardView />
    </StyledContainer>
  );
});

const StyledContainer = styled.div`
  padding: 74px 20px 120px 20px;
  max-width: 1000px;
  margin: 0 auto;

  @media ${device.mobileL} {
    padding: 24px 20px 24px 20px;
  }
`;

const StyledTitle = styled.h2`
  font-family: 'Ginora Sans', serif;
  font-weight: 400;
  margin: 0;
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  letter-spacing: 1px;
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
  text-align: center;

  @media ${device.mobileL} {
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizeXL};
  }
`;

const StyledDescription = styled.p`
  margin: 20px auto;
  font-size: ${({ theme }) => theme.fontSizeM};
  font-weight: 300;
  max-width: 800px;
  color: ${({ theme }) => theme.lightTextColor};
  text-align: center;

  @media ${device.mobileL} {
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizeS};
  }
`;
