import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { device } from 'resources/theme';
import { RoutesEnum } from 'constants/routes';

import styled from 'styled-components';

interface IProfileNavigation {
  disabled?: boolean;
}

export const ProfileNavigation = ({ disabled = false }: IProfileNavigation) => {
  const [t] = useTranslation('views/profile');

  return (
    <StyledNavContainer>
      <StyledNavLink to={RoutesEnum.profileNFTRelative}>
        <StyledButtonContainer>
          {' '}
          <StyledButton disabled={disabled}>
            <span>{t('prof.NFT')}</span>
          </StyledButton>
        </StyledButtonContainer>{' '}
      </StyledNavLink>
      <StyledNavLink to={RoutesEnum.profileDetailsRelative}>
        <StyledButtonContainer>
          {' '}
          <StyledButton disabled={disabled}>
            <span>{t('prof.profDetails')}</span>
          </StyledButton>
        </StyledButtonContainer>{' '}
      </StyledNavLink>
    </StyledNavContainer>
  );
};

const StyledButtonContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 586px;
  background: ${({ theme }) => theme.bgLightBlurColor};
  border-radius: 10px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 1px;
    top: 1px;
    bottom: 1px;
    right: 1px;
    border-radius: 10px;
    transition: all 0.5s;
    &:hover,
    &:active {
      display: none;
    }
  }
`;

const StyledButton = styled.button`
  position: relative;
  padding: 23px 0 22px;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  z-index: 1;
  background: ${({ theme }) => theme.bgLightBlurColor};
  outline: none;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  font-size: ${({ theme }) => theme.buttonsFontSize};
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 10px;
  border: none;
  span {
    background: ${({ theme }) => theme.linearGradientGold};
    font-family: 'Ginora Sans', serif;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }

  @media ${device.laptopM} {
    font-size: ${({ theme }) => theme.fontSizeM};
    padding: 17px 0;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0px;
  }

  &:hover {
    background: ${({ theme }) => theme.linearGradientGold};
    span {
      background: transparent;
      background-clip: unset;
      -webkit-background-clip: unset;
      -moz-background-clip: unset;
      text-fill-color: ${({ theme }) => theme.bgColor};
      -webkit-text-fill-color: ${({ theme }) => theme.bgColor};
      -moz-text-fill-color: ${({ theme }) => theme.bgColor};
    }
  }
  &:active {
    background: #c4c4c4;
  }
`;

const StyledNavLink = styled(NavLink)`
  display: block;
  width: 100%;
`;

const StyledNavContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
  max-width: 586px;
  width: 100%;
  background: ${({ theme }) => theme.bgLightBlurColor};
  border-radius: 10px;
  .active {
    ${StyledButton} {
      span {
        background: ${({ theme }) => theme.bgColor};
        border: none;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
      }
    }
    ${StyledButtonContainer} {
      background: ${({ theme }) => theme.linearGradientGold};
    }
  }
  @media ${device.laptop} {
    margin-bottom: 30px;
  }
`;
