import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useModal } from 'hooks';
import { authStore, cryptoHuntersStore, walletStore } from 'store';
import { CryptoHuntersContract } from 'blockchain';
import {
  CH_MAX_ALLOWED_NFT_PUBLIC_SALE,
  CH_MAX_ALLOWED_NFT_WHITELIST,
  CH_NFT_PRICE,
  CH_NFT_WHITELIST_PRICE,
} from 'constants/index';
import { fixMetamaskConnection } from 'utils';
import { GoldenButton, GoldenLabel, Loader, NFTCounter, PriceBlock, SuccessModal } from 'components';
import { PurchaseModalView } from 'views';
import { device } from 'resources/theme';
import goldLine from 'resources/images/goldLine.svg';
import defaultNftImage from 'resources/images/cryptoHunters/nft.png';
import { getChWhitelistSignature } from '../../../api';

interface IProps {
  nftLeft: number;
  onPurchase: () => void;
}

export const CHAuthorizedView = observer(({ nftLeft, onPurchase }: IProps) => {
  const { t } = useTranslation('views/purchase');
  const { t: chTranslation } = useTranslation('views/cryptoHunters');
  const { isOpen, open, close } = useModal();
  const { isOpen: isSuccessOpen, open: openSuccess, close: closeSuccess } = useModal();
  const [count, setCount] = useState<number>(1);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setError] = useState<string>('');
  const [maxNft, setMaxNft] = useState(0);
  const nftPrice = cryptoHuntersStore.isPublicSale ? CH_NFT_PRICE : CH_NFT_WHITELIST_PRICE;
  const totalPrice = parseFloat((count * nftPrice).toFixed(5));

  useEffect(() => {
    calculateMaxAllowedNft();
  }, [nftLeft, cryptoHuntersStore.isMintEnabled, cryptoHuntersStore.isPublicSale]);

  const calculateMaxAllowedNft = async () => {
    try {
      if (authStore.isAuthorized && walletStore.connectedAccount) {
        let maxAllowed = 0;
        if (cryptoHuntersStore.isMintEnabled) {
          if (cryptoHuntersStore.isPublicSale) {
            const publicSaleAlreadyMinted = await CryptoHuntersContract.publicSaleTokensMinted(
              walletStore.connectedAccount,
            );
            maxAllowed = CH_MAX_ALLOWED_NFT_PUBLIC_SALE - publicSaleAlreadyMinted;
          } else {
            const whitelistAlreadyMinted = await CryptoHuntersContract.whitelistTokensMinted(
              walletStore.connectedAccount,
            );
            maxAllowed = CH_MAX_ALLOWED_NFT_WHITELIST - whitelistAlreadyMinted;
          }
          const _maxNft = Math.min(nftLeft, maxAllowed);
          setMaxNft(_maxNft);
          if (_maxNft === 0) {
            setCount(0);
          }
        }
      }
    } catch (e) {
      console.log('Error while fetching CH max allowed tokens to mint.');
    }
  };

  const handleModalSubmit = async () => {
    if (!walletStore.web3Provider || !walletStore.connectedAccount) {
      return;
    }
    try {
      setLoading(true);
      const contract = new CryptoHuntersContract(walletStore.web3Provider);
      let signature = '0x';
      if (cryptoHuntersStore.isMintEnabled && !cryptoHuntersStore.isPublicSale) {
        const response = await getChWhitelistSignature({
          address: walletStore.connectedAccount,
        });
        signature = response.signature;
      }
      if (signature === '') {
        setError(chTranslation('checkWhitelist.notWhitelisted'));
        setLoading(false);
        return;
      }
      await contract.mint(count, signature, parseFloat((count * nftPrice).toFixed(5)));
      await onPurchase();
      await calculateMaxAllowedNft();
      close();
      openSuccess();
      setError('');
      setLoading(false);
    } catch ({ error }) {
      setLoading(false);
      if (error?.code === -32000) {
        setError(t('authorizedView.modal.errors.funds'));
      } else {
        setError(t('authorizedView.modal.errors.others'));
      }
    }
  };

  useEffect(() => {
    fixMetamaskConnection();
  }, []);

  return (
    <StyledContainer>
      <StyledGoldLineLeft>
        <img src={goldLine} />
      </StyledGoldLineLeft>
      <StyledGoldLineRight>
        <img src={goldLine} />
      </StyledGoldLineRight>
      <StyledImageContainer>
        <StyledInnerContainer>
          <StyledImage src={defaultNftImage} />
          <StyledPriceLabel>
            <StyledSpan>{t('authorizedView.price')}</StyledSpan>
            <StyledPriceSpan>{`${nftPrice} ETH`}</StyledPriceSpan>
          </StyledPriceLabel>
        </StyledInnerContainer>
      </StyledImageContainer>
      <StyledHeadline>{chTranslation('purchase.authorizedView.title')}</StyledHeadline>
      <StyledDescriptionContainer>
        <p>{chTranslation('purchase.authorizedView.description1')}</p>
        <p>{chTranslation('purchase.authorizedView.description2')}</p>
      </StyledDescriptionContainer>
      <StyledCounter>
        <StyledCounterHeader>{t('authorizedView.addNftsToCard')}</StyledCounterHeader>
        <NFTCounter min={Math.min(maxNft, 1)} max={maxNft} setCount={setCount} count={count} />
      </StyledCounter>
      <StyledCheckoutContainer>
        <StyledInfoBlock>
          {t('authorizedView.totalPrice')}
          <PriceBlock value={totalPrice} label={t('authorizedView.eth')} />
        </StyledInfoBlock>
        <StyledInfoBlock>
          {t('authorizedView.yourOrder')}
          <PriceBlock value={count} label={t('authorizedView.nft')} />
        </StyledInfoBlock>
      </StyledCheckoutContainer>
      <StyledGoldenButton>
        <GoldenButton
          onClick={() => {
            if (count > 0) {
              open();
            }
          }}
        >
          {t('authorizedView.checkout')}
        </GoldenButton>
      </StyledGoldenButton>
      <PurchaseModalView
        totalPrice={totalPrice}
        errorMessage={errorMessage}
        isOpen={isOpen}
        close={() => {
          setError('');
          close();
        }}
        onSubmit={handleModalSubmit}
        submitButtonText={t('authorizedView.modal.continue')}
        maxWidthForLaptop='595px'
      />
      <SuccessModal message={t('authorizedView.successMessage')} isOpen={isSuccessOpen} close={closeSuccess} />
      <Loader isLoading={isLoading} />
    </StyledContainer>
  );
});

const StyledContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    'a a b b'
    'a a c c'
    'a a d .'
    'a a e e'
    '. . f .';
  box-sizing: border-box;
  padding: 0 100px 100px;
  @media ${device.laptop} {
    padding: 0 85px 100px 0;
  }
  @media ${device.tablet} {
    padding: 0 0 60px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      'a'
      'b'
      'c'
      'd'
      'e'
      'f';
  }
`;

const StyledGoldLineLeft = styled.div`
  position: absolute;
  top: 1150px;
  left: 0;
  @media ${device.laptop} {
    top: 900px;
    img {
      width: 345px;
      height: 45px;
    }
  }
  @media ${device.tablet} {
    display: none;
  }
`;
const StyledGoldLineRight = styled.div`
  position: absolute;
  top: 1150px;
  right: 0;
  transform: rotate(180deg);
  transform: scale(-1, 1);
  @media ${device.laptop} {
    top: 900px;
    img {
      width: 345px;
      height: 45px;
    }
  }
  @media ${device.tablet} {
    display: none;
  }
`;

const StyledGoldenButton = styled.div`
  grid-area: f;
  margin-bottom: 40px;
  @media ${device.tablet} {
    justify-self: center;
    margin-bottom: 0;
  }
`;

const StyledCounter = styled.div`
  grid-area: d;
  @media ${device.tablet} {
    justify-self: center;
    margin-top: 40px;
    text-align: center;
    height: 75px;
  }
  @media ${device.mobileL} {
    margin-top: 20px;
  }
`;

const StyledImageContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  border-style: solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: ${({ theme }) => theme.linearGradientGoldTransparent};
  grid-area: a;
  @media ${device.tablet} {
    grid-area: c;
    order: 3;
    justify-self: center;
  }
`;

const StyledInnerContainer = styled.div`
  box-sizing: border-box;
  padding: 23px 26px;
  background: rgba(255, 255, 255, 0.05);
  @media ${device.mobileL} {
    padding: 14px 16px;
  }
`;

const StyledImage = styled.img`
  width: 420px;
  height: 420px;
  margin-bottom: 30px;
  @media ${device.laptop} {
    width: 320px;
    height: 320px;
    margin-bottom: 23px;
  }
  @media ${device.mobileL} {
    width: 261px;
    height: 262px;
    margin-bottom: 23px;
  }
`;
const StyledSpan = styled.span`
  font-family: 'Ginora Sans', serif !important;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizeL};
`;
const StyledPriceSpan = styled(StyledSpan)`
  font-size: ${({ theme }) => theme.fontSizeXL};
`;
const StyledPriceLabel = styled(GoldenLabel)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledHeadline = styled.h1`
  max-width: 70%;
  font-family: 'Ginora Sans', serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  line-height: 120%;
  text-transform: uppercase;
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  grid-area: b;
  @media ${device.laptop} {
    max-width: 120%;
    font-size: ${({ theme }) => theme.headlineSmallerSize};
  }
  @media ${device.tablet} {
    grid-area: a;
    order: 1;
    justify-self: center;
    text-align: center;
  }
  @media ${device.mobileL} {
    font-size: ${({ theme }) => theme.fontSizeL};
    margin-bottom: 8px;
  }
`;

const StyledDescriptionContainer = styled.div`
  max-width: 90%;
  color: ${({ theme }) => theme.lightTextColor};
  font-size: ${({ theme }) => theme.fontSizeM};
  font-weight: 300;
  grid-area: c;
  margin-bottom: 56px;
  @media ${device.laptop} {
    max-width: 120%;
    font-size: ${({ theme }) => theme.fontSizeS};
  }
  @media ${device.tablet} {
    grid-area: b;
    ordr: 2;
    justify-self: center;
    text-align: center;
    margin-top: 0;
    margin-bottom: 36px;
  }
  @media ${device.mobileL} {
    font-size: ${({ theme }) => theme.fontSizeS};
    line-height: 21px;
    margin-bottom: 16px;
  }
`;

const StyledCounterHeader = styled.span`
  font-family: 'Ginora Sans', serif !important;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizeXL};
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSizeL};
  }
  @media ${device.mobileL} {
    font-size: ${({ theme }) => theme.fontSizeM};
  }
`;

const StyledInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  font-family: 'Ginora Sans', serif !important;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeXL};
  color: ${({ theme }) => theme.lightTextColor};
  line-height: 28px;
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSizeML};
  }
  @media ${device.tablet} {
    text-align: center;
    width: 100%;
    margin-bottom: 44px;
    div{
      margin:auto;
  }
  @media ${device.mobileL} {
    margin-bottom: 24px;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSizeM};
`;

const StyledCheckoutContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  grid-area: e;
  @media ${device.laptop} {
    justify-content: normal;
    gap: 125px;
  }
  @media ${device.tablet} {
    flex-direction: column-reverse;
    justify-self: center;
    margin-top: 74px;
    margin-bottom: 0;
    gap: 0;
  }
  @media ${device.mobileL} {
    margin-top: 24px;
  }
`;
