import { IRoute } from 'types/common/routes';

export const combinePaths = (parent: string, child: string): string =>
  `${parent.replace(/\/$/, '')}/${child.replace(/^\//, '')}`;

export const buildPaths = (routes: IRoute[], parentPath = ''): IRoute[] =>
  routes.map((route: IRoute) => {
    const path = combinePaths(parentPath, route.path);

    return {
      ...route,
      path,
      ...(route.routes && { routes: buildPaths(route.routes, path) }),
    };
  });

export const setupParents = (routes: IRoute[], parentRoute: IRoute | null = null): IRoute[] =>
  routes.map((route) => {
    const withParent = {
      ...route,
      ...(parentRoute && { parent: parentRoute }),
    };

    return {
      ...withParent,
      ...(withParent.routes && {
        routes: setupParents(withParent.routes, withParent),
      }),
    };
  });

export const flattenRoutes = (routes: IRoute[]): any[] =>
  routes.map((route: IRoute) => [route.routes ? flattenRoutes(route.routes) : [], route]).flat(Infinity);

export const generateAppRoutes = (routes: IRoute[]): any[] => {
  return flattenRoutes(setupParents(buildPaths(routes)));
};

export const pathTo = (route: IRoute): any[] => {
  if (!route.parent) {
    return [route];
  }

  return [...pathTo(route.parent), route];
};
