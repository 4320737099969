import { API } from 'api/API';
import { appConfig } from 'config';
import { makeAutoObservable } from 'mobx';

import { ErrorKeys, ErrorReasons } from 'constants/index';
import { IEditUser, IUser, IUserResponseBody, IUserUpdateRequest, IUserUpdateResponse } from 'types';

import { errorStore } from './error';

const EMPTY_USER_DATA = { username: '', email: '', accountAddress: '' };

export class UserStore {
  user: IUser = EMPTY_USER_DATA;
  isWhitelisted = false;

  constructor() {
    makeAutoObservable(this);
  }

  async getUser() {
    try {
      const { data } = await API.get<IUserResponseBody>(`${appConfig.apiUrl}/user`);
      this.isWhitelisted = !!data.userLists?.length;
      this.setUser({ ...data });
      errorStore.removeError(ErrorKeys.ConnectError);
    } catch (err) {
      errorStore.setError({ ...err, key: ErrorKeys.ConnectError, reason: ErrorReasons.Unknown });
    }
  }

  async editUser({ email, username }: IEditUser) {
    try {
      await API.patch<IUserUpdateRequest, IUserUpdateResponse>(`${appConfig.apiUrl}/user`, {
        email,
        username,
      });
      this.setUser({ ...this.user, email, username });
      errorStore.removeError(ErrorKeys.ProfileEditError);
      return true;
    } catch (err) {
      errorStore.setError({ ...err, key: ErrorKeys.ProfileEditError, reason: ErrorReasons.InvalidEditProfile });
      return false;
    }
  }

  clearUser() {
    this.setUser(EMPTY_USER_DATA);
  }

  setUser(user: IUser) {
    this.user = user;
  }
}

export const userStore = new UserStore();
