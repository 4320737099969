export enum ErrorKeys {
  SignInError = 'SignInError',
  ConnectError = 'ConnectError',
  ProfileEditError = 'ProfileEditError',
  DrawError = 'DrawError',
  FreezeError = 'FreezeError',
  MintError = 'MintError',
  WhitelistError = 'WhitelistError',
}

export enum ErrorReasons {
  NoMetamask = 'NoMetamask',
  WalletRequestRejected = 'WalletRequestRejected',
  WalletNotConnected = 'WalletNotConnected',
  SignInFailed = 'SignInFailed',
  AmlFailed = 'AmlFailed',
  InvalidEditProfile = 'InvalidEditProfile',
  LinkAlreadyUsed = 'LinkAlreadyUsed',
  Unknown = 'Unknown',
}
