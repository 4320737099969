import { IRoute } from 'types';
import { Navigate } from 'react-router';

import { AdminRoutesEnum } from './routes';
import { AdminNFTBannerView, WinnersPageView, AdminDrawStepsView } from 'views/admin';

export const adminRoutesArray = (): IRoute[] => [
  {
    path: AdminRoutesEnum.admin,
    label: 'Admin',
    component: () => <Navigate to={AdminRoutesEnum.adminBanner} />,
  },
  {
    path: AdminRoutesEnum.adminBanner,
    label: 'Draw',
    component: () => <AdminNFTBannerView />,
  },
  {
    path: AdminRoutesEnum.adminWinners,
    label: 'Winners',
    component: () => <WinnersPageView />,
  },
  {
    path: AdminRoutesEnum.adminDraw,
    label: 'Draw Steps',
    component: () => <AdminDrawStepsView />,
  },
];
