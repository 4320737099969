import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { GoldenButton, Loader } from 'components';
import { ErrorKeys, ErrorReasons } from 'constants/index';
import { errorStore, marketplaceStore, walletStore } from 'store';
import { MarketplaceContract } from 'blockchain';
import { ErrorMessageView } from 'views/common';
import { device } from 'resources/theme';
import { appConfig } from 'config';

export const TopDrawView = observer(({ setWaitedTx }: any) => {
  const { t } = useTranslation('views/admin');
  const [isLoading, setLoading] = useState(false);

  const handleStep = async () => {
    if (!walletStore.web3Provider) {
      return;
    }

    try {
      const contract = new MarketplaceContract(walletStore.web3Provider);
      const tx = await contract.topDraw(appConfig.topMetadataUri);
      setLoading(true);
      const waitedTx = await tx.wait();
      setWaitedTx(waitedTx?.events);
      await marketplaceStore.loadDrawData();
      errorStore.removeError(ErrorKeys.DrawError);
    } catch (err) {
      errorStore.setError({ ...err, key: ErrorKeys.DrawError, reason: ErrorReasons.Unknown });
    }
    setLoading(false);
  };

  return (
    <>
      <StyledTitle>
        <Trans t={t} i18nKey='drawSteps.topDraw.title' components={{ span: <span /> }} />
      </StyledTitle>
      <StyledDescription>{t(`drawSteps.topDraw.description`)}</StyledDescription>
      <StyledButtonWrap>
        <StyledGoldenButton onClick={handleStep}>{t(`drawSteps.topDraw.button`)}</StyledGoldenButton>
      </StyledButtonWrap>
      <ErrorMessageView keys={[ErrorKeys.DrawError]} />
      <Loader isLoading={isLoading} message={t(`drawSteps.topDraw.loadingMessage`)} />
    </>
  );
});

const StyledTitle = styled.h2`
  font-family: 'Ginora Sans', serif;
  font-weight: 400;
  margin: 0;
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  letter-spacing: 1px;
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
  text-align: center;

  @media ${device.mobileL} {
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizeXL};
  }
`;

const StyledDescription = styled.p`
  margin: 20px 0;
  font-size: ${({ theme }) => theme.fontSizeM};
  font-weight: 300;
  color: ${({ theme }) => theme.lightTextColor};
  text-align: center;

  @media ${device.mobileL} {
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizeS};
  }
`;

const StyledButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const StyledGoldenButton = styled(GoldenButton)`
  padding: 21px 53px;
`;
