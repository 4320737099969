import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { device } from 'resources/theme';
import { authStore, cryptoHuntersStore, userStore } from 'store';
import { CH_NFTS_TOTAL_COUNT, RoutesEnum } from 'constants/index';
import { IconImage, Loader, WhiteBtn } from 'components';
import { formatAddress } from 'utils';
import { CryptoHuntersContract } from 'blockchain';
import { CHNotAuthorizedView } from './CHNotAuthorizedView';
import { CHAuthorizedView } from './CHAuthorizedView';
import { CHNoNftLeftView } from './CHNoNftLeftView';
import { CHMintDisabledView } from './CHMintDisabledView';

export const CHPurchaseView = observer(() => {
  const { t } = useTranslation('views/purchase');
  const navigate = useNavigate();
  const [nftLeft, setNftLeft] = useState<number | null>(null);

  const isLoading =
    nftLeft === null || cryptoHuntersStore.isMintEnabled === undefined || cryptoHuntersStore.isPublicSale === undefined;
  const canPurchase = cryptoHuntersStore.isMintEnabled && (nftLeft || 0) > 0;

  const getNftLeft = async () => {
    try {
      setNftLeft(CH_NFTS_TOTAL_COUNT - (await CryptoHuntersContract.getTotalSupply()));
    } catch (e) {
      // setNftLeft(null);
    }
  };

  useEffect(() => {
    if (authStore.isAuthorized) {
      userStore.getUser();
    }
  }, [authStore.isAuthorized]);

  useEffect(() => {
    getNftLeft();
    const intervalID = setInterval(() => getNftLeft(), 60000);
    return () => clearInterval(intervalID);
  }, []);

  const viewContent = useMemo(() => {
    if (!cryptoHuntersStore.isMintEnabled) {
      return <CHMintDisabledView />;
    }

    if (!authStore.isAuthorized) {
      return <CHNotAuthorizedView />;
    }

    if (canPurchase && nftLeft !== null && nftLeft > 0) {
      return (
        <CHAuthorizedView
          nftLeft={nftLeft}
          onPurchase={async () => {
            await getNftLeft();
          }}
        />
      );
    }

    return <CHNoNftLeftView />;
  }, [authStore.isAuthorized, canPurchase, nftLeft, cryptoHuntersStore.isMintEnabled]);

  return (
    <StyledWrapper>
      <Loader isLoading={isLoading}>
        <StyledContainer>
          <StyledHeader>
            <StyledHeaderText>
              {canPurchase && (
                <>
                  <StyledNumber>
                    {typeof nftLeft === 'number' ? nftLeft.toLocaleString().replace(',', ' ') : '...'}
                  </StyledNumber>
                  <span>{t('header.nftLeft')}</span>
                </>
              )}
            </StyledHeaderText>
            {cryptoHuntersStore.isMintEnabled ? (
              authStore.isAuthorized ? (
                <StyledWalletBtn onClick={() => navigate(RoutesEnum.profileDetails)}>
                  <StyledWallet>
                    <IconImage icon='wallet' />
                  </StyledWallet>
                  <StyledUsername>{userStore.user?.username || formatAddress(authStore.accountAddress)}</StyledUsername>
                </StyledWalletBtn>
              ) : (
                <StyledWalletBtn onClick={() => navigate(RoutesEnum.signIn)}>
                  {t('header.connectWallet')}
                  <StyledWallet>
                    <IconImage icon='wallet' />
                  </StyledWallet>
                </StyledWalletBtn>
              )
            ) : null}
          </StyledHeader>
        </StyledContainer>
        {viewContent}
      </Loader>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  min-height: 50vh;
`;

const StyledContainer = styled.div`
  padding-top: 24px;
  width: 100%;
  @media ${device.laptop} {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  @media ${device.mobileL} {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-areas:
    '. a b'
    '. a b';
  align-items: center;
  margin-bottom: 40px;
  @media ${device.laptop} {
    margin-bottom: 0;
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
  }
  @media ${device.mobileL} {
    margin-bottom: 24px;
    padding: 0px;
  }
`;

const StyledNumber = styled.span`
  margin-right: 13px;
  padding-bottom: 25px;
  font-size: 96px;
  line-height: 120%;
  font-family: 'Ginora Sans' !important;
  white-space: nowrap;
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.pageTitlesFontSize};
    line-height: 160%;
    margin-right: 10px;
    padding-bottom: 5px;
  }
  @media ${device.mobileL} {
    font-size: ${({ theme }) => theme.fontSizeXXL};
    line-height: 140%;
    margin-right: 10px;
    padding-bottom: 10px;
  }
`;

const StyledWalletBtn = styled(WhiteBtn)`
  grid-area: b;
  justify-self: end;
  grid-gap: 13px;
  @media ${device.mobileL} {
    margin-top: 18px;
  }
`;

const StyledHeaderText = styled.div`
  grid-area: a;
  justify-self: center;
  display: flex;
  align-items: center;
  height: 139px;
  font-family: 'Ginora Sans' !important;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  line-height: 120%;
  text-transform: uppercase;
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  span {
    background: ${({ theme }) => theme.linearGradientGold};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.pageTitlesSmallerSize};
    height: 85px;
  }
  @media ${device.mobileL} {
    height: fit-content;
    font-size: ${({ theme }) => theme.fontSizeXL};
    line-height: 140%;
  }
`;

const StyledWallet = styled.div`
  height: 26px;
  width: 26px;
  position: relative;
  img {
    @media ${device.laptop} {
      width: 18px !important;
      height: 18px !important;
    }
  }
`;

const StyledUsername = styled.div`
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
