import { Navigate } from 'react-router-dom';

import { IRoute } from 'types';
import { SignInView, UserProfileView } from 'views';
import { appConfig } from 'config';

import { CryptoHuntersRoutesEnum, FreeMintRoutesEnum, RoutesEnum } from './routes';
import { ErrorView } from 'views/error';
import { FreeMintView } from '../views/';
import { CheckWhitelistView } from '../views/cryptoHunters/';
import { CHPurchaseView } from '../views/cryptoHunters/purchase';

export const routesArray = (): IRoute[] => [
  {
    path: RoutesEnum.home,
    url: appConfig.homePageUrl,
    label: 'Main Page',
    isExternal: true,
    component: () => <Navigate to={CryptoHuntersRoutesEnum.cryptoHuntersMint} />, // TODO change to RoutesEnum.mint
    routes: [
      // TODO enable
      // {
      //   path: RoutesEnum.mint,
      //   label: 'Mint',
      //   component: () => <PurchaseView />,
      // },
      {
        path: RoutesEnum.profile,
        label: 'Profile Page',
        component: UserProfileView,
      },
      {
        path: RoutesEnum.error,
        label: 'Error',
        component: () => <ErrorView />,
      },
      // {
      //   path: RoutesEnum.presale,
      //   label: 'Presale',
      //   component: () => <AddToWhitelistView />,
      // },
      {
        path: CryptoHuntersRoutesEnum.checkWhitelist,
        label: 'Whitelist Check',
        component: () => <CheckWhitelistView />,
      },
      {
        path: CryptoHuntersRoutesEnum.cryptoHuntersMint,
        label: 'Crypto Hunters',
        component: () => <CHPurchaseView />,
      },
    ],
  },
  {
    path: '/sign-in',
    label: 'Sign In',
    component: SignInView,
  },
];

export const freeMintRoutesArray = (): IRoute[] => [
  {
    path: RoutesEnum.home,
    url: appConfig.homePageUrl,
    label: 'Main Page',
    isExternal: true,
    component: () => <Navigate to={CryptoHuntersRoutesEnum.cryptoHuntersMint} />, // TODO change to RoutesEnum.mint
    routes: [
      {
        path: FreeMintRoutesEnum.freeMint,
        label: 'Free Mint',
        component: () => <FreeMintView />,
      },
    ],
  },
];
