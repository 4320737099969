import { ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  children: ReactNode;
  onClick?: () => void;
}
export const SquareIconButton = ({ children, onClick, ...props }: IProps) => {
  return (
    <StyledButton onClick={onClick} {...props}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  z-index: 0;
  width: 40px;
  height: 40px;
  position: relative;
  background: ${({ theme }) => theme.linearGradientGold};
  outline: none;
  font-family: 'Ginora Sans', serif !important;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeXL};
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  color: ${({ theme }) => theme.bgColor};
  background-clip: unset;
  -webkit-background-clip: unset;
  -moz-background-clip: unset;
  text-fill-color: ${({ theme }) => theme.bgColor};
  -webkit-text-fill-color: ${({ theme }) => theme.bgColor};
  -moz-text-fill-color: ${({ theme }) => theme.bgColor};

  img {
    filter: brightness(0);
  }

  &:hover {
    color: ${({ theme }) => theme.bgColor};
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

    img {
      filter: unset;
    }
  }
`;
