import styled from 'styled-components';
import bgMain from 'resources/images/freeMint/main/mainBg.png';
import bgMainMobile from 'resources/images/freeMint/main/mainBgMobile.png';
import bgMom from 'resources/images/freeMint/main/mainBgMom.png';
import bgMomMobile from 'resources/images/freeMint/main/mainBgMomMobile.png';
import bgBaby from 'resources/images/freeMint/main/mainBgBaby.png';
import bgCollection from 'resources/images/freeMint/main/mainBgCollection.svg';
import bgCollectionMobile from 'resources/images/freeMint/main/mainBgCollectionMobile.svg';
import { IconImage } from '../../../components';
import { device } from '../../../resources/theme';
import { useTranslation } from 'react-i18next';

export const FreeMintMainSectionView = () => {
  const { t } = useTranslation('views/freeMint');

  return (
    <StyledWrapper>
      <StyledImageBaby />
      <StyledImageMom />
      <StyledTextsContainer>
        <StyledTextsLeftContainer />
        <StyledTextsRightContainer>
          <StyledTitle1>{t('main.title1')}</StyledTitle1>
          <StyledTitle2>{t('main.title2')}</StyledTitle2>
          <StyledText>{t('main.description')}</StyledText>
        </StyledTextsRightContainer>
      </StyledTextsContainer>
      <StyledBgText src={bgCollection} alt='' />
      <StyledBgTextMobile src={bgCollectionMobile} alt='' />
      <StyledPriceAndDateContainer>
        <div>
          <StyledPriceAndDateLabel>{t('main.mintDateLabel')}</StyledPriceAndDateLabel>
          <StyledPriceAndDateValue>{t('main.mintDateValue')}</StyledPriceAndDateValue>
        </div>
        <div>
          <StyledPriceAndDateLabel>{t('main.mintPriceLabel')}</StyledPriceAndDateLabel>
          <StyledPriceAndDateValue>{t('main.mintPriceValue')}</StyledPriceAndDateValue>
        </div>
      </StyledPriceAndDateContainer>
      <StyledSeparatingLine icon='separatingLine' />
    </StyledWrapper>
  );
};

const StyledSeparatingLine = styled(IconImage)`
  width: 100%;
  position: absolute;
  bottom: -12px;
  left: 0;
  right: 0;
  margin: auto;
`;

const StyledWrapper = styled.section`
  background-image: url(${bgMain});
  background-position: center center;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  text-align: center;
  height: 717px;
  @media ${device.mobileL} {
    margin-top: -91px;
    background-image: url(${bgMainMobile});
    background-position: center center;
    background-size: cover;
  }
`;

const StyledTextsContainer = styled.div`
  margin-right: auto;
  padding-top: 179px;
  display: flex;
  @media ${device.tablet} {
    flex-direction: column;
    padding-top: 123px;
  }
`;

const StyledTextsLeftContainer = styled.div`
  width: 400px;
  @media ${device.laptop} {
    width: 250px;
  }
  @media ${device.tablet} {
    display: none;
  }
`;

const StyledTextsRightContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  @media ${device.tablet} {
    align-items: flex-end;
    padding-right: 23px;
  }
`;

const StyledTitle1 = styled.h1`
  font-family: 'Syne Extra';
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 84px;
  text-transform: uppercase;
  background: linear-gradient(120.43deg, #ffc149 25.88%, #fffea1 41.78%, #b48900 68.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  z-index: 2;
  @media ${device.laptop} {
    margin-left: 50px;
    font-size: 50px;
    line-height: 60px;
  }
  @media ${device.tablet} {
    margin-left: 50px;
    font-size: 64px;
    line-height: 72px;
  }
  @media ${device.mobileL} {
    font-size: 25px;
    line-height: 30px;
  }
`;

const StyledTitle2 = styled.h1`
  font-family: 'Syne Extra';
  font-style: normal;
  font-weight: 800;
  font-size: 70px;
  line-height: 84px;
  text-transform: uppercase;
  background: linear-gradient(120.43deg, #ffc149 25.88%, #fffea1 41.78%, #b48900 68.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  z-index: 2;
  @media ${device.laptop} {
    margin-left: 100px;
    font-size: 50px;
    line-height: 60px;
  }
  @media ${device.tablet} {
    margin-left: 100px;
    font-size: 55px;
    line-height: 60px;
  }
  @media ${device.mobileL} {
    margin-left: 0;
    font-size: 25px;
    line-height: 30px;
  }
`;

const StyledText = styled.div`
  margin-right: 245px;
  text-align: left;
  max-width: 473px;
  font-family: 'Montserrat Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  z-index: 2;
  padding-top: 41px;
  @media ${device.laptop} {
    margin-left: 180px;
    margin-right: auto;
  }
  @media ${device.mobileL} {
    text-align: right;
    max-width: 320px;
    padding-top: 12px;
    margin-right: 0;
    margin-left: 0;
  }
`;

const StyledImageMom = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  background-image: url(${bgMom})};
  z-index: 1;
  @media ${device.mobileL} {
    background-image: url(${bgMomMobile})};
    bottom: -1px;
  }
`;

const StyledImageBaby = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: 345px bottom;
  background-image: url(${bgBaby})};
  z-index: 1;
  @media ${device.mobileL} {
  background-position: 115px bottom;
  background-size: 226px auto;
  }
`;

const StyledBgText = styled.img`
  display: block;
  position: absolute;
  bottom: 180px;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  z-index: 0;
  @media ${device.mobileL} {
    display: none;
    bottom: 230px;
  }
`;

const StyledBgTextMobile = styled.img`
  display: none;
  position: absolute;
  bottom: 180px;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  z-index: 0;
  @media ${device.mobileL} {
    display: block;
    bottom: 230px;
  }
`;

const StyledPriceAndDateContainer = styled.div`
  position: absolute;
  right: 66px;
  bottom: 150px;
  text-align: right;
  color: white;
  font-size: 30px;
  z-index: 2;
  @media ${device.mobileL} {
    right: 0;
    bottom: 280px;
    max-width: 250px;
    font-size: 19.1253px;
    line-height: 23px;
    padding-right: 23px;
  }
`;

const StyledPriceAndDateLabel = styled.span`
  font-family: 'Syne Extra';
  font-style: normal;
  font-weight: 800;
  font-size: 19.1253px;
  line-height: 23px;
  text-transform: uppercase;
  background: linear-gradient(120.43deg, #ffc149 25.88%, #fffea1 41.78%, #b48900 68.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const StyledPriceAndDateValue = styled.span`
  font-family: 'Syne Extra';
  font-style: normal;
  font-weight: 800;
  font-size: 19.1253px;
  line-height: 23px;
  text-transform: uppercase;
  background: linear-gradient(147.12deg, #33e7c6 5.93%, rgba(139, 248, 255, 0.7) 83.45%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
