import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { addUserToWhitelist } from 'api';
import { authStore, errorStore, userStore } from 'store';
import { ErrorKeys, ErrorReasons, RoutesEnum } from 'constants/index';
import { fixMetamaskConnection } from 'utils';
import { Loader } from 'components';
import { ErrorMessageView, SignInCardView } from 'views';
import { device } from 'resources/theme';

export const AddToWhitelistView = observer(() => {
  const { t } = useTranslation('views/whitelist');
  const [searchParams] = useSearchParams();
  const whitelistKey = searchParams.get('accessKey');
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const addToWhitelist = async () => {
    setLoading(true);
    try {
      await addUserToWhitelist({ invitationLink: String(whitelistKey) });
      await userStore.getUser();
    } catch (err) {
      errorStore.setError({
        ...err,
        key: ErrorKeys.WhitelistError,
        reason: err.response?.data?.includes('already used') ? ErrorReasons.LinkAlreadyUsed : ErrorReasons.Unknown,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!whitelistKey) {
      navigate(RoutesEnum.home);
    }
  }, [whitelistKey]);

  useEffect(() => {
    fixMetamaskConnection();
  }, []);

  useEffect(() => {
    if (authStore.isAuthorized) {
      userStore.getUser();
    }
  }, [authStore.isAuthorized]);

  useEffect(() => {
    if (userStore.isWhitelisted) {
      navigate(RoutesEnum.mint);
    } else if (userStore.user.accountAddress) {
      addToWhitelist();
    }
  }, [userStore.user.accountAddress, userStore.isWhitelisted]);

  return (
    <StyledContainer>
      <StyledTitle>{t('title')}</StyledTitle>
      {!authStore.isAuthorized && (
        <>
          <StyledDescription>{t('signInDescription')}</StyledDescription>
          <SignInCardView />
        </>
      )}
      <StyledErrorMessage keys={[ErrorKeys.WhitelistError]} />
      <Loader isLoading={isLoading} />
    </StyledContainer>
  );
});

const StyledContainer = styled.div`
  padding: 74px 20px 120px 20px;
  min-height: 400px;
  max-width: 1000px;
  text-align: center;
  margin: 0 auto;

  @media ${device.mobileL} {
    padding: 24px 20px 24px 20px;
  }
`;

const StyledTitle = styled.h2`
  font-family: 'Ginora Sans', serif;
  font-weight: 400;
  margin: 0;
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  letter-spacing: 1px;
  background: ${({ theme }) => theme.linearGradientGold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
  text-align: center;

  @media ${device.mobileL} {
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizeXL};
  }
`;

const StyledDescription = styled.p`
  margin: 20px 0;
  font-size: ${({ theme }) => theme.fontSizeM};
  font-weight: 300;
  color: ${({ theme }) => theme.lightTextColor};
  text-align: center;

  @media ${device.mobileL} {
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizeS};
  }
`;

const StyledErrorMessage = styled(ErrorMessageView)`
  margin-top: 20px;
`;
