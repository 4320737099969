import { device } from 'resources/theme';
import styled from 'styled-components';

interface IProps {
  value: string | number;
  label: string;
}
export const PriceBlock = ({ value, label, ...props }: IProps) => {
  return (
    <StyledContainer {...props}>
      <StyledValue>{value}</StyledValue>
      <StyledLabel>{label}</StyledLabel>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: fit-content;
  font-size: ${({ theme }) => theme.pageTitlesFontSize};
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSizeXL};
  }
  @media ${device.mobileL} {
    font-size: ${({ theme }) => theme.fontSizeM};
  }
`;

const StyledValue = styled.span`
  width: auto;
  font-size: inherit;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
  background: ${({ theme }) => theme.linearGradientGold};
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  @media ${device.mobileL} {
    font-weight: 500;
  }
`;

const StyledLabel = styled(StyledValue)`
  font-family: 'Ginora Sans', serif !important;
  font-weight: 500;
  margin-left: 0.25em;
  @media ${device.mobileL} {
    font-weight: 500;
  }
`;
