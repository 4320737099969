import { useState } from 'react';
import styled from 'styled-components';

import { openExternalLink } from 'utils';
import { Icons, ISocialLinkItem } from 'types';

import { IconImage } from './IconImage';
import { device } from 'resources/theme';

interface IProps {
  item: ISocialLinkItem;
}
export const IconSocialLink = ({ item }: IProps) => {
  const [icon, setIcon] = useState<Icons>(item.basicIcon);

  return (
    <StyledLink
      onClick={() => openExternalLink(item.link)}
      onMouseOver={() => setIcon(item.hoveredIcon)}
      onMouseOut={() => setIcon(item.basicIcon)}
    >
      <StyledSocialIconImage icon={icon} />
    </StyledLink>
  );
};

const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

const StyledSocialIconImage = styled(IconImage)`
  height: 38px;
  width: 38px;
  @media ${device.mobileL} {
    height: 30px;
    weight: 30px;
  }
`;
