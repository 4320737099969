import { ReactNode } from 'react';
import styled from 'styled-components';
import { device } from 'resources/theme';

interface IProps {
  children: ReactNode;
  onClick?: () => void;
}

export const WhiteBtn = ({ children, onClick, ...props }: IProps) => {
  return (
    <StyledButton onClick={onClick} {...props}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  height: 52px;
  font-family: 'Ginora Sans' !important;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.buttonsFontSize};
  line-height: 23px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.walletBtnColor};
  outline: none;
  cursor: pointer;
  padding-right: 20px;
  padding-left: 23px;
  background: inherit;
  border: 1px solid #fff;
  border-radius: 5px;
  @media ${device.laptopL} {
    font-size: ${({ theme }) => theme.fontSizeS};
    padding: 10px 20px !important;
    line-height: 16px;
    height: 42px;
  }
  &:hover,
  &:active {
    filter: brightness(0) invert(0.65);
  }
`;
