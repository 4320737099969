import React, { ReactNode, useState } from 'react';
import { device } from 'resources/theme';
import styled from 'styled-components';

interface IProps {
  children: ReactNode;
  text?: string;
}

export const Tooltip = ({ children, text }: IProps) => {
  const [active, setActive] = useState(false);
  return (
    <div
      onMouseEnter={() => {
        setActive(true);
      }}
      onMouseLeave={() => {
        setActive(false);
      }}
    >
      {children}
      <StyledContainer>{active && <StyledText>{text}</StyledText>}</StyledContainer>
    </div>
  );
};

const StyledContainer = styled.div`
  position: relative;
`;

const StyledText = styled.div`
  position: absolute;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  max-width: 295px;
  top: 10px;
  background: #2a2f45;
  box-shadow: 0px 0px 5.4876px rgba(55, 65, 81, 0.06), 0px 2.7438px 8.23141px rgba(55, 65, 81, 0.1);
  border-radius: 5.4876px;
  padding: 5px 11px;
  left: 30px;
  text-align: left;
  @media ${device.mobileL} {
    display: none;
  }}
`;
