import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { GoldenButton, IconImage } from 'components';
import { device } from 'resources/theme';
import { openExternalLink } from 'utils';
import { appConfig } from 'config';

export const ErrorView = () => {
  const { t } = useTranslation('views/error');

  return (
    <StyledContainer>
      <StyledCardWrapper>
        <StyledCardTextContainer>
          <StyledIconImage icon='error404' />
          <StyledErrorMessage>{t('oops')}</StyledErrorMessage>
          <StyledAdditionalText>{t('sorry')}</StyledAdditionalText>
          <StyledGoldenButton onClick={() => openExternalLink(appConfig.homePageUrl, '_self')}>
            <span>{t('home')}</span>
          </StyledGoldenButton>
        </StyledCardTextContainer>
      </StyledCardWrapper>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin: 27px 0 77px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media ${device.mobileL} {
    margin: 60px 0;
  }
`;

const StyledCardWrapper = styled.div`
  position: relative;
  max-width: 788px;
  width: 100%;
  margin: 0 20px;
  background: transparent;
  background-clip: padding-box;
  border-width: 3.96px;
  box-sizing: border-box;
  :before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    border-radius: 23.76px;
    padding: 3.96px;
    background: linear-gradient(
      122.47deg,
      #ffc149 2.47%,
      #fffea1 11.82%,
      rgba(255, 254, 161, 0) 27.72%,
      rgba(255, 254, 161, 0) 74.66%,
      #b48900 104.8%
    );
    filter: drop-shadow(0px 3.16751px 15.8376px rgba(0, 0, 0, 0.4));
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  @media ${device.mobileL} {
    margin: 0 24px;
    max-width: 327px;
  }
`;

const StyledIconImage = styled(IconImage)`
  width: 401px;
  height: 202px;

  @media ${device.mobileL} {
    width: 172px;
    height: 86px;
  }
`;

const StyledCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 55.5px 0;
  max-width: 401px;

  @media ${device.mobileL} {
    padding: 27px 0;
  }
`;

const StyledErrorMessage = styled.div`
  margin: 24px 0;
  font-family: Ginora Sans;
  font-size: 64px;
  font-weight: 600;
  line-height: 77px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.textColorGold};

  @media ${device.mobileL} {
    margin: 16px 0;
    font-weight: 400;
    text-align: center;
    line-height: 33.6px;
    font-size: ${({ theme }) => theme.fontSizeXL};
  }
`;

const StyledAdditionalText = styled.div`
  margin-bottom: 32px;
  font-family: Poppins;
  font-size: ${({ theme }) => theme.fontSizeM};
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: ${({ theme }) => theme.lightBgColor};

  @media ${device.mobileL} {
    margin-bottom: 16px;
    font-size: ${({ theme }) => theme.fontSizeS};
    line-height: 21px;
  }
`;

const StyledGoldenButton = styled(GoldenButton)`
  &:span {
    padding: 10px 20px;
    text-transform: capitalize;

    @media ${device.mobileL} {
      text-transform: capitalize;
    }
  }
`;
