import MarketplaceABI from './abi/Marketplace.json';
import NftABI from './abi/NFT.json';
import FreeMintABI from './abi/FreeMint.json';
import CryptoHuntersABI from './abi/CryptoHunters.json';

export const appConfig = {
  env: process.env.NODE_ENV,
  networkId: Number(process.env.REACT_APP_NETWORK_ID),
  infuraId: process.env.REACT_APP_INFURA_ID,
  apiUrl: process.env.REACT_APP_API_URL,
  presaleTs: Number(process.env.REACT_APP_PRE1658696400000IMESTAMP),
  saleTs: Number(process.env.REACT_APP_1658696400000IMESTAMP),
  homePageUrl: String(process.env.REACT_APP_HOME_URL),
  topMetadataUri: String(process.env.REACT_APP_TOP_METADATA_URI),
  bronzeMetadataUri: String(process.env.REACT_APP_BRONZE_METADATA_URI),
  contracts: {
    Marketplace: {
      abi: MarketplaceABI,
      address: String(process.env.REACT_APP_CONTRACT_MARKETPLACE_ADDRESS),
    },
    NFT: {
      abi: NftABI,
      address: String(process.env.REACT_APP_CONTRACT_NFT_ADDRESS),
    },
    FreeMint: {
      abi: FreeMintABI,
      address: String(process.env.REACT_APP_CONTRACT_FREE_MINT_ADDRESS),
    },
  },
  cryptoHunters: {
    pageUrl: process.env.REACT_APP_CRYPTO_HUNTERS_PAGE_URL,
    contracts: {
      CryptoHunters: {
        abi: CryptoHuntersABI,
        address: String(process.env.REACT_APP_CONTRACT_CRYPTO_HUNTERS_ADDRESS),
      },
    },
  },
};
