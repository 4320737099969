import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Loader, Modal } from 'components';
import { device } from 'resources/theme';
import { errorStore, walletStore } from '../../store';
import { MarketplaceContract } from '../../blockchain';
import { ErrorKeys, ErrorReasons } from '../../constants';

interface IProps {
  isOpen: boolean;
  close: () => void;
  NFTRemain: number;
  onMint: () => void;
}

export const MintModalView = ({ isOpen, close, NFTRemain, onMint }: IProps) => {
  const { t } = useTranslation('views/admin');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>('');
  const [errorMessage, setError] = useState<string>('');
  const maxAmount = NFTRemain < 100 ? NFTRemain : 100;

  const mint = async () => {
    if (!walletStore.web3Provider) {
      return;
    }

    setLoading(true);
    try {
      const contract = new MarketplaceContract(walletStore.web3Provider);
      const tx = await contract.ownerPurchase(Number(amount), 250000);
      await tx.wait();
      setLoading(false);
      onMint();
      close();
    } catch (err) {
      errorStore.setError({ ...err, key: ErrorKeys.MintError, reason: ErrorReasons.Unknown });
      setLoading(false);
    }
  };

  const handleSetAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = Number(e.target.value);

    if (amount <= 0) {
      setError(t('mint.minError'));
    } else if (amount > maxAmount) {
      setError(t('mint.maxError', { max: maxAmount }));
    } else {
      setError('');
    }

    setAmount(e.target.value);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        close={close}
        onSubmit={mint}
        submitButtonText={t('mint.submit')}
        loading={isLoading || !!errorMessage || !amount}
        maxWidthForLaptop='620px'
      >
        <StyledDescription>{t('mint.description')}</StyledDescription>
        <StyledContainer>
          <StyledInput type='number' placeholder={t('mint.amount')} value={amount} onChange={handleSetAmount} />
          <StyledError>{errorMessage}</StyledError>
        </StyledContainer>
      </Modal>
      <Loader isLoading={isLoading} />
    </>
  );
};

const StyledContainer = styled.div`
  margin-bottom: 40px;
`;

const StyledDescription = styled.p`
  width: auto;
  font-size: ${({ theme }) => theme.fontSizeML};
  font-weight: 300;
  color: ${({ theme }) => theme.lightTextColor};
  text-align: center;
  margin-bottom: 43px;
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSizeXs};
  }
  @media ${device.mobileL} {
    margin-bottom: 17px;
  }
`;

const StyledInput = styled.input<{ isError?: boolean }>`
  width: 100%;
  max-width: 400px;
  padding: 16px 32px;
  background: #fff3f3;
  border: ${({ isError, theme }) => (isError ? `1px solid ${theme.errorColor}` : 'unset')};
  border-radius: 10px;
  outline: none;
  font-family: 'Poppins', serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeM};
  line-height: 24px;
  box-sizing: border-box;

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media ${device.laptop} {
    padding: 12px 52px 12px 17px;
    font-size: ${({ theme }) => theme.fontSizeS};
    line-height: 150%;
  }
`;

const StyledError = styled.div`
  font-family: 'Poppins', serif !important;
  font-style: normal;
  font-weight: 400;
  max-width: 400px;
  margin: auto;
  margin-top: 4px;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizeXs};
  line-height: 20px;
  height: 24px;
  color: ${({ theme }) => theme.errorColor};
`;
