import styled from 'styled-components';
import { ISocialLinkItem } from 'types';
import { IconSocialLink } from 'components/icons/IconSocialLink';
import { device } from 'resources/theme';

interface IProps {
  items: ISocialLinkItem[];
  isHeader?: boolean;
}

export const SocialLinks = ({ items, isHeader = false }: IProps) => {
  return (
    <StyledSocialContainer isHeader={isHeader}>
      {items.map((item) => {
        return <IconSocialLink item={item} key={`${item.link}${item.name}`} />;
      })}
    </StyledSocialContainer>
  );
};

const StyledSocialContainer = styled.div<{ isHeader: boolean }>`
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  margin-top: 15px;
  @media ${device.mobileL} {
    justify-content: center;
  }
  @media ${device.laptop} {
    justify-content: ${({ isHeader }) => (isHeader ? 'center' : 'flex-end')};
  }
`;
