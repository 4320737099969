import { ethers, providers } from 'ethers';

import { appConfig } from 'config';
import { IUserVrsResponseBody } from 'types';

import { rpcProvider } from '../rpcProvider';

export class MarketplaceContract {
  static publicContract = new ethers.Contract(
    appConfig.contracts.Marketplace.address,
    appConfig.contracts.Marketplace.abi,
    rpcProvider,
  );
  contract: ethers.Contract;

  constructor(provider: providers.Web3Provider) {
    this.contract = new ethers.Contract(
      appConfig.contracts.Marketplace.address,
      appConfig.contracts.Marketplace.abi,
      provider.getSigner(),
    );
  }

  static async getSaleTs(): Promise<number> {
    const presaleTimer = await MarketplaceContract.publicContract.presaleTimer();
    return presaleTimer.toNumber() * 1000;
  }

  static isAdmin = async (hash: string, accountAddress: string): Promise<boolean> =>
    await MarketplaceContract.publicContract.hasRole(hash, accountAddress);

  // static async getRandomNumbersLength(): Promise<number> {
  //   const randomNumbersLength = await MarketplaceContract.publicContract.getRandomNumbersLength();
  //   return randomNumbersLength.toNumber();
  // }

  static async getWinnersLength(): Promise<number> {
    const winnersLength = await MarketplaceContract.publicContract.getWinnersLength();
    return winnersLength.toNumber();
  }

  static async getBronzeCounter(): Promise<number> {
    const bronzeCounter = await MarketplaceContract.publicContract.bronzeCounter();
    return bronzeCounter;
  }

  static async getFreeze(): Promise<boolean> {
    return MarketplaceContract.publicContract.freeze();
  }

  static async isDraw(): Promise<any> {
    return MarketplaceContract.publicContract.isDraw();
  }

  async purchase(quantity: number, vrs: IUserVrsResponseBody, value: number): Promise<any> {
    return this.contract.purchase(quantity, vrs.v, vrs.r, vrs.s, {
      value: ethers.utils.parseEther(String(value.toFixed(5))).toString(),
    });
  }

  async requestRandomNumbers(gasLimit: number, amount: number): Promise<any> {
    return this.contract.requestRandomNumbers(gasLimit, amount);
  }

  async topDraw(uri: string): Promise<any> {
    return this.contract.topDraw(uri);
  }

  async bronzeDraw(uri: string, gas: number): Promise<any> {
    return this.contract.bronzeDraw(uri, gas, {
      gasLimit: 25000000,
    });
  }

  async setFreeze(isFrozen: boolean): Promise<any> {
    return this.contract.setFreeze(isFrozen);
  }

  async ownerPurchase(quantity: number, gas: number): Promise<any> {
    return this.contract.ownerPurchase(quantity, gas, {
      gasLimit: 25000000,
    });
  }
}
