import { GoldenLabel } from 'components';
import { device } from 'resources/theme';
import styled from 'styled-components';
import { INFTAttributes } from 'types';

interface IChNftImageProps {
  name?: string;
  id: number;
  src: string;
  attributes?: INFTAttributes[] | null;
}

export const ChNftImage = ({ src, name, attributes, id }: IChNftImageProps) => {
  return (
    <StyledContainer>
      <StyledImageContainer>
        <StyledInnerContainer>
          <StyledImage src={src} />
          <StyledPriceLabel>
            <StyledPriceSpan>{name}</StyledPriceSpan>
            <StyledPriceSpanContainer>
              <StyledPriceSpanLeft>{'Token ID:'}</StyledPriceSpanLeft>
              <StyledPriceSpanRight>{id}</StyledPriceSpanRight>
            </StyledPriceSpanContainer>
            {attributes && attributes.length > 0
              ? attributes.map((attr, index) => {
                  return (
                    <StyledPriceSpanContainer key={index}>
                      <StyledPriceSpanLeft>
                        {attr.trait_type}
                        {':'}
                      </StyledPriceSpanLeft>
                      <StyledPriceSpanRight>{attr.value}</StyledPriceSpanRight>
                    </StyledPriceSpanContainer>
                  );
                })
              : null}
          </StyledPriceLabel>
        </StyledInnerContainer>
      </StyledImageContainer>
    </StyledContainer>
  );
};

const StyledImageContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  border-style: solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: ${({ theme }) => theme.linearGradientGoldTransparent};
  background: rgba(255, 255, 255, 0.05);
`;

const StyledInnerContainer = styled.div`
  padding: 25px 52px;
  @media ${device.laptop} {
    padding: 15px 17px};
  }
  @media ${device.mobileL} {
    padding: 14px 23px;
  }
`;

const StyledImage = styled.img`
  object-fit: cover;
  width: 350px;
  height: 350px;
  margin-bottom: 30px;
  @media ${device.laptop} {
    width: 273px;
    height: 259px;
    margin-bottom: 0px;
  }
  @media ${device.mobileL} {
    width: 250px;
    height: 240px;
  }
`;

const StyledSpan = styled.span`
  font-size: ${({ theme }) => theme.fontSizeM};
`;

const StyledPriceSpan = styled(StyledSpan)`
  display: block;
  font-size: ${({ theme }) => theme.fontSizeM};
`;

const StyledPriceSpanContainer = styled.div`
  display: flex;
`;

const StyledPriceSpanLeft = styled.div`
  text-align: right;
  flex: 1;
  margin-right: 2px;
  font-size: ${({ theme }) => theme.fontSizeM};
`;
const StyledPriceSpanRight = styled.div`
  text-align: left;
  flex: 1;
  margin-left: 2px;
  font-size: ${({ theme }) => theme.fontSizeM};
`;

const StyledPriceLabel = styled(GoldenLabel)`
  width: 100%;
  text-align: center;
  max-width: 350px;
  @media ${device.laptop} {
    max-width: 273px;
    padding: 6px 0;
  }
  @media ${device.mobileL} {
    max-width: 250px;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 6px;
`;
