import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { freeMintStore } from '../../store';
import { FreeMintMainSectionView } from './main/FreeMintMainSectionView';
import { FreeMintAboutSectionView } from './about/FreeMintAboutSectionView';
import { FreeMintModalView } from './FreeMintModalView';

export const FreeMintView = observer(() => {
  useEffect(() => {
    return () => {
      freeMintStore.closeModal();
    };
  }, []);

  return (
    <>
      <FreeMintMainSectionView />
      <FreeMintAboutSectionView />
      {freeMintStore.modalKey && <FreeMintModalView />}
    </>
  );
});
